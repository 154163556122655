<template>
  <div>
      <table :class="getTableClasses" style="width: 100%" >
        <tbody>
        <tr>
          <th colspan="2"><strong>1st Section (General Info) </strong> </th>
        </tr>
        <tr>
          <th width="25%"><strong>Reference No of NC:  </strong></th>
          <th> {{ data?.non_conformity?.ref_no }}</th>
        </tr>
        <tr>
          <th width="25%"><strong>Name of Company: </strong></th>
          <th>{{ data?.non_conformity?.company_name }}</th>
        </tr>
        <tr>
          <th width="25%"><strong>Date: </strong></th>
          <th>{{ data?.date }}</th>
        </tr>
        <tr>
          <th  width="25%"><strong>2nd Section (Description) </strong></th>
          <th>{{ data?.non_conformity?.non_conformance_description }}</th>
        </tr>
        <tr>
          <th width="25%"><strong>3rd Section (Root Cause Analysis) </strong></th>
          <th>{{ data?.root_cause_analysis }}</th>
        </tr>
        <tr>
          <th width="25%"><strong>4rd Section (Corrective Action):</strong></th>
          <th>{{ data?.corrective_action }}</th>
        </tr>
        <tr>
          <th width="25%"><strong>5rd Section (Preventive Action ): </strong></th>
          <th>{{ data?.preventive_actions }}</th>
        </tr>
        <tr>
          <th colspan="2"><strong>6th Section (Review and Follow-up) : </strong></th>
        </tr>
        <tr>
          <th width="25%"><strong>To be reviewed/followed-up by: </strong> </th>
          <th> {{ data?.review_by }}</th>
        </tr>
        <tr>
          <th width="25%"><strong>Comments by Reviewer: </strong> </th>
          <th> {{ data?.reviewer_comments }}</th>
        </tr>
        <tr>
          <th width="25%"><strong>Date: </strong> </th>
          <th> {{ data?.reviewer_date }}</th>
        </tr>
        <tr>
          <th width="25%"><strong>Comments by DPA: </strong> </th>
          <th> {{ data?.dpa_comments }}</th>
        </tr>
        <tr>
          <th width="25%"><strong>Date: </strong> </th>
          <th> {{ data?.dpa_date }}</th>
        </tr>

        <tr>
          <th colspan="2"><strong>6th Section (Review and Follow-up) : </strong></th>
        </tr>
        <tr>
          <th width="25%"><strong>Vessel’s acknowledgement and Comments: </strong> </th>
          <th> {{ data?.vessel_acknowledgement }}</th>
        </tr>
        <tr>
          <th width="25%"><strong>Date Closed Vessel: </strong> </th>
          <th> {{ data?.date_close_vessel }}</th>
        </tr>
        <tr>
          <th width="25%"><strong>Name of Master: </strong> </th>
          <th> {{ data?.master_name }}</th>
        </tr>
        <tr>
          <th width="25%"><strong>Date Closed Office: </strong> </th>
          <th> {{ data?.date_closed_office }}</th>
        </tr>
        <tr>
          <th width="25%"><strong>Name of DPA: </strong> </th>
          <th> {{ data?.dpa_name }}</th>
        </tr>

        </tbody>
      </table>
    </div>
</template>



<script>
export default {
  props: {
    data: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    getTableClasses() {
      return {
        'table': true,
        'table-bordered': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }

};
</script>
<style scoped>
    .underline {
      text-decoration: underline;
    }

</style>
