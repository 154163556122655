<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left"> Invoice Reports</h2>
            <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>

          </div>
          <div class="row">
            <div class="col-sm-12">

              <form role="form" action="#" @submit="formSubmit" class="searchForm">
                <div class="x_panel">
                  <div class="x_content">
                    <div class="row justify-content-center">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Vessel</label>
                          <select class="form-control" v-model="formData.vessel">
                            <option value="" selected>Select Vessel</option>
                            <option v-for="(row, key) in vessels" v-bind:value="{ id: row.id, name: row.name }">{{row.name}}</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-md-2 mx-2">
                        <div class="form-group">
                          <label>Date From</label>
                          <DatePicker type="date" v-model="formData.dateFrom" valueType="format"></DatePicker>
                        </div>
                      </div>

                      <div class="col-md-2 mx-2">
                        <div class="form-group">
                          <label>Date To</label>
                          <DatePicker type="date" v-model="formData.dateTo" valueType="format"></DatePicker>
                        </div>
                      </div>


                      <div class="col-md-2">
                        <button style="margin-top: 26px" type="submit" class="btn btn-success btn-sm">Submit</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div
                v-if="loading"
                class="col-md-12 text-center"
                style="margin-top:25%"
              >
                <PulseLoader
                  :loading="loading"
                ></PulseLoader>
              </div>
              <div class="card-box table-responsive" v-if="reportData && !loading">
                <button @click="print('printArea','Invoice Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
                <button @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
                <vue-excel-xlsx class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'invoice'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
                <div class="clearfix"></div>
                <div id="printableArea">
                  <div class="mb-3 text-center">
                    <div class="font-weight-bold">Invoice Report</div>
                    <div v-if="formData.vessel?.name != ''">Vessel:
                      <span>{{formData.vessel.name}}</span>
                    </div>
                    <div v-if="formData.dateFrom != '' && formData.dateTo !=''">
                      Date Period:
                      <span v-if="formData.dateFrom">{{ formData.dateFrom | moment('DD/MM/YYYY') }}</span> -
                      <span v-if="formData.dateTo"> {{ formData.dateTo | moment('DD/MM/YYYY') }}</span>
                    </div>
                  </div>
                  <TableData :reportData="reportData" :noDataFound="noDataFound" :invoiceView="invoiceView"/>

                  <div id="printArea" style="display: none">
                    <div style="text-align: center">
                      <h2>{{ appName }}</h2>
                      <h3>Purchase Reports</h3>
                      <h3 v-if="formData.vessel.name != ''">Vessel:
                        <span>{{formData.vessel.name}}  </span>
                      </h3>
                      <h3 v-if="formData.dateFrom != '' && formData.dateTo !=''">
                        Date Period:
                        <span v-if="formData.dateFrom">{{ formData.dateFrom | moment('DD/MM/YYYY') }}</span> -
                        <span v-if="formData.dateTo"> {{ formData.dateTo | moment('DD/MM/YYYY') }}</span>
                      </h3>
                      <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                    </div>
                    <TableData :reportData="reportData" :noDataFound="noDataFound" :isPrinting="true"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
    import DatePicker from 'vue2-datepicker'
    import auth from '../../../auth'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import exportMixin from "@/mixin/exportMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [printMixin, goBackButtonMixin, exportMixin],
        components: {TableData, DatePicker, PulseLoader},
        data() {
            return {
                loading: false,
                formData: {
                    vessel: {
                        id: auth.getVesselId(),
                        name: ''
                    },
                    dateFrom: '',
                    dateTo: '',
                },
                requisitionItem: null,
                appName: process.env.VUE_APP_NAME,
                vessels: null,
                reportData: null,
                noDataFound: null,
                vesselId: auth.getVesselId(),
                excelHeader: [],
                excelData: []
            }
        },
        mounted() {
            this.getVessel();
            this.formSubmit();
        },
        methods: {
            prepareExportData() {
                return this.reportData.map((item, key) => ({
                    '#': key + 1,
                    'Invoice No': item?.invoice_no,
                    'Purchase No': item?.purchase?.po_no,
                    'Created By': item?.user?.username,
                    'Paid Amount': item?.amount,
                    'Date Created': item?.date,
                }));
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'invoice.csv');
            },
            excelExport() {
                let data = this.reportData;
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            getVessel() {
                this.$http.get('/api/vessel').then(response => {
                    this.vessels = response.data.success.vessel
                    const selectedVessel = this.vessels.find(vessel => vessel.id == this.vesselId);
                    if (selectedVessel) {
                      this.formData.vessel = {
                        id: selectedVessel.id,
                        name: selectedVessel.name
                      };
                    }
                }).catch(e => {
                    console.log(e)
                })
            },
            formSubmit: function (e) {
                if (e !== undefined) {
                    e.preventDefault()
                }
                this.loading = true;
                if (this.formData.vessel) this.formData.vessel_id = this.formData.vessel.id
                let params = {
                    vessel_id: this.formData.vessel_id,
                    date_from: this.formData.dateFrom,
                    date_to: this.formData.dateTo
                };
                this.$http.get('/api/report/invoice', {params}).then((response) => {
                    this.loading = false;
                    if (response.data.success.length < 1) {
                        this.noDataFound = true
                    }
                    if (response.data) {
                        this.reportData = response.data.success;
                        this.excelExport();
                    }
                }).catch((error) => {
                    this.loading = false;
                    let response = error.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {

                    }
                })
            },
            invoiceView(row) {
                this.$router.push({
                    name: 'InvoiceView',
                    params: {
                        purchaseID: row.purchase_id,
                        invoiceID: row.id
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
