
<template>
  <div>
  <nav aria-label="..." v-if="pagination.total > 0">
    <ul class="pagination justify-content-center">
      <li class="page-item" :class="{ disabled: pagination.current_page === 1 }">
        <a class="page-link" @click.prevent="getData(1)"  >First page</a>
      </li>
      <li class="page-item" :class="{ disabled: pagination.current_page === 1 }">
        <a class="page-link" @click.prevent="getData(pagination.current_page - 1)">Previous</a>
      </li>

      <li class="page-item"   v-for="page in paginationPage" :class="isCurrentPage(page) ? 'active' : ''" :key="page"  >
        <a class="page-link" @click.prevent="getData(page)">{{ page }}
        </a>
      </li>

      <li class="page-item" :class="{ disabled: pagination.current_page >= pagination.last_page }">
        <a class="page-link" @click.prevent="getData(pagination.current_page + 1)">Next</a>
      </li>
      <li class="page-item" :class="{ disabled: pagination.current_page >= pagination.last_page }">
        <a class="page-link" @click.prevent="getData(pagination.last_page)">Last page</a>
      </li>
    </ul>
  </nav>

  </div>
</template>
<script>
export default {
  props: {
    getData : {
      required: true
    },
    pagination: {
      required: true
    }
  },
  data() {
    return {
      visiblePageCount: 5,
    };
  },
  methods: {
    isCurrentPage(page) {
      return this.pagination.current_page === page
    }
  },
  computed: {
    paginationPage() {
      const totalPages = this.pagination.last_page;
      const currentPage = this.pagination.current_page;

      // Calculate the start and end page
      let startPage = Math.floor((currentPage - 1) / this.visiblePageCount) * this.visiblePageCount + 1;
      let endPage = startPage + this.visiblePageCount - 1;

      if (endPage > totalPages) {
        endPage = totalPages; // Ensure we don't go beyond total pages
      }

      // Generate an array of pages to display
      let pages = [];
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
}
</script>
<style scoped>

</style>
