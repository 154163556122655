<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead class="text-center" :set="seriaNumber =   1">
    <tr>
      <th>#sl</th>
      <th>Job Name(Code)</th>
      <th>Department Name(Code)</th>
      <th>Component Name(code)</th>
      <th>Job Type</th>
      <th>Counter Type</th>
      <th>Status</th>
      <th>Due Date/Due. Hr.(Current Run. Hr.)</th>
      <th>Overdue Duration(Days)</th>
      <th>Last Completed Date</th>
      <th  v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_DEFER_JOB) && !isPrinting">Action</th>
    </tr>
    </thead>
    <tbody v-for="(job) in jobList" :key="job.id">
    <tr v-for="(job_schedule,index) in job.job_schedules" :key="job_schedule.id" >
      <td>{{seriaNumber++}}</td>
      <td>{{job?.name}} ({{job?.code}})</td>
      <td>{{job?.department?.name}}({{job?.department?.code}})</td>
      <td>{{job?.component?.maincomponent?.name}}({{job?.component?.maincomponent?.code}}) >> {{job?.component?.name}}({{job?.component?.code}})</td>
      <td>{{job?.job_type?.name}}</td>
      <td>{{job?.counter_type}}</td>
      <td>{{job_schedule?.status}}</td>
      <td>
        <span> {{job_schedule?.end_date  | moment('DD-MM-YYYY') }}</span>
        <span v-if="job?.counter_type == 'Running Hours'">/{{parseInt(job?.running_schedule_value) + parseInt(job?.running_hours_value) }}({{job?.current_running_hours_value}})</span>
      </td>
      <td>{{ job?.overdue_duration }}</td>
      <td>{{job?.last_complete_date  | moment('DD-MM-YYYY')}}</td>
      <td v-if="(isPermissionExist(PERMISSIONS.MAINTENANCE_DEFER_JOB) || isPermissionExist(PERMISSIONS.MAINTENANCE_JOB_DONE)) && !isPrinting" >
        <button v-if="job_schedule?.status !== 'Done'" title="Deffer Job " class="btn btn-sm btn-info" type="button" @click="viewDeferSchedule(job,index)"><i class="fa fa-compass" aria-hidden="true"></i></button>
        <div v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_JOB_DONE) ">
          <button title="Job Done" type="button" v-if="job.job_schedules[index].job_done_report == null " class="btn btn-info btn-sm" @click="jobDoneFunction(job,index)"><i class="fa fa-thumbs-up"></i></button>
          <button title="Job Done" type="button" v-else-if="job.job_schedules[index].job_done_report != null && job.job_schedules[index].status != 'Done'"   class="btn btn-primary  btn-sm" @click="jobDoneFunction(job,index)"><i class="fa fa-thumbs-up"></i></button>
          <button title="Job Done" type="button" v-else class="btn btn-success btn-sm" @click="jobDoneFunction(job,index)"><i class="fa fa-thumbs-up"></i></button>
        </div>
     </td>

    </tr>
    </tbody>
  </table>
</template>

<script>
import auth from "@/auth";
import permissionsMixin from "@/mixin/permissionsMixin";

export default {
  mixins:[permissionsMixin],
  data () {
    return {
      permissions:auth.getPermissionData(),
    }
  },
  props: {
    jobList : {
      required: true
    },
    jobDoneFunction:{
      type:Function,
      required:false
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    viewDeferSchedule: {
      type: Function,
      required: false
    },
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
