<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead :set="seriaNumber =   1" >
    <tr>
      <th>#</th>
      <th>Job Name(Code)</th>
      <th> Department(Code)</th>
      <th> Main Component Name(Code)</th>
      <th>Last Maintenance Date</th>
      <th>Due Date</th>
      <th>Counter Type</th>
      <th>Status</th>
      <th>Assigned Ranked</th>
    </tr>
    </thead>
    <tbody v-for="(job) in jobList" :key="job?.id">
    <tr v-for="(job_schedule) in job?.job_schedules" :key="job_schedule?.id">
      <td>{{seriaNumber++}}</td>
      <td>{{job?.name}}({{job?.code}})</td>
      <td>{{ job?.department?.name }}({{ job?.department?.code }})</td>
      <td>{{ job?.main_component?.name }}({{ job?.main_component?.code }})</td>
      <td>{{job_schedule?.start_date  | moment('DD-MM-YYYY')}}</td>
      <td>{{job_schedule?.end_date  | moment('DD-MM-YYYY')}}</td>
      <td>{{job_schedule?.counter_type}}</td>
      <td>{{job_schedule?.status == 'In_Progress' ? 'In Progress' : job_schedule?.status}}</td>
      <td>{{job?.rank_fixed_role?.name}}</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    jobList : {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    }
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
