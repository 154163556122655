<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
        :color="color"
        :size="size"
      ></PulseLoader>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <h2 class="float-left"> {{DeferReschedule ? 'Defer Job List' : JobDoneStatus ? 'Job Done' : P2W ? 'Permit To Work' : IRR ? 'Risk Assessment' : 'Upcoming Job List'}}</h2>
          <ul class="nav navbar-right panel_toolbox">

            <button v-if="!listMode" @click="changeListMode" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>

            <router-link v-if="listMode && isPermissionExist(PERMISSIONS.OVERDUE_JOB_REPORT)" to="/over-due-job" class="btn btn-info btn-sm float-right">Overdue Job</router-link>
            <button v-if="listMode" @click="print('printArea','Upcoming Job List ')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
            <button v-if="listMode" @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>

          </ul>
        </div>
        <div class="">
          <div class="x_content ">
            <div class="row" v-if="listMode">
              <div class="col-md-12">
                <div class="card">

                  <div class="x_panel">
                    <div class="x_content">
                      <div class="row justify-content-center">

                        <div class="col-md-2">
                          <div class="form-group">
                            <label>Date From</label>
                            <DatePicker type="date" v-model="formData.date_from" valueType="format"></DatePicker>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label>Date To</label>
                            <DatePicker type="date" v-model="formData.date_to" valueType="format"></DatePicker>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label>Counter Type</label>
                            <select class="form-control" v-model="formData.counter_type">
                              <option value>Select Job Interval</option>
                              <option value="Yearly">Yearly</option>
                              <option value="Two-Yearly">2 yearly</option>
                              <option value="Thirty-Monthly">2.5 yearly</option>
                              <option value="Three-Yearly">3 yearly</option>
                              <option value="Four-Yearly">4 yearly</option>
                              <option value="Five-Yearly">5 yearly</option>
                              <option value="Ten-Yearly">10 yearly</option>
                              <option value="Fifteen-Yearly">15 yearly</option>
                              <option value="Monthly">Monthly</option>
                              <option value="Three-Monthly">3 Monthly</option>
                              <option value="Six-Monthly">6 Monthly</option>
                              <option value="Weekly">Weekly</option>
                              <option value="Running Hours">Running Hour</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <div class="form-group">
                            <label>Job Type</label>
                            <select class="form-control" v-model="formData.job_type">
                              <option value>Select Job Type</option>
                              <option value="Yes">Critical</option>
                              <option value="No">Non Critical</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-2" v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_ASSIGNED_RANKED)">
                          <div class="form-group">
                            <label>Assign Rank</label>
                            <select v-model="formData.rank_fixed" class="form-control">
                              <option value="">Select Rank</option>
                              <option v-for="role in roles" :key="role.id" :value="role.id">{{role.name}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-2">
                          <button style="margin-top: 26px" @click="getAllJobList()" class="btn btn-success btn-sm">Submit</button>
                        </div>
                      </div>
                    </div>
                  </div>


                  <!--                  <h5 class="card-header">Job List</h5>-->
                  <div class="card-body">
                    <!-- <div class="row"  v-if="filter.jobType != '' ">
                        <div class="col-md-6 offset-md-3">
                            <DatePicker type="date"  range v-model="filter.dateRange"  valueType="format" placeholder="Search Job By Date Range" ></DatePicker>
                            <button class="btn btn-success" style="font-size: 9px;margin-top: 1px;" @click="searchSchedule"><i class="fa fa-search"></i></button>
                        </div>
                    </div> -->
                    <div class="card-box table-responsive">
                      <table id="datatables" class="table table-sm table-striped table-bordered text-center" style="width: 100%">
                        <thead>
                        <tr>
                          <th :set="sl = 0">#</th>
                          <th> Department</th>
                          <th> Component Name</th>
                          <th> Job Name (Job Code)</th>
                          <th> Job Type</th>
                          <th>Counter Type</th>
                          <th>Prev Maintenance Date</th>
                          <th>Next Maintenance Due Date</th>
                          <th v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_ASSIGNED_RANKED)">Assign To</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody v-for="(job, i) in jobList" :key="i">
                        <tr v-show="job.job_schedules[index].status != 'Done' " v-for="(job_schedule, index) in job?.job_schedules" :key="index">
                          <td>{{job?.job_schedules[index].status != 'Done' ? ++sl : sl}}</td>
                          <td>{{ job?.department?.name }}({{ job?.department?.code }})</td>
                          <td>
                            {{ job?.main_component?.name }}({{ job?.main_component?.code }}) >>
                            {{
                            job.sub_sub_component != null ? job.sub_sub_component?.name + '(' + job.sub_component?.name + ')' :
                            job.sub_component != null ? job.sub_component?.name + '(' + job.component?.name + ')' :
                            job.component != null ? job.component?.name + '(' + job.component?.code + ')' : 'N/A'
                            }}

                            <span v-if="job.item_rob" title="Insufficient Inventory. Please Purchase Required Item" class="badge badge-danger"><i class="fa fa-exclamation-triangle fa-2x"></i></span>
                          </td>
                          <td>{{ job?.name }} ({{job?.code}}) <span v-if="job.pw_ra_assign=='Yes'" title="RA/Permit Required" class="badge badge-danger"><i class="fa fa-list-alt fa-2x"></i></span></td>
                          <td>{{ job?.job_type?.name }}</td>
                          <td>{{ job?.counter_type }}</td>
                          <td>{{job.job_schedules[index]?.start_date | moment('DD-MM-YYYY') }}</td>
                          <td>{{job.job_schedules[index]?.end_date | moment('DD-MM-YYYY') }} <span v-if="job.job_schedules[index]?.defer_reschedule.length > 0" class="badge badge-danger">Defer  </span></td>
                          <td width="12%" v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_ASSIGNED_RANKED)">
                                <div class="form-group">
                                  <select class="form-control" @change="assignRanked($event,job.id)">
                                    <option v-for="role in roles" v-bind:selected="job?.rank_fixed == role.id" :key="role.id" :value="role.id">{{role.name}}</option>
                                  </select>
                                </div>
                          </td>
                          <td>
                            <!--                            <span class="badge badge-warning" v-if="job.job_schedules[index].status=='Pending'">Pending </span>-->
                            <span class="badge" :class="{ 'badge-warning': isPending(job.job_schedules[index]), 'badge-danger': isOverdue(job.job_schedules[index]) }">
                                {{ getStatusLabel(job.job_schedules[index]) }}
                            </span>
                            <span class="badge badge-info" v-if="job.job_schedules[index].status=='In_Progress'">In Progress</span>
                            <span class="badge badge-warning" v-if="job.job_schedules[index].status=='Done'">Done</span>

                          </td>
                          <td>
                            <!--RISK ASSESMENT BUTTON -->
                            <button title="Job Instruction " class="btn btn-secondary btn-sm" @click="readJobRemark(job)"><i class="fa fa-book"></i>
                            </button>


                            <div v-if="job.pw_ra_assign=='Yes' && false">
                              <div>
                                <!--  second engineer  -->
                                <button title="Risk Assessment" v-if="job.job_schedules[index].risk_assessment.length<1 && isPermissionExist(PERMISSIONS.MAINTENANCE_RISK_ASSESSMENT)" type="button" class="btn btn-info btn-sm" @click="createRA(job,index)"><i class="fa fa-warning"></i></button>
                                <!--  admin,chief,master  -->
                                <button title="Risk Assessment" v-else-if="job.job_schedules[index].risk_assessment.length<1 " type="button" class="btn btn-info btn-sm" @click="createRaAleart"><i class="fa fa-warning"></i></button>

                                <button title="Risk Assessment" v-else type="button" class="btn btn-success btn-sm" @click="createRA(job,index)"><i class="fa fa-warning"></i></button>
                              </div>
                              <div v-if="false">
                                <!--PERMIT TO  BUTTON -->
                                <!--PERMIT TO  BUTTON nulll -->
                                <!--  second engineer  -->
                                <button title="Permit To Work " type="button" v-if="job.job_schedules[index].permit_to_work==null && isPermissionExist(PERMISSIONS.MAINTENANCE_SECOND_ENGINEER_PERMIT_TO_WORK)" class="btn btn-info btn-sm" @click="createP2W(job,index)"><i class="fa fa-tasks"></i></button>
                                <!--  admin,chief,master  -->
                                <button title="Permit To Work " type="button" v-if="job.job_schedules[index].permit_to_work==null" class="btn btn-info btn-sm" @click="createP2WAleart"><i class="fa fa-tasks"></i></button>

                                <!--PERMIT TO  BUTTON not  null -->
                                <!--  second engineer  -->
                                <button title="Permit To Work " type="button" v-if="job.job_schedules[index].permit_to_work!=null  && job.job_schedules[index].permit_to_work.permission_type=='No' && isPermissionExist(PERMISSIONS.MAINTENANCE_SECOND_ENGINEER_PERMIT_TO_WORK)" class="btn  btn-sm" style="background-color: #69845b; color:#ffff"
                                        @click="createP2W(job,index)"><i class="fa fa-tasks"></i></button>
                                <!--  admin,chief,master  -->
                                <button title="Permit To Work " type="button" v-if="job.job_schedules[index].permit_to_work!=null  && job.job_schedules[index].permit_to_work.permission_type=='No' && isPermissionExist(PERMISSIONS.MAINTENANCE_PERMIT_TO_WORK) " class="btn  btn-sm" style="background-color: #69845b; color:#ffff"
                                        @click="createP2W(job,index)"><i class="fa fa-tasks"></i></button>
                                <!-- <button title="Permit To Work " type="button" v-if="job.job_schedules[index].permit_to_work!=null && user_type ==3"  class="btn btn-info btn-sm" @click="createP2WAleart"><i class="fa fa-tasks"></i></button> -->

                                <!--  second engineer  -->
                                <button title="Permit To Work " type="button" v-if="job.job_schedules[index].permit_to_work!=null   && job.job_schedules[index].permit_to_work.permission_type=='Yes' && isPermissionExist(PERMISSIONS.MAINTENANCE_SECOND_ENGINEER_PERMIT_TO_WORK)" class="btn btn-success  btn-sm" @click="createP2W(job,index)"><i
                                  class="fa fa-tasks"></i></button>
                                <!--  admin,chief,master  -->
                                <button title="Permit To Work " type="button" v-if="job.job_schedules[index].permit_to_work!=null   && job.job_schedules[index].permit_to_work.permission_type=='Yes' && isPermissionExist(PERMISSIONS.MAINTENANCE_PERMIT_TO_WORK) " class="btn btn-success btn-sm" @click="createP2WChiefMode(job,index)"><i
                                  class="fa fa-tasks"></i></button>
                              </div>
                            </div>

                            <!-- deffer job icon  -->
                            <!-- this button for super admin to chief engineer for accept defer request  -->
                            <!-- <button title="Deffer Job " v-if="user_type<=3" class="btn btn-sm btn-info" type="button"><i class="fa fa-compass" aria-hidden="true"></i></button> -->

                            <div v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_JOB_DONE) ">
                              <button title="Job Done" type="button" v-if="job.job_schedules[index].job_done_report == null " class="btn btn-info btn-sm" @click="jobDoneFunction(job,index)"><i class="fa fa-thumbs-up"></i></button>
                              <button title="Job Done" type="button" v-else-if="job.job_schedules[index].job_done_report != null && job.job_schedules[index].status != 'Done'" class="btn btn-primary  btn-sm" @click="jobDoneFunction(job,index)"><i class="fa fa-thumbs-up"></i></button>
                              <button title="Job Done" type="button" v-else class="btn btn-success btn-sm" @click="jobDoneFunction(job,index)"><i class="fa fa-thumbs-up"></i></button>
                            </div>

                            <button v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_DEFER_JOB) " title="Deffer Job " class="btn btn-sm btn-info" type="button" @click="viewDeferSchedule(job,index)"><i class="fa fa-compass" aria-hidden="true"></i></button>

                            <!-- <button  title="Defer Reschedule List" type="button"  class="btn btn-info btn-sm"><i class="fa fa-refresh"></i></button> -->
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                    <div id="printArea" style="display: none">
                      <div style="text-align: center">
                        <h2>{{ appName }}</h2>
                        <h4>Vessel Name: {{this.vessel_name}}</h4>
                        <h3>Upcoming Job List</h3>
                        <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                      </div>

                      <table class="table  table-bordered text-center print-table" style="width: 100%; ">
                        <thead>
                        <tr>
                          <th :set="sl = 0">#</th>
                          <th> Department</th>
                          <th> Component Name</th>
                          <th> Job Name(Job Code)</th>
                          <th> Job Type</th>
                          <th>Counter Type</th>
                          <th>Prev Maintenance Date</th>
                          <th>Next Maintenance Due Date</th>
                          <th v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_ASSIGNED_RANKED)">Assign To</th>
                          <th>Status</th>
                        </tr>
                        </thead>
                        <tbody v-show="job != null" v-for="(job, i) in jobList" :key="i">
                        <tr v-show="job?.job_schedules[index].status != 'Done' " v-for="(job_schedule, index) in job?.job_schedules" :key="index">
                          <td>{{job?.job_schedules[index].status != 'Done' ? ++sl : sl}}</td>
                          <td>{{ job?.department?.name }}({{ job?.department?.code }})</td>
                          <td>
                            {{ job?.main_component?.name }}({{ job?.main_component?.code }}) >>
                            {{
                            job.sub_sub_component != null ? job.sub_sub_component?.name + '(' + job.sub_component?.name + ')' :
                            job.sub_component != null ? job.sub_component?.name + '(' + job.component?.name + ')' :
                            job.component != null ? job.component?.name + '(' + job.component?.code + ')' : 'N/A'
                            }}
                          </td>
                          <td>{{ job?.name }} ({{job?.code}})</td>

                          <td>{{ job?.job_type?.name }}</td>
                          <td>{{ job?.counter_type }}</td>
                          <td>{{job?.job_schedules[index]?.start_date | moment('DD-MM-YYYY') }}</td>
                          <td>{{job?.job_schedules[index]?.end_date | moment('DD-MM-YYYY') }}</td>
                          <td width="10%" v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_ASSIGNED_RANKED)">
                            <div class="row">
                              <div class="col-md-8 offset-md-2">
                                <div class="form-group">
                                  <select class="form-control">
                                    <option v-for="role in roles" v-bind:selected="job.rank_fixed == role.id" :key="role.id" :value="role.id">{{role.name}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </td>

                          <td>
                            <!--                            <span class="badge badge-warning" v-if="job.job_schedules[index].status=='Pending'">Pending </span>-->
                            <span class="badge" :class="{ 'badge-warning': isPending(job.job_schedules[index]), 'badge-danger': isOverdue(job.job_schedules[index]) }">
                                {{ getStatusLabel(job.job_schedules[index]) }}
                            </span>
                            <span class="badge badge-info" v-if="job.job_schedules[index].status=='In_Progress'">In Progress</span>
                            <span class="badge badge-warning" v-if="job.job_schedules[index].status=='Done'">Done</span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <RiskAssessment v-if="IRR" :jobInfo="selectedJob" :Schedule="scheduleId" :mode="permitWorkMode" @enableListMode="changeListMode"/>
            <PermitToWork v-if="P2W" :jobInfo="selectedJob" :chiefMode='chiefPermisionMode' :mode="permitWorkMode" :Schedule="scheduleId" @enableListMode="changeListMode"/>
            <JobDone v-if="JobDoneStatus" :jobInfo="selectedJob" :mode="permitWorkMode" :componentName="componentName" :jobName="job_name" :Schedule="scheduleId" @enableListMode="changeListMode"/>
            <DeferReschedule v-if="DeferReschedule" :jobInfo="selectedJob" :Schedule="scheduleId" @enableListMode="changeListMode"/>

          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="showModal" tabindex="-1" role="dialog" aria-labelledby="showModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="showModalLabel"> Job Instruction</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <a href="javascript:;" @click="print('jobRemarks')" style="background: #08c;color: white;" class="btn  btn-sm "><i
                class="fa fa-print"></i> Print</a>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div id="jobRemarks">
              <span v-html="jobRemarks"></span>
            </div>

            <div class="row">
              <div class="col-md-6"></div>
              <div class="col-md-6"></div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>

    import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import Tree from 'vuejs-tree'
    import {VueEditor} from 'vue2-editor'
    import RiskAssessment from './RiskAssessment'
    import PermitToWork from './PermitToWork'
    import JobDone from './JobDone'
    import DeferReschedule from './DeferReschedule'
    import auth from '@/auth'
    import permissionsMixin from "@/mixin/permissionsMixin";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";

    export default {
        mixins: [permissionsMixin, printMixin, goBackButtonMixin],
        name: 'Maintenance',
        components: {
            VueEditor,
            RiskAssessment,
            PermitToWork,
            JobDone,
            DeferReschedule,
            Tree,
            DatePicker,
            PulseLoader
        },
        data() {
            return {
                loading: false,
                color: '#1ABB9C',
                size: '10px',
                jobId: this.$route.params.jobId,
                selectedComponent: '',
                permissions: auth.getPermissionData(),
                selectedJob: Object,
                treeDisplayData: [],
                roles: [],
                scheduleId: '',
                componentName: '',
                job_name: '',
                jobType: '',
                query: '',
                content: '',
                queryFiled: 'name',
                components: [],
                jobList: [],
                jobRemarks: '',
                listMode: true,
                IRR: false,
                P2W: false,
                appName: process.env.VUE_APP_NAME,
                JobDoneStatus: false,
                DeferReschedule: false,
                editMode: false,
                permitWorkMode: false,
                tableEnableMode: false,
                chiefPermisionMode: false,
                vessel_id: auth.getVesselId(),
                user_type: auth.getUserType(),
                vessel_name: auth.getVesselName(),
                formData: new Form({
                    vessel_id: auth.getVesselId(),
                    date_from: '',
                    date_to: '',
                    rank_fixed: '',
                    counter_type: '',
                    job_type: ''
                }),
                form: new Form({
                    id: '',
                    name: '',
                    email: '',
                    status: 'Activate'
                }),
                filter: new Form({
                    dateRange: '',
                    jobType: '',
                    jobId: '',
                    vessel_id: auth.getVesselId(),
                }),
            }
        },
        mounted() {
            //quick job find from dashboard
            if (this.jobId) {
                this.getJobAllScheduleList();
            }
            this.getAllJobList();
            this.dataTableEnable();
            this.allRole()
        },
        computed: {
            moment() {
                return moment
            },
        },
        methods: {
            allRole() {
                this.$http.get("/api/filter/get-active-role").then(response => {
                    this.roles = response.data.success;
                    // setTimeout(() => $('#datatables').DataTable(), 1000);
                }).catch(e => {
                    console.log(e);
                });
            },
            isPending(schedule) {
                return schedule && schedule.status === 'Pending' && this.isEndDatePending(schedule.end_date);
            },
            isOverdue(endDate) {
                return endDate && this.getCurrentDate() > new Date(endDate);
            },
            isEndDatePending(endDate) {
                return endDate && this.getCurrentDate() <= new Date(endDate);
            },
            getCurrentDate() {
                // Get the current date without time (assuming your dates have no time information)
                var currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);
                return currentDate;
            },
            getStatusLabel(schedule) {
                if (this.isPending(schedule)) {
                    return 'Pending';
                } else if (this.isOverdue(schedule.end_date)) {
                    return 'Overdue';
                }
                return ''; // Add handling for other cases if needed
            },
            getAllJobList() {
                this.loading = true;
                this.jobList = [];
                this.formData.post('/api/filter/all-job').then(response => {
                    this.jobList = response.data.success;
                    this.loading = false;
                }).catch(e => {
                    this.loading = false;
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                })
            },
            getJobAllScheduleList() {
                this.$http.get('/api/filter/job/' + this.jobId + '/' + this.vessel_id).then(response => {
                    this.jobList = [];
                    $('.dataTables_empty').closest('tr').remove();
                    this.jobList = response.data.success
                    // setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    console.log(e)
                })
            },
            searchSchedule() {
                this.filter.busy = true;
                this.filter.post('/api/filter/search-jobschedule').then(response => {
                    if (this.filter.successful) {
                        this.jobList = [];
                        this.jobList = response.data.success;
                        $('.dataTables_empty').closest('tr').remove()
                        // setTimeout(() => $('#datatables').DataTable(), 1000)
                    } else {
                        this.jobList = []
                        // setTimeout(() => $('#datatables').DataTable(), 1000)
                    }
                }).catch(e => {
                    var error = this.filter.errors.errors.error;
                    this.$snotify.warning('Sorry Item Is Not Found')
                })
            },
            createRA(job, i) {
                this.listMode = false;
                this.IRR = true;
                this.selectedJob = job;
                let RiskAssessmentLenght = job.job_schedules[i].risk_assessment.length;
                if (RiskAssessmentLenght > 0) {
                    this.permitWorkMode = true
                } else {
                    this.permitWorkMode = false
                }
                this.scheduleId = i
            },
            viewDeferSchedule(job, i) {
                this.listMode = false;
                this.DeferReschedule = true;
                this.selectedJob = job;
                this.scheduleId = i
            },
            createRaAleart() {
                this.$snotify.warning('Risk Assessment Is not Submitted')
            },

            createP2WAleart() {
                this.$snotify.warning('Work Permission Not Send')
            },
            createCL() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                $('#roleModalCL').modal('show')
            },
            createP2W(job, i) {
                if (job.job_schedules[i].permit_to_work != null) {
                    this.permitWorkMode = true
                } else {
                    this.permitWorkMode = false
                }
                this.listMode = false
                this.IRR = false
                this.P2W = true
                this.selectedJob = job
                this.scheduleId = i
            },
            createP2WChiefMode(job, i) {
                if (job.job_schedules[i].permit_to_work != null) {
                    this.permitWorkMode = true
                } else {
                    this.permitWorkMode = false
                }
                this.chiefPermisionMode = true
                this.listMode = false
                this.IRR = false
                this.P2W = true
                this.selectedJob = job
                this.scheduleId = i
            },
            readJobRemark(job) {
                this.jobRemarks = job.job_remarks
                $('#showModal').modal('show')
            },
            jobDoneFunction(job, i) {
                // if(job.job_schedules[i].permit_to_work==null && job.job_schedules[i].risk_assessment!=null){
                if (job.job_schedules[i].job_done_report != null) {
                    this.permitWorkMode = true;
                } else {
                    this.permitWorkMode = false;
                }
                if (this.user_type != job.rank_fixed && job.job_schedules[i].job_done_report == null) {
                    this.$snotify.warning('Job done report is not submitted');
                } else {
                    let component_name = job.sub_sub_component != null ? job.sub_sub_component?.name + '(' + job.sub_component?.name + ')' :
                        job.sub_component != null ? job.sub_component?.name + '(' + job?.component?.name + ')' :
                            job.component != null ? job?.component?.name + '(' + job?.main_component?.name + ')' :
                                job.main_component != null ? job?.main_component?.name : 'N/A';
                    let job_name = job?.name + '(' + job?.code + ')';
                    this.listMode = false
                    this.IRR = false
                    this.P2W = false
                    this.JobDoneStatus = true
                    this.selectedJob = job
                    this.scheduleId = i
                    this.componentName = component_name;
                    this.job_name = job_name;

                }
            },
            addNodeFunction: function (info) {
                this.jobList = []
                switch (info.level) {
                    case 1:
                        this.filter.jobType = 'department_id'
                        this.filter.jobId = info.id
                        this.getAllJobList()
                        this.tableEnableMode = true
                        break
                    case 2:
                        this.filter.jobType = 'main_component_id'
                        this.filter.jobId = info.id
                        this.getAllJobList()
                        this.tableEnableMode = true
                        break
                    case 3:
                        this.filter.jobType = 'component_id'
                        this.filter.jobId = info.id
                        this.getAllJobList()
                        this.tableEnableMode = true
                        break
                    case 4:
                        this.filter.jobType = 'sub_component_id'
                        this.filter.jobId = info.id
                        this.getAllJobList()
                        this.tableEnableMode = true
                        break
                    case 5:
                        this.filter.jobType = 'sub_sub_component_id'
                        this.filter.jobId = info.id
                        this.getAllJobList()
                        this.tableEnableMode = true
                        break
                }
            },
            //this method create for assign ranked its can be access only chief engineer means role ==3
            assignRanked(event, jobId) {
                var rankedId = event.target.value
                this.$http.get('/api/job/' + jobId + '/' + rankedId).then(response => {
                    this.getAllJobList()
                    this.$snotify.success('Ranked Assigned Successfully')
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    this.$snotify.error('Ranked Can Not be Assign')
                })
            },
            //this function use for change list mode
            changeListMode() {
                this.IRR = false
                this.P2W = false
                this.JobDoneStatus = false
                this.DeferReschedule = false
                this.listMode = true
                this.getAllJobList()
            },
            dataTableEnable() {
                // setTimeout(() => $('#datatables').DataTable(), 1000)
            },
        }
    }
</script>

<style scoped>
  .mx-datepicker {
    width: 100% !important;
  }

  @media print {
    /* Hide the button when printing */
    button {
      display: none;
    }
  }
</style>
