<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <h2 class="float-left">Spare Parts Consumption History List</h2>
          <ul class="nav navbar-right panel_toolbox" v-if="!listMode">
            <li>
              <button type="button" class="btn btn-info btn-sm" @click="changeListMode">View Job List <i class="fa fa-eye"></i></button>
            </li>
          </ul>
          <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
          <button @click="print('printArea','Spare Parts Consumption History Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
          <button @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
          <vue-excel-xlsx class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'spare-parts-consumption'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
        </div>

        <div class="">
          <div class="x_content ">
            <div class="row" v-if="listMode && jobList">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-3">
                        <div class="form-group">
                          <select class="form-control" v-model="scheduleInfo.vesselId">
                            <option value="" selected>Select Vessel</option>
                            <option v-for="(row, key) in vessels" :value="row.id">{{row.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <select v-model="scheduleInfo.department_id" name="department_id" class="form-control" @change="getMainComponent(scheduleInfo.department_id)">
                            <option value selected>Select department</option>
                            <option v-for="sdepartment in departments" :key="sdepartment.id" :value="sdepartment.id">{{ sdepartment.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <select v-model="scheduleInfo.main_component_id" name="main_component_id" class="form-control" @change="getComponentList(scheduleInfo.main_component_id)">
                            <option value selected>Select main component</option>
                            <option v-for="mainComponent in mainComponents" :key="mainComponent.id" :value="mainComponent.id">{{ mainComponent.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <select v-model="scheduleInfo.component_id" name="component_id" class="form-control" @change="getSubComponent(scheduleInfo.component_id)">
                            <option value selected>Select component</option>
                            <option v-for="component in components" :key="component.id" :value="component.id">{{ component.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <select v-model="scheduleInfo.sub_component_id" name="component_id" class="form-control" @change="getSubSubComponent(scheduleInfo.sub_component_id)">
                            <option value="0" selected>Select Sub component</option>
                            <option v-for="subcomponent in subcomponents" :key="subcomponent.id" :value="subcomponent.id">{{ subcomponent.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group">
                          <select v-model="scheduleInfo.sub_sub_component_id" name="component_id" class="form-control">
                            <option value="0" selected>Select Sub Sub component</option>
                            <option v-for="subsubcomponent in subsubcomponents" :key="subsubcomponent.id" :value="subsubcomponent.id">{{ subsubcomponent.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-3 col-6">
                        <div class="form-group">
                          <input type="text" v-model="scheduleInfo.searchInput" placeholder="Search....." name="searchInput" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-1">
                        <div class="form-group">
                          <button class="form-control btn-info btn btn-sm" @click="getAllJobList()">Search</button>
                        </div>
                      </div>
                    </div>

                    <div
                      v-if="loading"
                      class="col-md-12 text-center"
                      style="margin-top:25%"
                    >
                      <PulseLoader
                        :loading="loading"
                      ></PulseLoader>
                    </div>
                    <div v-if="!loading" class="card-box table-responsive">
                      <TableData :jobList="jobList?.data" :getItemRob="getItemRob" :getItemName="getItemName"/>
                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <div class="font-weight-bold">Spare Parts Consumption History List</div>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        </div>
                        <TableData :jobList="jobListWithoutPagination" :getItemRob="getItemRob" :getItemName="getItemName" :isPrinting="true"/>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <JobDone v-if="JobDoneStatus" :jobInfo="selectedJob" :mode="permitWorkMode" :componentName="componentName" :Schedule="scheduleData" @enableListMode="changeListMode"/>

            <!--            pagination -->
            <div v-if="listMode && jobList">
             <Pagination :pagination="jobList" :getData="getAllJobList"  />
            </div>

          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import {VueEditor} from 'vue2-editor'
    import auth from '../../../auth'
    import Pagination from "@/components/Pagination/Pagination.vue";
    import JobDone from '../JobDone.vue'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import exportMixin from "@/mixin/exportMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";


    export default {
        mixins: [printMixin, goBackButtonMixin, exportMixin],
        name: 'spare-parts-report',
        components: {
            TableData,
            Pagination,
            VueEditor,
            JobDone,
            DatePicker,
            PulseLoader
        },
        data() {
            return {
                appName: process.env.VUE_APP_NAME,
                loading: false,
                reportList: false,
                color: '#1ABB9C',
                size: '10px',
                jobId: 25,
                selectedComponent: '',
                selectedJob: Object,
                treeDisplayData: [],
                scheduleData: '',
                componentName: '',
                jobType: '',
                query: '',
                content: '',
                queryFiled: 'name',
                jobList: [],
                jobListWithoutPagination: [],
                jobRemarks: '',
                listMode: true,
                IRR: false,
                P2W: false,
                JobDoneStatus: false,
                DeferReschedule: false,
                editMode: false,
                permitWorkMode: false,
                tableEnableMode: false,
                chiefPermisionMode: false,
                vessels: null,
                departments: [],
                mainComponents: [],
                items: [],
                components: [],
                subcomponents: [],
                subsubcomponents: [],
                vessel_id: auth.getVesselId(),
                user_type: auth.getUserType(),
                form: new Form({
                    id: '',
                    name: '',
                    email: '',
                    status: 'Activate'
                }),
                // pagination: {
                //     current_page: 1,
                //     offset: 5
                // },
                pagination: {},
                currentPage: 1,
                filter: new Form({
                    dateRange: '',
                    jobType: '',
                    jobId: '',
                    vessel_id: auth.getVesselId(),
                }),
                scheduleInfo: new Form({
                    vesselId: auth.getVesselId(),
                    department_id: '',
                    main_component_id: '',
                    component_id: '',
                    sub_component_id: '0',
                    sub_sub_component_id: '0',
                    filterDate: '',
                    searchInput: ''

                }),
                excelHeader: [],
                excelData: []
            }
        },
        mounted() {
            this.getAllJobList(1);
            this.departmentList();
            this.geItems();
            this.getVessel();
        },
        methods: {
            getVessel() {
                this.$http.get('/api/vessel')
                    .then(response => {
                        this.vessels = response.data.success.vessel
                    })
                    .catch(e => {
                        console.log(e)
                    })
            },
            prepareExportData() {
                let exportData = [];
                this.jobListWithoutPagination.forEach((job, index) => {
                    job.spare_consumed.forEach((input, k) => {
                        const rowData = {
                            '#': k === 0 ? index + 1 : '',
                            'Job Name': k === 0 ? job.job_schedules.job.name + '(' + job.job_schedules.job.code + ')' : '',
                            'Component Name': k === 0 ? job.job_schedules.job.component.name + '(' + job.job_schedules.job.component?.code + ')' : '',
                            'Spares Consumed': this.getItemName(input.spares_consumed),
                            'Quantity': input.spares_qty,
                            'Completion Date': k === 0 ? this.$options.filters.moment(job.date, 'DD-MM-YYYY') : '', // Display the date only on the first row
                            'Maintenance Type': k === 0 ? job.maintenance_type : '',
                            'Done By': k === 0 ? job?.done_by_rank?.name : '' // Display the done by only on the first row
                        };
                        // Push the rowData to exportData
                        exportData.push(rowData);
                    });
                });
                return exportData;
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'spare-parts-consumption.csv');
            },
            excelExport() {
                let data = this.prepareExportData();
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            geItems() {
                let params = {
                    vessel_id: this.vessel_id
                };
                this.$http.get('/api/get-items', {params}).then((response) => {
                    this.items = response?.data?.success;
                }).catch((e) => {
                    console.log(e)
                })
            },
          getItemName(itemId) {
            if (this.items != null && this.items.length > 0) {
              let item =  this.items?.find(item => item.id == itemId)?.name;
              return item;
            }
            return "";

          },
          getItemRob(itemId) {
            if (this.items != null && this.items.length > 0) {
              let item =  this.items?.find(item => item.id == itemId)?.rob;
              return item;
            }
            return "";
          },
            getAllJobList(page = 1) {
                this.jobList = [];
                this.loading = true;
                this.jobListWithoutPagination = [];
                let params = {
                    vesselId: this.scheduleInfo.vesselId,
                    department_id: this.scheduleInfo.department_id,
                    main_component_id: this.scheduleInfo.main_component_id,
                    component_id: this.scheduleInfo.component_id,
                    sub_component_id: this.scheduleInfo.sub_component_id,
                    sub_sub_component_id: this.scheduleInfo.sub_sub_component_id,
                    searchInput: this.scheduleInfo.searchInput
                };
                this.$http.get('/api/filter/spare-parts-history-list?page=' + page, {params}).then(response => {
                    this.jobList = response.data?.jobData;
                    this.loading = false;
                    this.jobListWithoutPagination = response.data?.jobDataWithoutPagination;
                    this.excelExport();
                    // let lastPage = response.data?.jobData?.last_page;
                    // if (lastPage > 5) {
                    //     this.pagination.offset = 5;
                    // } else {
                    //     this.pagination.offset = lastPage;
                    // }
                }).catch(e => {
                    this.loading = false;
                    let response = e?.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {

                    }
                });
            },
            departmentList() {
                this.$http.get('/api/filter/department/' + this.vessel_id).then((response) => {
                    this.departments = response.data.success;
                }).catch((e) => {
                    console.log(e)
                });
            },
            getMainComponent(department_id) {
                this.mainComponents = [];
                this.components = [];
                this.subcomponents = [];
                this.subsubcomponents = [];
                this.scheduleInfo.main_component_id = '';
                this.scheduleInfo.component_id = '';
                this.scheduleInfo.sub_component_id = '0';
                this.scheduleInfo.sub_sub_component_id = '0';

                if (department_id !== '') {
                    this.$http.get('/api/filter/main-component/' + this.vessel_id + '/' + department_id).then((response) => {
                        this.mainComponents = response.data.success;
                    }).catch((e) => {
                        console.log(e)
                    });
                }
            },

            getComponentList(main_component_id) {
                this.components = [];
                this.subcomponents = [];
                this.subsubcomponents = [];
                this.scheduleInfo.component_id = '';
                this.scheduleInfo.sub_component_id = '0';
                this.scheduleInfo.sub_sub_component_id = '0';
                if (main_component_id !== '') {
                    this.$http.get('/api/filter/component/' + this.vessel_id + '/' + main_component_id).then((response) => {
                        this.components = response.data.success;
                    }).catch((e) => {
                        console.log(e)
                    });
                }
            },
            getSubComponent(component_id) {
                this.subcomponents = [];
                this.subsubcomponents = [];
                this.scheduleInfo.sub_component_id = '0';
                this.scheduleInfo.sub_sub_component_id = '0';
                if (component_id != '0') {
                    this.$http.get('/api/filter/sub-component/' + this.vessel_id + '/' + component_id).then((response) => {
                        this.subcomponents = response.data.success;
                    }).catch((e) => {
                        console.log(e)
                    });
                }
            },
            getSubSubComponent(sub_component_id) {
                this.subsubcomponents = []
                this.scheduleInfo.sub_sub_component_id = '0';
                if (sub_component_id != '0') {
                    this.$http.get('/api/filter/sub-sub-component/' + this.vessel_id + '/' + sub_component_id).then((response) => {
                        this.subsubcomponents = response.data.success;
                    }).catch((e) => {
                        console.log(e)
                    });
                }
            },
            jobDoneFunction(job) {
                if (job?.job_done_report && job?.job_done_report != null) {
                    this.permitWorkMode = true
                } else {
                    this.permitWorkMode = false
                }
                if (this.user_type <= 3 && job?.job_done_report == null) {
                    this.$snotify.warning('Job Done Report is not Submitted')
                } else {
                    this.listMode = false
                    this.IRR = false
                    this.P2W = false
                    this.JobDoneStatus = true
                    this.selectedJob = job?.job
                    this.scheduleData = job
                    this.componentName = job?.job?.component?.name
                }
            },
            //this function use for change list mode
            changeListMode() {
                this.IRR = false;
                this.P2W = false;
                this.JobDoneStatus = false;
                this.DeferReschedule = false;
                this.listMode = true;
                this.getAllJobList();
            }
        }
    }
</script>

<style scoped>
  .mx-datepicker {
    width: 100% !important;
  }

</style>
