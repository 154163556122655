<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left">Less Receive Item List</h2>
              <div>
                <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                <button @click="print('printArea','Less Receive Item List ')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>
                      <TableData :deliveryData="deliveryData"/>
                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <h4>Vessel Name: {{this.vessel_name}}</h4>
                          <div class="font-weight-bold">Less Receive Item List</div>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        </div>
                        <TableData :deliveryData="deliveryData" :isPrinting="true"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
    import auth from '../../../auth'
    import TableData from "./TableData.vue";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [printMixin, goBackButtonMixin],
        name: 'LessDeliveryList',
        components: {TableData, PulseLoader},
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                vessel_name: auth.getVesselName(),
                editMode: false,
                receiveItemData: [],
                purchaseItemData: [],
                deliveryData: [],
                CountryData: [],
                portData: [],
                form: new Form({
                    requisition_id: '',
                    supplier_id: '',
                    title: '',
                    date: '',
                    remark: '',
                    vessel_id: auth.getVesselId(),
                }),
                received_date: '',
                country_id: '',
                port_id: '',
                vessel_id: auth.getVesselId(),
                pagination: {
                    current_page: 1
                }
            }
        },
        mounted() {
            this.getDeliveryData()
            this.getCountryData()
        },
        methods: {
            getDeliveryData() {
                this.loading = true
                this.$http.post('/api/filter/less-delivery-list/' + this.vessel_id).then(response => {
                    this.loading = false;
                    this.deliveryData = response.data.success;
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    this.loading = false
                })
            },
            setDateFormat(event) {
                this.form.date = moment(event.target.value).format('YYYY-MM-DD')
            },
            updateQuantity(index, event) {
                this.purchaseItemData[index].receive = event.target.value
            },
            getCountryData() {
                this.$http.get('/api/country').then(response => {
                    this.CountryData = response.data.success

                }).catch(e => {
                    console.log(e)
                })
            },

        }
    }
</script>

<style scoped>

</style>
