<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left">Purchase List</h2>
              <div>
                <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                <button @click="print('printArea','Breakdown History Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>
                      <TableData
                        :listData="listData"
                        :approveRequest="approveRequest"
                        :showBudget="showBudget"
                        :show="show"
                        :edit="edit"
                        :updateInvoice="updateInvoice"
                        :showEvaluationFormModal="showEvaluationFormModal"
                      />
                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <h4>Vessel Name: {{this.vessel_name}}</h4>
                          <div class="font-weight-bold">Purchase List</div>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        </div>
                        <TableData :listData="listData" :isPrinting="true"/>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- purchase view Modal -->
      <div class="modal fade" id="purchaseModal" tabindex="-1" role="dialog" aria-labelledby="requisitionModalTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="requisitionModalTitle">Purchase Details </h5>
              <div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <button @click="print('modalPrint','Purchase Details')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>

              </div>
            </div>
            <div class="modal-body" v-if="purchaseData">
              <ModalData :purchaseData="purchaseData"/>
              <div id="modalPrint" style="display: none">
                <div style="text-align: center">
                  <h2>{{ appName }}</h2>
                  <h4>Vessel Name: {{this.vessel_name}}</h4>
                  <h3>Purchase Details </h3>
                  <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                </div>
                <ModalData :purchaseData="purchaseData" :isPrinting="true"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- purchase view Modal -->

      <!-- purchase view Modal -->
      <div class="modal fade" id="purchaseEditModal" tabindex="-1" role="dialog" aria-labelledby="requisitionModalTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="requisitionModalTitle">Edit Purchase </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <form @submit="storePo" class="requisitionForm">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Purchase Title<span class="text-danger">*</span></label>
                      <input v-model="purchaseDataForm.title" type="text" name="title" class="form-control" placeholder="Enter title" autocomplete="off" required>
                    </div>
                  </div>


                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Date</label>
                      <datetime format="YYYY-MM-DD" width="300px" v-model="purchaseDataForm.date"></datetime>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Shipping cost<span class="text-danger">*</span></label>
                      <input v-model="purchaseDataForm.shipping_cost" type="number" name="shipping_cost" value="0" class="form-control" min="0" step="any" required>
                    </div>
                  </div>
                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Discount Amount </label>
                      <input v-model="purchaseDataForm.discount_amount" type="number" name="discount_amount" value="0" class="form-control" min="0" step="any">
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Tax(%)<span class="text-danger">*</span></label>
                      <input v-model="purchaseDataForm.tax" type="number" name="tax" value="0" class="form-control" min="0" placeholder="Enter tax" required>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Supplier Currency <span class="text-danger">*</span></label>
                      <select v-model="purchaseDataForm.currency_id" name="supplier_id" class="form-control" required>
                        <option v-for="currency in currencies" :key="currency.id" :value="currency.id">{{currency.name}}</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-2">
                    <div class="form-group">
                      <label>Currency Rate<span class="text-danger">*</span></label>
                      <input v-model="purchaseDataForm.currency_rate" type="number" value="0" class="form-control" min="0" step="any" required placeholder="e.g. for US Dollar 1">
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Notes</label>
                      <input v-model="purchaseDataForm.remark" name="remark" class="form-control" placeholder="Enter notes">
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" class="btn btn-info">Submit</button>
                </div>
              </form>

              <hr>

              <table class="table table-bordered" v-if="purchaseData">
                <thead>
                <tr>
                  <th>Status</th>
                  <th>Item name</th>
                  <th>Impa code</th>
                  <th>Maker Part no</th>
                  <th>Maker</th>
                  <th>Model</th>
                  <th>Opening stock</th>
                  <th>Details</th>
                  <th>Unit</th>
                  <th class="text-center">Qty</th>
                  <th class="text-right">Price</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item , index) in purchaseData.purchase_item" :key="index">
                  <td>{{item.status}}</td>
                  <td>{{item.item.name}}</td>
                  <td>{{item.item.inpa_code}}</td>
                  <td>{{item.item.maker_part_no}}</td>
                  <td>{{item.item.maker}}</td>
                  <td>{{item.item.model}}</td>
                  <td>{{item.item.opening_stock}}</td>
                  <td>{{item.item.description}}</td>
                  <td>{{item.item.unit.name}}</td>
                  <td class="text-center">{{item.qty}}</td>
                  <td class="text-right">${{item.price}}</td>
                </tr>
                </tbody>
              </table>

              <div class="table-responsive">
                <table class="table text-right table-bordered" v-if="purchaseData">
                  <tbody>
                  <tr>
                    <th>Sub Total:</th>
                    <td width="10%">${{purchaseData.amount}}</td>
                  </tr>
                  <tr>
                    <th>Tax ({{parseFloat(purchaseData.tax).toFixed(2)}}%)</th>
                    <td>${{purchaseData.tax_amount}}</td>
                  </tr>
                  <tr>
                    <th>Shipping Cost:</th>
                    <td>${{purchaseData.shipping_cost}}</td>
                  </tr>
                  <tr>
                    <th>Grand Total:</th>
                    <td>${{purchaseData.grand_amount}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- purchase view Modal -->

      <!-- Invoice payment Modal -->
      <div class="modal fade" id="invoicePaymentModal" tabindex="-1" role="dialog" aria-labelledby="invoicePaymentModalTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="invoicePaymentModalTitle"><span v-if="PaidDetails.invoice_exist === true">Update</span>  <span v-else>Create</span> Invoice </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form method="post" @submit="submitPaidAmount" class="requisitionForm">
                <div class="row" v-if="PaidDetails">

                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Invoice amount<span class="text-danger">*</span></label>
                      <input v-model="PaidDetails.amount" readonly type="number" name="amount" value="0" class="form-control" min="0" step="any" autocomplete="off" required>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Date</label>
                      <datetime format="YYYY-MM-DD" width="300px" v-model="PaidDetails.date"></datetime>
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" class="btn btn-info">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- Invoice payment Modal -->

      <!-- Create purchase order Modal -->
      <div class="modal fade" id="evaluationFormModal" tabindex="-1" role="dialog" aria-labelledby="purchaseOrderModalTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="purchaseOrderModalTitle">New Evaluation</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit="store" class="requisitionForm">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Communication Skill</label>
                      <div>
                        <label class="mr-3"><input v-model="form.communication_skill" name="skill" type="radio" value="Poor"> Poor</label>
                        <label class="mr-3"><input v-model="form.communication_skill" name="skill" type="radio" value="Average"> Average</label>
                        <label class="mr-3"><input v-model="form.communication_skill" name="skill" type="radio" value="Good"> Good</label>
                        <label class="mr-3"><input v-model="form.communication_skill" name="skill" type="radio" value="Better"> Better</label>
                        <label><input v-model="form.communication_skill" name="skill" type="radio" value="Best"> Best</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Delivery time</label>
                      <div>
                        <label class="mr-3"><input v-model="form.delivery_time" name="time" type="radio" value="Poor"> Poor</label>
                        <label class="mr-3"><input v-model="form.delivery_time" name="time" type="radio" value="Average"> Average</label>
                        <label class="mr-3"><input v-model="form.delivery_time" name="time" type="radio" value="Good"> Good</label>
                        <label class="mr-3"><input v-model="form.delivery_time" name="time" type="radio" value="Better"> Better</label>
                        <label><input v-model="form.delivery_time" name="time" type="radio" value="Best"> Best</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Response time</label>
                      <div>
                        <label class="mr-3"><input v-model="form.response_time" name="response_time" type="radio" value="Poor"> Poor</label>
                        <label class="mr-3"><input v-model="form.response_time" name="response_time" type="radio" value="Average"> Average</label>
                        <label class="mr-3"><input v-model="form.response_time" name="response_time" type="radio" value="Good"> Good</label>
                        <label class="mr-3"><input v-model="form.response_time" name="response_time" type="radio" value="Better"> Better</label>
                        <label><input v-model="form.response_time" name="response_time" type="radio" value="Best"> Best</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Item quality</label>
                      <div>
                        <label class="mr-3"><input v-model="form.item_quality" name="quality" type="radio" value="Poor"> Poor</label>
                        <label class="mr-3"><input v-model="form.item_quality" name="quality" type="radio" value="Average"> Average</label>
                        <label class="mr-3"><input v-model="form.item_quality" name="quality" type="radio" value="Good"> Good</label>
                        <label class="mr-3"><input v-model="form.item_quality" name="quality" type="radio" value="Better"> Better</label>
                        <label><input v-model="form.item_quality" name="quality" type="radio" value="Best"> Best</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Item pricing</label>
                      <div>
                        <label class="mr-3"><input v-model="form.item_pricing" name="pricing" type="radio" value="Poor"> Poor</label>
                        <label class="mr-3"><input v-model="form.item_pricing" name="pricing" type="radio" value="Average"> Average</label>
                        <label class="mr-3"><input v-model="form.item_pricing" name="pricing" type="radio" value="Good"> Good</label>
                        <label class="mr-3"><input v-model="form.item_pricing" name="pricing" type="radio" value="Better"> Better</label>
                        <label><input v-model="form.item_pricing" name="pricing" type="radio" value="Best"> Best</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="submit" class="btn btn-info">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import datetime from 'vuejs-datetimepicker'
    import auth from '../../auth'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import ModalData from "./ModalData.vue";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        name: 'PurchaseList',
        components: {ModalData, TableData, datetime, PulseLoader},
        data() {
            return {
                loading: false,
                editMode: false,
                appName: process.env.VUE_APP_NAME,
                supplierData: [],
                vessel_name: auth.getVesselName(),
                currencies: null,
                purchaseData: null,
                purchaseDataForm: {
                    title: '',
                    currency: {
                        code: ''
                    }
                },
                form: {
                    vessel_id: auth.getVesselId(),
                },
                PaidDetails: {
                    vessel_id: auth.getVesselId(),
                    purchase_id: '',
                    amount: '0',
                    date: '',
                    invoice_exist:false
                },
                listData: [],
                vessel_id: auth.getVesselId(),
                pagination: {
                    current_page: 1
                }
            }
        },
        mounted() {
            this.getQuotationData()
            this.getCurrency()
        },
        methods: {
            getQuotationData() {
                this.loading = true;
                this.$http.get('/api/filter/purchase/' + this.vessel_id).then(response => {
                    this.loading = false;
                    this.listData = response.data.success;
                    setTimeout(() => $('#datatables').DataTable({
                        'ordering': false,
                        'aaSorting': [],
                        'bDestroy': true
                    }), 1000)
                }).catch(e => {
                    this.loading = false;
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error("something went wrong")
                    }
                })
            },
            getCurrency() {
                this.$http.get('/api/currency').then(response => {
                    this.currencies = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            show(id) {
                this.$http.get('/api/purchase/' + id).then(response => {
                    this.purchaseData = response.data
                    $('#purchaseModal').modal('show')
                }).catch(e => {
                    console.log(e)
                })
            },

            storePo: function (e) {
                e.preventDefault()
                this.$http.post('/api/purchase-update', this.purchaseDataForm).then(response => {
                    $('#purchaseEditModal').modal('hide')
                    if (response.data.success) {
                        this.$snotify.success('PO Updated successfully')
                        this.getQuotationData()
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error("something went wrong")
                    }
                })
            },

            edit(id) {
                $('#purchaseEditModal').modal('show');
                this.$http.get('/api/purchase/' + id).then(response => {
                    this.purchaseData = response.data;
                    this.purchaseDataForm = response.data;
                }).catch(e => {
                    console.log(e)
                })
            },

            showBudget(id, index) {
                this.$http.get('/api/get-budget-info/' + id).then(response => {
                    $('.budget-' + index).html('Budget:' + response.data.totalBudget + ', Budget Left:' + response.data.totalBudgetLeft)
                }).catch(e => {
                    console.log(e)
                    this.$snotify.error(e.response.data.error)
                    $('.budget-' + index).html(e.response.data.error)
                })
            },
            updateInvoice(row) {
                $('#invoicePaymentModal').modal('show');
                this.PaidDetails.purchase_id = row.id;
                this.PaidDetails.amount = row.actual_amount;
                this.PaidDetails.invoice_exist = row.invoice_exist;

            },
            approveRequest(type, ItemId, status, key) {
                this.$http.post(`/api/approve`, {
                    type: type,
                    status: status,
                    key: key,
                    ref_id: ItemId
                }).then((response) => {
                    if (response.data.success) {
                        this.getQuotationData();
                        this.$snotify.success('Request Approved Successfully.')
                    }
                }).catch((error) => {
                    console.log(error);
                    if (error.response.status) {
                        this.$snotify.error('Request Approval Failed')
                    }
                })
            },
            submitPaidAmount: function (e) {
                e.preventDefault();
                this.$http.post('/api/invoice', this.PaidDetails).then(response => {
                    $('#invoicePaymentModal').modal('hide');
                    if (response.data.success) {
                        this.$snotify.success(response.data.message);
                        this.$router.push({
                            name: 'InvoiceView',
                            params: {
                                purchaseID: response.data.success.purchase_id,
                                invoiceID: response.data.success.id
                            }
                        })
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch(e => {
                    this.$snotify.error(e.response.data.error)
                    // if (response.data?.errors) {
                    //   const errors = response.data.errors;
                    //   for (const key in errors) {
                    //     toast.error(errors[key][0]);
                    //   }
                    // }
                    // this.$snotify.error(error.response.data.error)
                })
            },
            showEvaluationFormModal(row) {
                this.form.supplier_id = row.supplier_id
                this.form.purchase_id = row.id
                $('#evaluationFormModal').modal('show')
            },
            store: function (e) {
                e.preventDefault()

                if (!this.form.communication_skill || !this.form.delivery_time || !this.form.response_time
                    || !this.form.item_quality || !this.form.item_pricing) {
                    this.$snotify.error('Please answer all questions.')
                    return false
                }

                this.$http.post('/api/supplier-evaluation', this.form).then(response => {
                    $('#evaluationFormModal').modal('hide')
                    if (response.data.success) {
                        this.$snotify.success('Evaluation added successfully')
                    } else {
                        this.$snotify.error('Something went wrong try again later.')
                    }
                }).catch(e => {
                    this.$snotify.error(e.response.data.error)
                })
            },
            print(id, title = '') {
                printUtil(id, title);
            },
            goBack() {
                this.$router.go(-1);
            }
        }
    }
</script>

<style scoped>

</style>
