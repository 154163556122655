<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>#</th>
      <th>Item Name</th>
      <th>Impa Code</th>
      <th>Model</th>
      <th>RFQ No</th>
      <th>P.O No</th>
      <th>Order Qty</th>
      <th>Receive Qty</th>
      <th>Return Qty</th>
      <th>Damage Qty</th>
      <th>Status</th>
    </tr>
    </thead>
    <tbody>

    <tr v-for="(row , index) in reportData" :key="index">
      <td>{{++index}}</td>
      <td>{{row?.item?.name}}</td>
      <td>{{row?.item?.inpa_code}}</td>
      <td>{{row?.item?.model}}</td>
      <td>{{row?.purchase?.requisition?.requisition_no}}</td>
      <td>{{row?.purchase?.po_no}}</td>
      <td>{{row?.order_qty}}</td>
      <td>{{row?.qty}}</td>
      <td>{{row?.returned_qty}}</td>
      <td>{{row?.damage_qty}}</td>
      <td>
        <span v-if="row?.status == 1" class="badge badge-success">Received</span>
        <span v-else-if="row?.status == 0" class="badge badge-danger">Not Received</span>
      </td>

    </tr>

    <tr v-if="noDataFound">
      <td colspan="13">No data found</td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    reportData: {
      required: true
    },
    noDataFound: {
      type: Boolean,
      default: false
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
