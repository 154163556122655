<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left">Non Conformity</h2>
              <ul class="nav navbar-right panel_toolbox align-right">
                <li>
                  <button type="button" v-if="isPermissionExist(PERMISSIONS.NON_CONFORMITY)" class="btn btn-info btn-sm" @click="create">Add New <i class="fa fa-plus"></i></button>
                </li>
                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                  <button @click="print('printArea','Vessel Certificate')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
                  <button @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
                  <vue-excel-xlsx class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'non-conformity'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>
                      <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Reference No</th>
                          <th>Type(Convention/Code)</th>
                          <th>Type( 3rd Party Inspection)</th>
                          <th>Name of Company</th>
                          <th>Manual/Publication Ref</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row , index) in dataList" :key="index">
                          <td>{{index+1}}</td>
                          <td>{{row.ref_no || ''}}</td>
                          <td>{{row.convention_code || ''}}</td>
                          <td>{{row.third_party_inspection || ''}}</td>
                          <td>{{row.company_name || ''}}</td>
                          <td>{{row.publication_ref || ''}}</td>
                          <td>
                            <button type="button"  @click="show(row)" title="View" class="btn btn-info btn-sm"><i class="fa fa-eye"></i></button>
                            <button type="button" v-if="isPermissionExist(PERMISSIONS.NON_CONFORMITY)" @click="edit(row)" class="btn btn-primary btn-sm"><i class="fa fa-edit"></i></button>
                            <button
                              v-if="isPermissionExist(PERMISSIONS.NON_CONFORMITY)"
                              type="button"
                              @click="destroy(row)"
                              class="btn btn-danger btn-sm"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <h4>Vessel Name: {{this.vessel_name}}</h4>
                          <h3>Non Conformity</h3>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        </div>
                        <table class="table  print-table" style="width: 100%">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Reference No</th>
                            <th>Type(Convention/Code)</th>
                            <th>Type( 3rd Party Inspection)</th>
                            <th>Name of Company</th>
                            <th>Manual/Publication Ref</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(row , index) in dataList" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{row.ref_no || ''}}</td>
                            <td>{{row.convention_code || ''}}</td>
                            <td>{{row.third_party_inspection || ''}}</td>
                            <td>{{row.company_name || ''}}</td>
                            <td>{{row.publication_ref || ''}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">{{ editMode ? "Edit" : "Add New" }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-6 form-group">
                    <label>Reference No<span class="required">*</span></label>
                    <input
                      v-model="form.ref_no"
                      type="text"
                      name="ref_no"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('ref_no') }"
                    >
                    <has-error :form="form" field="ref_no"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Company Name<span class="required">*</span></label>
                    <input
                      v-model="form.company_name"
                      type="text"
                      name="company_name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('company_name') }"
                    >
                    <has-error :form="form" field="company_name"></has-error>
                  </div>


                  <div class="form-group col-md-6">
                    <label>Type(Convention/Code)  <span class="required">*</span></label>
                    <select v-model="form.convention_code" name="convention_code" class="form-control" :class="{ 'is-invalid': form.errors.has('convention_code') }">
                      <option value="">Select Option</option>
                      <option value="ISM">ISM</option>
                      <option value="ISPS">ISPS</option>
                      <option value="ISO">ISO</option>
                     </select>
                    <has-error :form="form" field="convention_code"></has-error>
                  </div>
                  <div class="form-group col-md-6">
                    <label>Type( 3rd Party Inspection) <span class="required">*</span></label>
                    <select v-model="form.third_party_inspection" name="third_party_inspection" class="form-control" :class="{ 'is-invalid': form.errors.has('third_party_inspection') }">
                      <option value="">Select Option</option>
                      <option value="Flag">Flag</option>
                      <option value="Class">Class</option>
                      <option value="PSC">PSC</option>
                      <option value="Others">Others</option>
                    </select>
                    <has-error :form="form" field="third_party_inspection"></has-error>
                  </div>


                  <div class="col-md-6 form-group">
                    <label>Manual/Publication Ref<span class="required">*</span></label>
                    <input
                      v-model="form.publication_ref"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('publication_ref') }"
                    >
                    <has-error :form="form" field="publication_ref"></has-error>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Description of Non-Conformance (Findings) </label>
                      <textarea v-model="form.non_conformance_description"
                                name="non_conformance_description" class="form-control">
                      </textarea>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Objective findings </label>
                      <textarea v-model="form.objective_findings"
                                name="objective_findings" class="form-control">
                      </textarea>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Suggestions </label>
                      <textarea v-model="form.suggestions"
                                name="suggestions" class="form-control">
                      </textarea>
                    </div>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Name of Person Raising NC </label>
                    <input
                      v-model="form.name_person_raising"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('name_person_raising') }"
                    >
                    <has-error :form="form" field="name_person_raising"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Place of Person Raising NC </label>
                    <input
                      v-model="form.person_raising_place"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('person_raising_place') }"
                    >
                    <has-error :form="form" field="person_raising_place"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Date of Person Raising NC</label>
                    <datetime format="YYYY-MM-DD" v-model="form.person_raising_date"></datetime>
                    <has-error :form="form" field="person_raising_date"></has-error>
                  </div>


                  <div class="col-md-6 form-group">
                    <label>Name of PIC for CAP  </label>
                    <input
                      v-model="form.pic_name"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('pic_name') }"
                    >
                    <has-error :form="form" field="pic_name"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Place of PIC for CAP </label>
                    <input
                      v-model="form.pic_place"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('pic_place') }"
                    >
                    <has-error :form="form" field="pic_place"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Date of PIC for CAP </label>
                    <datetime format="YYYY-MM-DD" v-model="form.pic_date"></datetime>
                    <has-error :form="form" field="pic_date"></has-error>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Modal -->


      <div class="modal fade" id="nonConformityViewModal" tabindex="-1" role="dialog" aria-labelledby="requisitionModalTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="requisitionModalTitle">Non Conformity</h5>
              <div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <button @click="print('nonConformityPrint','Dry Dock')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </div>

            </div>
            <div class="modal-body">
              <NonConformityView :data="form"/>

              <div id="nonConformityPrint" style="display: none">
                <div style="text-align: center">
                  <h2>{{appName}}</h2>
                  <h4>Vessel Name:  {{ this.vessel_name }}</h4>
                  <h3>Non Conformity </h3>
                  <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                </div>
                <NonConformityView :data="form" :isPrinting="true"/>


              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
    import datetime from 'vuejs-datetimepicker';
    import auth from "@/auth";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import exportMixin from "@/mixin/exportMixin";
    import printMixin from "@/mixin/printMixin";
    import TableData from "@/components/reports/Requisition/TableData.vue";
    import ModalData from "@/components/DryDock/ModalData.vue";
    import NonConformityView from "@/components/SafetyQuality/NonConformityView.vue";
    import permissionsMixin from "@/mixin/permissionsMixin";

    export default {
        name: "NonConformity",
        mixins: [goBackButtonMixin, exportMixin, printMixin,permissionsMixin],
        components: {NonConformityView, ModalData, TableData, PulseLoader, datetime},
        data() {
            return {
                loading: false,
                editMode: false,
                query: "",
                vesselId: auth.getVesselId(),
                vessel_name: auth.getVesselName(),
                permissions: auth.getPermissionData(),
                appName: process.env.VUE_APP_NAME,
                queryFiled: "name",
                dataList: null,
                vesselCertificateTypes: null,
                form: new Form({
                    id: "",
                    ref_no: "",
                    company_name: "",
                  convention_code: '',
                  third_party_inspection: '',
                  publication_ref: '',
                  non_conformance_description: '',
                  objective_findings: '',
                  suggestions: '',
                  name_person_raising: '',
                  person_raising_place: '',
                  person_raising_date: '',
                  pic_name: '',
                  pic_place: '',
                  pic_date: '',
                }),
                excelHeader: [],
                excelData: [],
                pagination: {
                    current_page: 1
                }
            };
        },
        mounted() {
            this.getData();
            this.getVesselCertificateTypes();
        },
        methods: {
            prepareExportData() {
                return this.dataList.map(item => ({
                    'Vessel Name' : auth.getVesselName(),
                    'Reference No': item?.ref_no,
                    'Type(Convention/Code)': item?.convention_code,
                    'Type( 3rd Party Inspection)': item?.third_party_inspection,
                    'Name of Company': item?.company_name,
                    'Manual/Publication Ref': item?.publication_ref,
                    'Description of Non-Conformance (Findings) ': item?.non_conformance_description,
                    'Objective Findings': item?.objective_findings,
                    'Suggestions': item?.suggestions,
                    'Name Of Person Raising NC': item?.name_person_raising,
                    'Place Of Person Raising NC': item?.person_raising_place,
                    'Date Of Person Raising NC': item?.person_raising_date,
                    'Name Of PIC for CAP': item?.pic_name,
                    'Place Of PIC for CAP': item?.pic_place,
                    'Date Of PIC for CAP': item?.pic_date,
                }));
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'non-conformity.csv');
            },
            excelExport() {
                let data = this.prepareExportData();
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                //data.shift();
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            getData() {
                this.loading = true;
                this.$http.get("/api/filter/non-conformity/" + this.vesselId).then(response => {
                    this.loading = false;
                    this.dataList = response.data.success;
                  console.log(this.dataList)
                    setTimeout(() => $('#datatables').DataTable(), 1000);
                    this.excelExport();
                }).catch(e => {
                    this.loading = false;
                    let response = e?.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },
            getVesselCertificateTypes() {
                this.$http.get("/api/filter/vessel-certificate-type/" + this.vesselId).then(response => {
                    this.vesselCertificateTypes = response.data.success;
                }).catch(e => {
                    console.log(e);
                });
            },
            onImageChange(e) {
                let file = e.target.files[0];
                this.form.doc = file;
            },
            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                $("#roleModal").modal("show");
            },
            store() {
                this.form.busy = true;
                this.form.vessel_id = this.vesselId;
                this.form.post("/api/non-conformity").then(response => {
                    this.getData();
                    $("#roleModal").modal("hide");
                    if (this.form.successful) {
                        $('.dataTables_empty').closest("tr").remove();
                        this.$snotify.success("Data Created Successfully");
                    } else {
                        this.$snotify.error("Something went wrong try again later.");
                    }
                }).catch(e => {
                    console.log(e);
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else if (response.status === 422) {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    } else {
                        var error = this.form.errors.errors.error;
                        this.$snotify.error(error);
                    }
                });
            },
            show(row) {
              this.form.reset();
              this.form.clear();
              this.form.fill(row);
              $('#nonConformityViewModal').modal('show')

            },
            edit(row) {
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.form.fill(row);
                $("#roleModal").modal("show");
                console.log(this.form);
            },
            update() {
                this.form.busy = true;
                this.form.vessel_id = this.vesselId;
                this.form._method = 'put';
                this.form.post("/api/non-conformity/" + this.form.id).then(response => {
                    this.getData();
                    $("#roleModal").modal("hide");
                    if (this.form.successful) {
                        this.$snotify.success("Data Successfully Updated");
                    } else {
                        this.$snotify.error("Something went wrong try again later.");
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },
            destroy(role) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete("/api/non-conformity/" + role.id).then(response => {
                        this.getData();
                        this.$snotify.success("Data Successfully Deleted", "Success");
                    }).catch(error => {
                        let response = error.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            var err = error.response.data.error;
                            this.$snotify.error(err);
                        }
                    });
                })
                    .catch(function () {

                    });
            }

        }
    }
</script>

<style scoped>

</style>
