var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{class:_vm.getTableClasses,staticStyle:{"width":"100%"}},[_c('tbody',[_vm._m(0),_c('tr',[_vm._m(1),_c('th',[_vm._v(_vm._s(_vm.data?.ref_no))])]),_c('tr',[_vm._m(2),_c('th',[_vm._v(_vm._s(_vm.data?.convention_code))])]),_c('tr',[_vm._m(3),_c('th',[_vm._v(_vm._s(_vm.data?.third_party_inspection))])]),_c('tr',[_vm._m(4),_c('th',[_vm._v(_vm._s(_vm.data?.company_name))])]),_vm._m(5),_c('tr',[_vm._m(6),_c('th',[_vm._v(_vm._s(_vm.data?.publication_ref))])]),_c('tr',[_vm._m(7),_c('th',[_vm._v(_vm._s(_vm.data?.non_conformance_description))])]),_c('tr',[_vm._m(8),_c('th',[_vm._v(_vm._s(_vm.data?.objective_findings))])]),_c('tr',[_vm._m(9),_c('th',[_vm._v(_vm._s(_vm.data?.suggestions))])]),_vm._m(10),_c('tr',[_vm._m(11),_c('th',[_vm._v(" "+_vm._s(_vm.data?.name_person_raising))])]),_c('tr',[_vm._m(12),_c('th',[_vm._v(" "+_vm._s(_vm.data?.person_raising_place))])]),_c('tr',[_vm._m(13),_c('th',[_vm._v(" "+_vm._s(_vm.data?.person_raising_date))])]),_c('tr',[_vm._m(14),_c('th',[_vm._v(" "+_vm._s(_vm.data?.pic_name))])]),_c('tr',[_vm._m(15),_c('th',[_vm._v(" "+_vm._s(_vm.data?.pic_place))])]),_c('tr',[_vm._m(16),_c('th',[_vm._v(" "+_vm._s(_vm.data?.pic_date))])])])]),_c('div')])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{attrs:{"colspan":"2"}},[_c('strong',[_vm._v("1st Section (General Info) ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Reference No: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Type(Convention/Code): ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Type( 3rd Party Inspection): ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Name Of Company: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{attrs:{"colspan":"2"}},[_c('strong',[_vm._v("2nd Section (Description) ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Manual/Publication Ref: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Description of Non-Conformance (Findings): ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Objective Findings: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Suggestions: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{attrs:{"colspan":"2"}},[_c('strong',[_vm._v("3rd Section( Person raising and Person Responsible) : ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Name Of Person Raising NC: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Place Of Person Raising NC: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Date Of Person Raising NC: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Name Of PIC For CAP: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Place Of PIC For CAP: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Date Of PIC For CAP: ")])])
}]

export { render, staticRenderFns }