<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading" class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left">Other Consumption History</h2>
            <ul class="nav navbar-right panel_toolbox align-right">
              <li>
                <button @click="print('printArea','Item List')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </li>
              <li>
                <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              </li>
            </ul>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="card">
                <div class="card-body">
                  <form role="form" action="#" @submit="getData" class="searchForm">
                    <div class="x_panel">
                      <div class="x_content">
                        <div class="row justify-content-center">
                          <div class="col-md-2">
                            <div class="form-group">
                              <label>Vessel</label>
                              <select required class="form-control" v-model="formData.vessel">
                                <option value="" selected>Select Vessel</option>
                                <option v-for="(row, key) in vessels" v-bind:value="{ id: row.id, name: row.name }">{{row.name}}</option>
                              </select>
                            </div>
                          </div>
                          <div class="col-md-2 mx-2">
                            <div class="form-group">
                              <label>Date From</label>
                              <DatePicker type="date" v-model="formData.dateFrom" valueType="format"></DatePicker>
                            </div>
                          </div>
                          <div class="col-md-2 mx-2">
                            <div class="form-group">
                              <label>Date To</label>
                              <DatePicker type="date" v-model="formData.dateTo" valueType="format"></DatePicker>
                            </div>
                          </div>
                          <div class="col-md-2">
                            <button style="margin-top: 26px" type="submit" class="btn btn-success btn-sm">Search</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>

                  <div class="card-box table-responsive">
                    <p class="text-muted font-13 m-b-30"></p>
                    <ItemTable :items="items" />


                    <div id="printArea" style="display: none">
                      <div style="text-align: center;line-height: 20px;">
                        <h2>{{ appName }}</h2>
                        <h4>Vessel Name: {{this.vessel_name}}</h4>
                        <div class="font-weight-bold">Item List</div>
                        <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        <ItemTable :items="items"   :isPrinting="true"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

    import Multiselect from 'vue-multiselect'
    import Select2 from 'v-select2-component'
    import QrcodeVue from 'qrcode.vue'
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import auth from '../../../auth'
    import ItemTable from "@/components/Item/TopTenConsumeItem/ItemTable.vue";
    import PermissionsMixin from "@/mixin/permissionsMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import printMixin from "@/mixin/printMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [PermissionsMixin, goBackButtonMixin, printMixin],
        name: 'TopTenConsumeItem',
        components: {
            ItemTable,
            PulseLoader,
            DatePicker
        },
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                permissions: auth.getPermissionData(),
                vesselId: auth.getVesselId(),
                vessel_name: auth.getVesselName(),
                items: [],
                vessels: null,
                formData: {
                  vessel: {
                    id: auth.getVesselId(),
                    name: ''
                  },
                  dateFrom: '',
                  dateTo: '',
                },
            }
        },
        mounted() {
            this.getVessel()
            this.getData()
        },
        methods: {
          getData: function (e) {
            if (e !== undefined) {
              e.preventDefault()
            }
            this.loading = true;
            if (this.formData.vessel) this.formData.vessel_id = this.formData.vessel.id
            let params = {
              vessel_id: this.formData.vessel_id,
              date_from: this.formData.dateFrom,
              date_to: this.formData.dateTo
            };
            this.$http.get('/api/top-ten-consume-item', {params}).then((response) => {
              this.loading = false;
              console.log(response.data)
              if (response.data) {
                 this.items = response.data.success;
                // this.excelExport();
              }
            }).catch((error) => {
              this.loading = false;
              let response = error.response;
              if (response?.status === 403) {
                this.$snotify.warning(response?.data?.error)
              } else {

              }
            })
          },

          getVessel() {
            this.$http.get('/api/vessel').then(response => {
              this.vessels = response.data.success.vessel
              const selectedVessel = this.vessels.find(vessel => vessel.id == this.vesselId);
              if (selectedVessel) {
                this.formData.vessel = {
                  id: selectedVessel.id,
                  name: selectedVessel.name
                };
              }
            }).catch(e => {
              console.log(e)
            })
          },

        }
    }
</script>
<style>

</style>
