<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left"> {{listMode ? 'Over Due Job Report' : 'Deferred Rescheduled List'}} </h2>
            <div v-if="listMode">
              <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
              <button @click="print('printArea','Over Due Job Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              <button @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
              <vue-excel-xlsx class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'over-due-job'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
              <router-link to="/monthly-over-due-job" class="btn btn-info btn-sm float-right">Monthly Overdue Job</router-link>
            </div>
            <div v-if="!listMode">
              <button @click="changeListMode" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div v-if="listMode">
      <div class="row">
        <div class="col-md-2">
          <div class="form-group">
            <select class="form-control" v-model="form.vessel">
              <option value="" selected>Select Vessel</option>
              <option v-for="(row, key) in vessels" v-bind:value="{ id: row.id, name: row.name }">{{row.name}}</option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <DatePicker type="date" v-model="form.date" valueType="format" range></DatePicker>
          <!-- <date-picker v-model="form.date"  :disabled-date="notAfterToday"></date-picker> -->
        </div>
        <div class="col-md-1">
          <button :disabled=' form.vessel =="" ' class="btn btn-sm btn-info" @click="getAllOverDueJob">Search</button>
        </div>
        <div class="col-md-8"></div>
      </div>
      <div>
        <div v-if="type=='pending'">
          <div class="row">
            <div class="col-md-12">

              <span></span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div
                v-if="loading"
                class="col-md-12 text-center"
                style="margin-top:25%"
              >
                <PulseLoader
                  :loading="loading"
                ></PulseLoader>
              </div>
              <div v-if="!loading" class="table-responsive">

                <TableData :jobList="jobList" :viewDeferSchedule="viewDeferSchedule" :jobDoneFunction="jobDoneFunction"/>
                <div id="printArea" style="display: none">
                  <div style="text-align: center;line-height: 20px;">
                    <h2>{{ appName }}</h2>
                    <div class="font-weight-bold">Over Due Job List</div>
                    <p v-if="form.date"> From {{form?.date[0]}} To {{form?.date[1]}}</p>
                    <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                  </div>
                  <TableData :jobList="jobList" :isPrinting="true"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeferReschedule v-if="DeferReschedule" :jobInfo="selectedJob" :Schedule="scheduleId" @enableListMode="changeListMode"/>
    <JobDone v-if="JobDoneStatus" :jobInfo="selectedJob" :mode="permitWorkMode" :jobName="job_name" :componentName="componentName" :Schedule="scheduleId" @enableListMode="changeListMode"/>

  </div>
</template>

<script>

    import auth from '../../../auth'
    import TableData from "./TableData.vue";

    const today = new Date();
    today.setHours(0, 0, 0, 0);
    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import {printUtil} from "@/utils/print";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import exportMixin from "@/mixin/exportMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import fa from "../../../../public/admin/vendors/moment/src/locale/fa";
    import DeferReschedule from "@/components/Maintenance/DeferReschedule.vue";
    import JobDone from "@/components/Maintenance/JobDone.vue";

    export default {
        mixins: [printMixin, goBackButtonMixin, exportMixin],
        name: 'OverDueJob',
        components: {JobDone, DeferReschedule, TableData, DatePicker, PulseLoader},
        data() {
            return {
                componentName: '',
                job_name: '',
                loading: false,
                appName: process.env.VUE_APP_NAME,
                editMode: false,
                DeferReschedule: false,
                permitWorkMode: false,
                selectedJob: Object,
                listMode: true,
                vessels: null,
                scheduleId: '',
                JobDoneStatus: false,
                query: '',
                queryFiled: 'name',
                counteryes: [],
                form: new Form({
                    date: '',
                    vessel: {
                        id: auth.getVesselId(),
                        name: ''
                    }
                }),
                pagination: {
                    current_page: 1
                },
                i: 0,
                jobList: [],
                non_critical_overdue_job: 0,
                critical_overdue_job: 0,
                type: 'pending',
                vesselId: auth.getVesselId(),
                vesselName: auth.getVesselName(),
                user_type: auth.getUserType(),
                excelHeader: [],
                excelData: []
            }
        },
        mounted() {
            this.getVessel();
            this.getAllOverDueJob();
        },
        created() {
            //    console.log(this.$route.params.type)
        },
        methods: {
            prepareExportData() {
                let exportData = [];
                this.jobList.forEach((item, index) => {
                    item?.job_schedules.forEach((schedule) => {
                        exportData.push({
                            '#': index + 1,
                            'Name': item?.name + '(' + item?.code + ')',
                            'Department Name': item?.department?.name,
                            'Component Name': item?.component?.maincomponent?.name + '(' + item?.component?.maincomponent?.code + ') >> ' + item?.component?.name + '(' + item?.component?.code + ')',
                            'Job Type': item?.job_type?.name,
                            'Counter Type': item?.counter_type,
                            'Due Date/Due. Hr.(Current Run. Hr.)': schedule?.end_date + (item?.counter_type === 'Running Hours' ? '/' + (parseInt(item?.running_schedule_value) + parseInt(item?.running_hours_value)) + '(' + item?.current_running_hours_value + ')' : ''),
                            'Last Completed Date': item?.last_complete_date,
                            'Overdue Duration': item?.overdue_duration,
                            'Status': schedule?.status
                        });
                    });
                });
                return exportData;
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'over-due-job.csv');
            },
            excelExport() {
                let data = this.prepareExportData();
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                //data.shift();
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            notAfterToday(date) {
                return date > today
            },
            jobDoneFunction(job, i) {
                // if(job.job_schedules[i].permit_to_work==null && job.job_schedules[i].risk_assessment!=null){
                if (job.job_schedules[i].job_done_report != null) {
                    this.permitWorkMode = true;
                } else {
                    this.permitWorkMode = false;
                }
                if (this.user_type != job.rank_fixed && job.job_schedules[i].job_done_report == null) {
                    this.$snotify.warning('Job done report is not submitted');
                } else {
                    let component_name = job.sub_sub_component != null ? job.sub_sub_component?.name + '(' + job.sub_component?.name + ')' :
                        job.sub_component != null ? job.sub_component?.name + '(' + job.component?.name + ')' :
                            job.component != null ? job.component?.name + '(' + job.main_component?.name + ')' :
                                job.main_component != null ? job.main_component?.name : 'N/A';
                    let job_name = job?.name + '(' + job?.code + ')';
                    this.job_name = job_name;
                    this.listMode = false;
                    this.JobDoneStatus = true;
                    this.selectedJob = job;
                    this.scheduleId = i;
                    this.componentName = component_name;
                }
            },
            getAllOverDueJob() {
                this.loading = true;
                if (this.form.vessel) this.form.vessel_id = this.form.vessel.id;
                let params = {
                    vessel_id: this.form.vessel_id,
                    date: this.form.date
                };
                this.$http.get('/api/get-due-job-report', {params}).then(response => {
                  console.log(response)
                    this.jobList = response.data.success.data;
                    this.non_critical_overdue_job = response.data.success.non_critical_overdue_job;
                    this.critical_overdue_job = response.data.success.critical_overdue_job;
                    this.loading = false;
                    this.excelExport();
                }).catch(e => {
                    console.log(e);
                    this.loading = false;
                    let response = e.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else if (response?.status === 422) {
                        if (e.response.data?.errors) {
                            const errors = e.response.data?.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    } else {
                        // this.$snotify.error(response?.data?.error)
                    }
                })
            },
            // getAllOverDueJob(){
            //     this.$http.get("/api/get-due-job/" + this.vesselId+'/List').then(response => {
            //         this.jobList=response.data.success;
            //     }).catch(e => {
            //         console.log(e);
            //     });
            // },
            getAllDeferJob() {
                this.$http.get('/api/get-defer-job/' + this.vesselId + '/List').then(response => {
                    this.deferJob = response.data.success;
                }).catch(e => {
                    console.log(e)
                })
            },
            getAllDoneJob() {
                this.$http.get('/api/get-done-job/' + this.vesselId + '/List').then(response => {
                    this.jobListDone = response.data.success;
                }).catch(e => {
                    console.log(e)
                })
            },
            viewDeferSchedule(job, i) {
                this.listMode = false;
                this.DeferReschedule = true;
                this.selectedJob = job;
                this.scheduleId = i;
            },
            changeListMode() {
                this.DeferReschedule = false;
                this.listMode = true;
                this.JobDoneStatus = false;
                this.getAllOverDueJob();
            },
            getVessel() {
                this.$http.get('/api/vessel').then(response => {
                    this.vessels = response.data.success.vessel || []
                    const selectedVessel = this.vessels.find(vessel => vessel.id == this.vesselId);
                    if (selectedVessel) {
                      this.form.vessel = {
                        id: selectedVessel.id,
                        name: selectedVessel.name
                      };
                    }

                }).catch(e => {
                    console.log(e)
                })
            },

        }
    }
</script>

<style scoped>
  .mx-datepicker-range {
    width: 100%;
  }
</style>
