<!-- TableComponent.vue -->
<template>
  <table    :class="getTableClasses" style="width:100%">
    <thead>
    <tr :set="sl = 1">
      <th class="text-center"># </th>
      <th class="text-center">  Name </th>
      <th class="text-center"> Total Consume </th>
      <th class="text-center">  ROB  </th>

    </tr>
    </thead>
    <tbody>
    <tr  v-for="(item, index) in items"  :key="item.id">
      <td class="text-center">  {{ sl++ }}  </td>
      <td class="text-center">  {{ item?.name }}  </td>
      <td class="text-center">  {{ item?.total_consume_qty }}  </td>
      <td class="text-center">  {{ item?.rob }}  </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
import PermissionsMixin from "@/mixin/permissionsMixin";
import auth from "@/auth";

export default {
  props: {
    items: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
  },

  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {

}
</style>
