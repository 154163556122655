import Vue from 'vue'
import VueRouter from 'vue-router'
import auth from '@/auth'
import Login from '@/components/Login.vue'
import DashboardLayout from '@/layout/DashboardLayout.vue'
import Dashboard from '@/components/Dashboard.vue'
import Clone from '@/components/clone/Clone.vue'
import SelectVessel from '@/components/SelectVessel.vue'
import Role from '@/components/Settings/Role.vue'
import User from '@/components/Settings/User.vue'
import Department from '@/components/Settings/Department.vue'
import MainComponent from '@/components/Settings/MainComponent.vue'
import Component from '@/components/Settings/Component.vue'
import SubComponent from '@/components/Settings/SubComponent.vue'
import SubSubComponent from '@/components/Settings/SubSubComponent.vue'
import Vessel from '@/components/Settings/Vessel.vue'
import SupplierType from '@/components/Item/SupplierType.vue'
import Supplier from '@/components/Supplier/Supplier.vue'
import CategoryTree from '@/components/Item/Category/CategoryTree.vue'
import CategoryList from '@/components/Item/Category/CategoryList.vue'
import LocationTree from '@/components/Item/Location/LocationTree.vue'
import LocationList from '@/components/Item/Location/LocationList.vue'
import Item from '@/components/Item/Item.vue'
import ItemToComponent from '@/components/Item/ItemToComponent.vue'
import StockAdjustment from '@/components/Item/StockAdjustment.vue'
import JobType from '@/components/Settings/JobType.vue'
import Job from '@/components/Settings/job/Job.vue'
import Maintenance from '@/components/Maintenance/Maintenance.vue'
import JobScheduleList from '@/components/Settings/JobDetails/JobScheduleList.vue'
import RequisitionsItems from '@/components/Requisitions/items/RequisitionsItems.vue'
import AutoRequisition from '@/components/AutoRequisition/AutoRequisition.vue'
import AutoRequisitionProcess from '@/components/Requisitions/AutoRequisitionProcess.vue'
import QuatetionList from '@/components/quotation/QuatetionList.vue'
import SupplierQuatetionList from '@/components/supplier-quotation/SupplierQuatetionList.vue'
import VendorEvaluation from '@/components/vendor-evaluation/VendorEvaluation.vue'
import OpeningStock from '@/components/purchase/OpeningStock.vue'
import PurchaseList from '@/components/purchase/PurchaseList.vue'
import DeliveryList from '@/components/Requisitions/Delivery/DeliveryList.vue'
import BudgetHeadTree from '@/components/Budget/BudgetHeadTree.vue'
import BudgetHeadList from '@/components/Budget/BudgetHeadList.vue'
import Budget from '@/components/Budget/Budget.vue'
import BudgetCreate from '@/components/Budget/BudgetCreate.vue'
import BudgetReport from '@/components/Budget/report/BudgetReport.vue'
import Currency from '@/components/Settings/Currency.vue'
import Unit from '@/components/Settings/Unit.vue'
import VesselCertificateType from '@/components/Settings/VesselCertificateType.vue'
import VesselCertificates from '@/components/Settings/VesselCertificates.vue'
import CertificateView from '@/components/Settings/CertificateView.vue'
import Country from '@/components/Settings/Country.vue'
import Port from '@/components/Settings/Port.vue'
import StockLedger from '@/components/reports/StockLedger/StockLedger.vue'
import RequisitionReport from '@/components/reports/Requisition/RequisitionReport.vue'
import QuotationReport from '@/components/reports/Quotation/QuotationReport.vue'
import PurchaseReport from '@/components/reports/Purchase/PurchaseReport.vue'
import InvoiceReport from '@/components/reports/Invoice/InvoiceReport.vue'
import LocationWiseStock from '@/components/reports/LocationWiseStock/LocationWiseStock.vue'
import InvoiceView from '@/components/invoice/invoiceView/InvoiceView.vue'
import InvoiceList from '@/components/invoice/InvoiceList.vue'
import ServiceRequisition from '@/components/return/purchase/ServiceRequisition.vue'
import ServiceQuotation from '@/components/return/ServiceQuotation.vue'
import IncidentReport from '@/components/reports/Incident/IncidentReport.vue'
import OverDueJob from '@/components/reports/OverDueJob/OverDueJob.vue'
import DashboardJob from '@/components/DashboardJob.vue'
import RunningHourJobReport from '@/components/reports/runningHourJob/RunningHourJobReport.vue'
import MaintenanceReport from "@/components/reports/Maintenance/MaintenanceReport.vue"
import BreakdownHistoryReport from "@/components/reports/Breakdown/BreakdownHistoryReport.vue";
import SparePartsReport from "@/components/reports/SpareParts/SparePartsReport.vue";
import ReturnDamage from "@/components/reports/return-damage/ReturnDamage.vue";
import LessDeliveryList from "@/components/Requisitions/LessDeliveryList/LessDeliveryList.vue";
import ReturnReceiveItem from "@/components/Requisitions/ServiceReturn/ReturnReceiveItem.vue";
import ServiceReqReturnReport from "@/components/reports/ServiceReqReturn/ServiceReqReturnReport.vue";
import BudgetVarianceReport from "@/components/Budget/variance/BudgetVarianceReport.vue";
import VesselType from "@/components/Settings/VesselType.vue";
import ModuleList from "@/components/Modules/ModuleList.vue";
import Forbidden from "@/components/Forbidden.vue";
import {PERMISSIONS} from "@/utils/permission";
import DryDock from "@/components/DryDock/DryDock.vue";
import JobPlannerReport from "@/components/reports/JobPlanner/JobPlannerReport.vue";
import DeferredJobReport from "@/components/reports/DeferredJob/DeferredJobReport.vue";
import ChangePassword from "@/components/ChangePassword.vue";
import ExportImport from "@/components/Settings/ExportImport.vue";
import MonthlyOverdueJob from "@/components/reports/OverDueJob/MonthlyOverdueJob.vue";
import CriticalEquipmentItem from "@/components/Item/CriticalEquipmentItem.vue";
import TicketCategory from "@/components/Ticket/Category/TicketCategory.vue";
import Tickets from "@/components/Ticket/Ticket/Tickets.vue";
import NonConformity from "@/components/SafetyQuality/NonConformity.vue";
import NonConformityCorrectiveAction from "@/components/SafetyQuality/NonConformityCorrectiveAction.vue";
import TopTenConsumeItem from "@/components/Item/TopTenConsumeItem/TopTenConsumeItem.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: Forbidden
  },
  {
    path: '/',
    component: DashboardLayout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path: '/home',
        name: 'home',
        component: Dashboard
      },  {
        path: '/change-password',
        name: 'change-password',
        component: ChangePassword
      },{
        path: '/clone',
        name: 'clone',
        component: Clone,
        meta: { requiresPermission: PERMISSIONS.DATA_CLONE}
      }, {
        path: '/select-vessel',
        name: 'select-vessel',
        component: SelectVessel,
        meta: { requiresPermission: PERMISSIONS.VESSEL_SELECT}
      }, {
        path: '/role',
        name: 'role',
        component: Role,
        meta: { requiresPermission: PERMISSIONS.ROLE_PERMISSION}
      }, {
        path: '/users',
        name: 'users',
        component: User,
        meta: { requiresPermission: PERMISSIONS.USERS}
      }, {
        path: '/department',
        name: 'department',
        component: Department,
        meta: { requiresPermission: PERMISSIONS.DEPARTMENT}
      }, {
        path: '/main-component',
        name: 'main-component',
        component: MainComponent,
        meta: { requiresPermission: PERMISSIONS.COMPONENT}
      }, {
        path: '/component',
        name: 'component',
        component: Component,
        meta: { requiresPermission: PERMISSIONS.COMPONENT}
      }, {
        path: '/sub-component',
        name: 'sub_component',
        component: SubComponent,
        meta: { requiresPermission: PERMISSIONS.COMPONENT}
      }, {
        path: '/sub-sub-component',
        name: 'sub_sub_component',
        component: SubSubComponent,
        meta: { requiresPermission: PERMISSIONS.COMPONENT}
      }, {
        path: '/vesseltype',
        name: 'vesseltype',
        component: VesselType,
        meta: { requiresPermission: PERMISSIONS.VESSEL_TYPE}
      },
      {
        path: '/modules',
        name: 'Module',
        component: ModuleList,
        meta: { requiresPermission: PERMISSIONS.ROLE_PERMISSION}
      },
      {
        path: '/vessel',
        name: 'vessel',
        component: Vessel,
        meta: { requiresPermission: PERMISSIONS.VESSEL}
      }, {
        path: '/supplier-type',
        name: 'supplier-type',
        component: SupplierType,
        meta: { requiresPermission: PERMISSIONS.SUPPLIER_TYPE}
      }, {
        path: '/supplier-list',
        name: 'supplier-list',
        component: Supplier,
        meta: { requiresPermission: PERMISSIONS.SUPPLIER}
      }, {
        path: '/category-tree',
        name: 'category-tree',
        component: CategoryTree,
        meta: { requiresPermission: PERMISSIONS.CATEGORY}
      }, {
        path: '/category-list',
        name: 'category-list',
        component: CategoryList,
        meta: { requiresPermission: PERMISSIONS.CATEGORY}
      }, {
        path: '/location-tree',
        name: 'location-tree',
        component: LocationTree,
        meta: { requiresPermission: PERMISSIONS.LOCATION}
      }, {
        path: '/location-list',
        name: 'location-list',
        component: LocationList,
        meta: { requiresPermission: PERMISSIONS.LOCATION}
      }, {
        path: '/item',
        name: 'item',
        component: Item,
        meta: { requiresPermission: PERMISSIONS.ITEM}
      },
      {
        path: '/critical-equipment-item',
        name: 'critical-equipment-item',
        component: CriticalEquipmentItem,
        meta: { requiresPermission: PERMISSIONS.ITEM}
      },
      {
        path: '/top-ten-consume-item',
        name: 'top-ten-consume-item',
        component: TopTenConsumeItem,
        meta: { requiresPermission: PERMISSIONS.ITEM}
      },
      {
        path: '/item-to-component',
        name: 'item-to-component',
        component: ItemToComponent
      }, {
        path: '/stock-adjustment',
        name: 'stock-adjustment',
        component: StockAdjustment
      }, {
        path: '/job-type',
        name: 'job-type',
        component: JobType,
        meta: { requiresPermission: PERMISSIONS.JOB_TYPE}
      }, {
        path: '/job',
        name: 'job',
        component: Job,
        meta: { requiresPermission: PERMISSIONS.JOB}
      }, {
        path: '/maintenance/:jobId?',
        name: 'maintenance',
        component: Maintenance,
        meta: { requiresPermission:  PERMISSIONS.MAINTENANCE }
      }, {
        path: '/advanced-job-perform/:jobId?',
        name: 'Advanced-Perform',
        component: JobScheduleList
      },

      {
        path: '/requisition-items',
        name: 'requisition_items',
        component: RequisitionsItems,
        meta: { requiresPermission:  PERMISSIONS.REQUISITION }
      }, {
        path: '/auto-requisition',
        name: 'AutoRequisition',
        component: AutoRequisition,
        meta: { requiresPermission:  PERMISSIONS.AUTO_REQUISITION }
      }, {
        path: '/auto-requisition/process/:ids',
        name: 'AutoRequisitionProcess',
        component: AutoRequisitionProcess
      }, {
        path: '/quatetion-list',
        name: 'quatetion_list',
        component: QuatetionList,
        meta: { requiresPermission:  PERMISSIONS.QUOTATION }
      }, {
        path: '/supplier-quotations',
        name: 'SupplierQuotationList',
        component: SupplierQuatetionList,
        meta: { requiresPermission:  PERMISSIONS.SUPPLIER_QUOTATION }
      }, {
        path: '/vendor-evaluation',
        name: 'VendorEvaluation',
        component: VendorEvaluation,
        meta: { requiresPermission:  PERMISSIONS.VENDOR_EVALUATION }
      }, {
        path: '/opening-stock',
        name: 'OpeningStock',
        component: OpeningStock
      }, {
        path: '/purchase-list',
        name: 'PurchaseList',
        component: PurchaseList,
        meta: { requiresPermission:  PERMISSIONS.PURCHASE_ORDER }
      }, {
        path: '/delivery-list',
        name: 'delivery-list',
        component: DeliveryList,
        meta: { requiresPermission:  PERMISSIONS.RECEIVE_ITEMS }
      }, {
        path: '/return-receive-item',
        name: 'return-receive-item',
        component: ReturnReceiveItem,
        meta: { requiresPermission:  PERMISSIONS.SERVICE_RECEIVE_ITEMS }
      },
      {
        path: '/less-delivery-list',
        name: 'less-delivery-list',
        component: LessDeliveryList,
        meta: { requiresPermission:  PERMISSIONS.LESS_DELIVERY_LIST }

      },
      {
        path: '/budget-head',
        name: 'budget-head',
        component: BudgetHeadTree,
        meta: { requiresPermission:  PERMISSIONS.BUDGET_HEAD }
      }, {
        path: '/budgethead-add',
        name: 'budgethead-add',
        component: BudgetHeadList,
        meta: { requiresPermission:  PERMISSIONS.BUDGET_HEAD }
      }, {
        path: '/budget',
        name: 'budget',
        component: Budget,
        meta: { requiresPermission:  PERMISSIONS.BUDGET }
      }, {
        path: '/budget-create',
        name: 'budget-create',
        component: BudgetCreate,
        meta: { requiresPermission:  PERMISSIONS.BUDGET }
      }, {
        path: '/budget-report',
        name: 'budget-report',
        component: BudgetReport,
        meta: { requiresPermission:  PERMISSIONS.BUDGET_REPORT }
      },
      {
        path: '/budget-variance-report',
        name: 'budget-variance-report',
        component: BudgetVarianceReport,
        meta: { requiresPermission:  PERMISSIONS.BUDGET_REPORT }
      },
      {
        path: '/reports/maintenance',
        name: 'maintenance-report',
        component: MaintenanceReport,
        meta: { requiresPermission:  PERMISSIONS.MAINTENANCE_HISTORY_REPORT }
      },

      {
        path: '/reports/spare-parts',
        name: 'spare-parts-report',
        component: SparePartsReport,
        meta: { requiresPermission:  PERMISSIONS.SPARE_PARTS_CONSUMPTION_HISTORY_REPORT }
      },
      {
        path: '/reports/breakdown',
        name: 'breakdown-report',
        component: BreakdownHistoryReport,
        meta: { requiresPermission:  PERMISSIONS.BREAKDOWN_HISTORY_REPORT }
      },
      {
        path: '/reports/service-req-return',
        name: 'service-req-return-report',
        component: ServiceReqReturnReport,
        meta: { requiresPermission:  PERMISSIONS.SERVICE_REQ_RETURN_ITEM_REPORT }
      },
      {
        path: '/reports/deferred-job',
        name: 'deferred-job-report',
        component: DeferredJobReport,
        meta: { requiresPermission:  PERMISSIONS.DEFERRED_JOB_REPORT }
      },
      {
        path: '/currency',
        name: 'currency',
        component: Currency,
        meta: { requiresPermission:  PERMISSIONS.CURRENCY }
      }, {
        path: '/unit',
        name: 'unit',
        component: Unit,
        meta: { requiresPermission: PERMISSIONS.UNIT}
      }, {
        path: '/vessel-certificate-type',
        name: 'VesselCertificateType',
        component: VesselCertificateType,
        meta: { requiresPermission: PERMISSIONS.VESSEL_CERTIFICATE_TYPE}
      }, {
        path: '/vessel-certificates',
        component: VesselCertificates,
        meta: { requiresPermission: PERMISSIONS.VESSEL_CERTIFICATE}
      },
      {
        path: '/non-conformity',
        component: NonConformity,
        meta: { requiresPermission: PERMISSIONS.VESSEL_CERTIFICATE}
      },
      {
        path: '/non-conformity-corrective-action',
        component: NonConformityCorrectiveAction,
        meta: { requiresPermission: PERMISSIONS.VESSEL_CERTIFICATE}
      },
      {
        path: '/vessel-certificate/:id',
        name: 'CertificateView',
        component: CertificateView
      }, {
        path: '/country',
        name: 'country',
        component: Country,
        meta: { requiresPermission: PERMISSIONS.COUNTRY}
      }, {
        path: '/port',
        name: 'port',
        component: Port,
        meta: { requiresPermission: PERMISSIONS.PORT}
      }, {
        path: '/reports/stock-ledger',
        name: 'reports/stock-ledger',
        component: StockLedger,
        meta: { requiresPermission: PERMISSIONS.STOCK_LEDGER_REPORT}
      }, {
        path: '/reports/requisition',
        name: 'reports/requisition',
        component: RequisitionReport,
        meta: { requiresPermission: PERMISSIONS.REQUISITION_REPORT}
      }, {
        path: '/reports/quotation',
        name: 'reports/quotation',
        component: QuotationReport,
        meta: { requiresPermission: PERMISSIONS.QUOTATION_REPORT}
       },
      {
        path: '/reports/return-damage-item',
        name: 'reports/return-damage-item',
        component: ReturnDamage,
        meta: { requiresPermission: PERMISSIONS.RETURN_DAMAGE_RECEIVE_ITEM_REPORT}
      },
      {
        path: '/reports/purchase',
        name: 'reports/purchase',
        component: PurchaseReport,
        meta: { requiresPermission: PERMISSIONS.PURCHASE_REPORT}
      }, {
        path: '/reports/invoice',
        name: 'reports/invoice',
        component: InvoiceReport,
        meta: { requiresPermission: PERMISSIONS.INVOICE_REPORT}
      }, {
        path: '/reports/location-wise-stock',
        name: 'reports/location-wise-stock',
        component: LocationWiseStock,
        meta: { requiresPermission: PERMISSIONS.LOCATION_WISE_STOCK_REPORT}
      }, {
        path: '/invoice/:purchaseID/:invoiceID',
        name: 'InvoiceView',
        component: InvoiceView,
        meta: { requiresPermission:  PERMISSIONS.INVOICE }
      }, {
        path: '/invoice',
        name: 'InvoiceList',
        component: InvoiceList,
        meta: { requiresPermission:  PERMISSIONS.INVOICE }
      },  {
        path: '/defer-job/:type',
        name: 'defer-job',
        component: DashboardJob
      }, {
        path: '/pending-job/:type',
        name: 'pending-job',
        component: DashboardJob
      }, {
        path: '/due-job/:type',
        name: 'due-job',
        component: DashboardJob
      }, {
        path: '/done-job/:type',
        name: 'job-done',
        component: DashboardJob
      }, {
        path: '/purchase-return',
        name: 'ServiceRequisition',
        component: ServiceRequisition,
        meta: { requiresPermission:  PERMISSIONS.PURCHASE_RETURN }
      }, {
        path: '/service-quotation',
        name: 'ServiceQuotation',
        component: ServiceQuotation
      }, {
        path: '/job-planner',
        name: 'JobPlanner',
        component: JobPlannerReport,
        meta: { requiresPermission:  PERMISSIONS.JOB_PLANNER_REPORT }
      }, {
        path: '/incident-job-report',
        name: 'IncidentReport',
        component: IncidentReport,
        meta: { requiresPermission:  PERMISSIONS.INCIDENT_REPORT }
      }, {
        path: '/over-due-job',
        name: 'OverDueJob',
        component: OverDueJob,
        meta: { requiresPermission:  PERMISSIONS.OVERDUE_JOB_REPORT }

      }, {
        path: '/monthly-over-due-job',
        name: 'MonthlyOverdueJob',
        component: MonthlyOverdueJob,
        meta: { requiresPermission:  PERMISSIONS.OVERDUE_JOB_REPORT }

      },{
        path: '/running-hours-report',
        name: 'RunningHourJobReport',
        component: RunningHourJobReport,
        meta: { requiresPermission:  PERMISSIONS.RUNNING_HOUR_JOBS_REPORT }
      },
      {
        path: '/dry-dock',
        name: 'dry_dock',
        component: DryDock,
        meta: { requiresPermission:  PERMISSIONS.DRYDOCK }
      },
      {
        path: '/export-import',
        name: 'ExportImport',
        component: ExportImport
      },
      {
        path: '/ticket-category',
        name: 'ticket-category',
        component: TicketCategory,
        meta: { requiresPermission: PERMISSIONS.SUPPORT_TICKET}
      },
      {
        path: '/tickets',
        name: 'tickets',
        component: Tickets,
        meta: { requiresPermission: PERMISSIONS.SUPPORT_TICKET}
      }

    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

function checkPermission(permission, next) {
  if (auth.getPermissionData().includes(permission)) {
    next();
  } else {
    next('/forbidden');
  }
}


router.beforeEach((to, from, next) => {
  const isLoggedIn = auth.loggedIn();
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isLoggedIn) {
      return next({ path: '/login' })
    }
  }
  const requiredPermission = to.meta.requiresPermission;
  if (requiredPermission) {
    checkPermission(requiredPermission, next);
  } else {
    next();
  }
})

export default router
