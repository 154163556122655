<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead :set="seriaNumber =   1" >
    <tr>
      <th>#</th>
      <th>Department Name</th>
      <th>Component Name</th>
      <th>Job Name(Code)</th>
      <th>Counter Type</th>
      <th>Prev Maintenance Date</th>
      <th>Next Maintenance Due Date</th>
      <th>Defer Rescheduled Date</th>
      <th>Reason Deffer</th>
      <th v-if="!isPrinting">Document</th>
      <th>Status</th>
      <th>Office Approval</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(item, index) in listData" :key="listData.id">
       <td>{{seriaNumber++}}</td>
      <td>{{item?.department}} ({{item?.department_code}})</td>
      <td>{{item?.main_component}} - {{ item?.component }} {{ item?.sub_component != '' ? '-' + item?.sub_component : ''}} {{ item?.sub_sub_component != '' ? '-' + item?.sub_sub_component : ''}} </td>
      <td>{{item.job_name}}({{item?.job_code}})</td>
      <td>{{item.counter_type}}</td>
      <td>{{item?.prev_start_date | moment('DD-MM-YYYY')}}</td>
      <td>{{item?.prev_end_date | moment('DD-MM-YYYY')}}</td>
      <td>{{item?.current_end_date | moment('DD-MM-YYYY')}}</td>
      <td>{{item?.reason_deffer}}</td>
      <td v-if="!isPrinting">
        <a style="text-decoration: underline;color: #0a6aa1" target="_blank" v-if="item &&  item?.defer_image != null" :href="item &&  item?.defer_image">View</a>
      </td>

      <td width="10%">
        <span v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_DEFER_JOB_ADD_APPROVAL) && item.status =='Pending' && !isPrinting">
                      <button @click="ApprovedByChiefEngineer(item)" class="btn btn-sm btn-warning">Pending Approval</button>
         </span>
        <span v-else>{{item.status}}</span>

      </td>
      <td width="10%"><span v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_DEFER_JOB_OFFICE_APPROVAL) && item?.office_approval =='Pending'  && !isPrinting">
                      <button @click="ApproveByOffice(item)" class="btn btn-sm btn-warning">Pending Approval</button>
                   </span>
        <span v-else>{{item?.office_approval}}</span></td>
    </tr>

    </tbody>
  </table>
</template>

<script>
import permissionsMixin from "@/mixin/permissionsMixin";
import auth from "@/auth";

export default {
  mixins: [permissionsMixin],
  data(){
    return {
      permissions:auth.getPermissionData(),
    }
  },
  props: {
    listData : {
      required: true
    },
    ApprovedByChiefEngineer: {
       required:false,
    },
    ApproveByOffice: {
      required:false,
    },
    isPrinting: {
      type: Boolean,
      default: false
    }

  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
