<!-- TableComponent.vue -->
<template>
  <table  :class="getTableClasses" style="width:100%">
    <thead :set="seriaNumber =   1" >
    <tr>
      <th>#</th>
      <th>Purchase No</th>
      <th>Item Name</th>
      <th>Order Qty</th>
      <th>Qty</th>
      <th>Receive Date</th>
      <th>Expire Date</th>
      <th>Port</th>
      <th>Country</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(item, index) in listData" :key="listData.id">
       <td>{{seriaNumber++}}</td>
      <td>{{item?.purchase?.po_no}}</td>
      <td>{{item?.item?.name}}</td>
      <td>{{item.order_qty}}</td>
      <td>{{item.qty}}</td>
      <td>{{item?.date | moment('DD-MM-YYYY')}}</td>
      <td>{{item?.expire_date | moment('DD-MM-YYYY')}}</td>
      <td>{{item?.port?.name}}</td>
      <td>{{item?.country?.name}}</td>
    </tr>


    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    listData : {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    }

  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
