<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="">
          <div class="x_panel">
            <h2 class="float-left"> Job planner Report </h2>
            <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
            <button @click="print('printArea','Job planner Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
            <button @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
            <vue-excel-xlsx class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'job-planner'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <select class="form-control" v-model="form.vessel">
            <option value="" selected>Select Vessel</option>
            <option v-for="(row, key) in vessels" :value="row.id">{{row.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-3">
        <DatePicker type="date" v-model="form.date" valueType="format" range></DatePicker>
      </div>
      <div class="col-md-2" v-if="isPermissionExist(PERMISSIONS.MAINTENANCE_ASSIGNED_RANKED)">
        <div class="form-group">
          <select v-model="form.rank_fixed" class="form-control">
            <option value="">Select Rank</option>
            <option v-for="role in roles" :key="role.id" :value="role.id">{{role.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-md-1">
        <button class="btn btn-sm btn-info" @click="getAllPeandingJob">Search</button>
      </div>
      <div class="col-md-7"></div>

    </div>
    <div>
      <div v-if="type=='pending'">
        <div class="row">
          <div class="col-md-12">
            <h4 class="text-center mt-4"> Job Plan List </h4>
            <span></span>
          </div>
        </div>
        <div
          v-if="loading"
          class="col-md-12 text-center"
          style="margin-top:25%"
        >
          <PulseLoader
            :loading="loading"
          ></PulseLoader>
        </div>
        <div v-if="!loading" class="row">
          <div class="col-md-12">

            <div class="table-responsive">
              <TableData :jobList="jobList"/>
              <div id="printArea" style="display: none">
                <div style="text-align: center;line-height: 20px;">
                  <h2>{{ appName }}</h2>
                  <div class="font-weight-bold">Job Plan List</div>
                  <p v-if="form.date"> From {{form?.date[0]}} To {{form?.date[1]}}</p>
                  <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                </div>
                <TableData :jobList="jobList" :isPrinting="true"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import auth from '../../../auth'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import exportMixin from "@/mixin/exportMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import permissionsMixin from "@/mixin/permissionsMixin";

    export default {
        mixins: [printMixin, goBackButtonMixin, exportMixin,permissionsMixin],
        name: 'JobPlanner',
        components: {TableData, DatePicker, PulseLoader},
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                editMode: false,
                query: '',
                queryFiled: 'name',
                permissions: auth.getPermissionData(),
                vessels: null,
                counteryes: [],
                roles: [],
                form: new Form({
                    date: '',
                    vessel: auth.getVesselId(),
                    rank_fixed:'',
                }),
                pagination: {
                    current_page: 1
                },
                i: 0,
                jobList: [],
                type: 'pending',
                vesselId: auth.getVesselId(),
                user_type: auth.getUserType(),
                excelHeader: [],
                excelData: []
            }
        },
        mounted() {
            this.getVessel();
            this.getAllPeandingJob();
            this.allRole();
        },
        methods: {
          allRole() {
            this.$http.get("/api/filter/get-active-role").then(response => {
              this.roles = response.data.success;
            }).catch(e => {
              console.log(e);
            });
          },
            prepareExportData() {
                let exportData = [];
                const jobListArray = Object.values(this.jobList);
                jobListArray.forEach((item, index) => {
                    item?.job_schedules.forEach((schedule) => {
                        exportData.push({
                            '#': index + 1,
                            'Job Name': item?.name + ' (' + item?.code + ')',
                            'Department': item?.department?.name + ' (' + item?.department?.code + ')',
                            'Main Component Name': item?.main_component?.name + ' (' + item?.main_component?.code + ')',
                            'Last Maintenance Date': schedule?.start_date,
                            'Due Date': schedule?.end_date,
                            'Counter Type': item?.counter_type,
                            'Status': schedule?.status,
                            'Assigned Ranked': item?.rank_fixed_role?.name,
                        });
                    });
                });
                return exportData;
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'job-planner.csv');
            },
            excelExport() {
                let data = this.prepareExportData();
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            getAllPeandingJob() {
                this.loading = true;
                let params = {
                    date: this.form.date,
                    vessel_id: this.form.vessel,
                    rank_fixed: this.form.rank_fixed
                };
                this.$http.get('/api/get-pending-job-report', {params}).then(response => {
                  console.log(response)
                    this.jobList = response.data.success;
                    this.loading = false;
                    this.excelExport();
                }).catch(e => {
                    console.log(e);
                    this.loading = false;
                    let response = e.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {

                    }
                })
            },
            getAllOverDueJob() {
                this.$http.get('/api/get-due-job/' + this.vesselId + '/List').then(response => {
                    this.jobList = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            getAllDeferJob() {
                this.$http.get('/api/get-defer-job/' + this.vesselId + '/List').then(response => {

                    this.deferJob = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            getAllDoneJob() {
                this.$http.get('/api/get-done-job/' + this.vesselId + '/List').then(response => {
                    this.jobListDone = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            getVessel() {
                this.$http.get('/api/vessel').then(response => {
                    this.vessels = response.data.success.vessel
                }).catch(e => {
                    console.log(e)
                })
            },

        }
    }
</script>

<style scoped>
  .mx-datepicker-range {
    width: 100%;
  }
</style>
