<!-- TableComponent.vue -->
<template>
  <table   :id="isPrinting ? '' : 'datatables'" :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th v-if="!isPrinting">#</th>
      <th>Item</th>
      <th>Category</th>
      <th>Parent Category</th>
      <th>Model</th>
      <th>Impa Code</th>
      <th>Status</th>
      <th>Create Date</th>
      <th v-if="!isPrinting">Action</th>
    </tr>
    </thead>
    <tbody>

    <tr v-for="(requisition , index) in requisitionData" :key="index"   :style="criticalEquipmentStyle(requisition.alert_requisition)"    >
      <td v-if="!isPrinting">
        <input type="checkbox" class="item" :value="`${requisition.item_id}`" :data-name="`${requisition.name}`" :data-parent="`${requisition.parent_category}`">
      </td>
      <td>{{requisition.name}}</td>
      <td>{{requisition.category}}</td>
      <td>{{requisition.parent_category}}</td>
      <td>{{requisition.model}}</td>
      <td>{{requisition.inpa_code}}</td>
      <td>{{requisition.status}}</td>
      <td>{{ requisition.date | moment('MMM Do, YY') }}</td>
      <td v-if="!isPrinting">
        <button type="button" @click="destroy(requisition.id)" title="Delete" class="btn btn-danger btn-sm"><i class="fa fa-trash"></i></button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    requisitionData: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

    destroy: {
      type: Function,
      required: false
    },




  },

  methods:{
    criticalEquipmentStyle(req){
       if (req){
          return {
            color: 'red'
          }
       }
    }
  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style  >

</style>
