<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_content">
          <div class="x_title">
            <h2>Vessel Certificate</h2>
            <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
            <button @click="print('printArea','Vessel Certificate' )" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
            <div class="clearfix"></div>
          </div>
          <div class="row justify-content-center">
            <div class="col-sm-10 border">
              <section class="content invoice p-2" id=" ">
                <div class="text-center invoice-header">
                  <h3>Vessel Certificate</h3>
                  <p>
                    <strong>Certificate Type:{{row?.vessel_certificate_type?.name}}</strong>
                  </p>
                </div>

                <div class="table">
                  <table class="table table-striped table-bordered">
                    <tbody>
                    <tr>
                      <th>Certificate Type</th>
                      <td colspan="2">
                        {{row?.vessel_certificate_type?.name}}
                      </td>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <td colspan="2">{{row.name}}</td>
                    </tr>
                    <tr>
                      <th>Date of Issue</th>
                      <td colspan="2">{{row.date_of_issue}}</td>
                    </tr>
                    <tr>
                      <th>Date of Expiry</th>
                      <td colspan="2">{{row.date_of_expire}}</td>
                    </tr>
                    <tr>
                      <th>Issued by(Authority)</th>
                      <td colspan="2">{{row.issued_by}}</td>
                    </tr>
                    <tr>
                      <th>Place</th>
                      <td colspan="2">{{row.place}}</td>
                    </tr>
                    <tr>
                      <th>Duration</th>
                      <td colspan="2">{{row.type}}</td>
                    </tr>
                    <tr>
                      <th>Certificate no</th>
                      <td colspan="2">{{row.certificate_no}}</td>
                    </tr>
                    <tr>
                      <th>Alert days</th>
                      <td colspan="2">{{row.alert_days}}</td>
                    </tr>
                    <tr>
                      <th>Window Period</th>
                      <td colspan="2">{{ row.window_period_start | moment("DD/MM/YYYY") }} - {{ row.window_period_end | moment("DD/MM/YYYY") }}</td>
                    </tr>
                    <tr>
                      <th>Doc</th>
                      <td colspan="2"><a v-if="row.doc != 0" :href="`${row.doc}`" target="_blank">View Doc</a></td>
                    </tr>
                    <tr>
                      <th>Extended upto</th>
                      <td colspan="2">{{row.extended_upto}}</td>
                    </tr>
                    <tr>
                      <th>Place of extension</th>
                      <td colspan="2">{{row.place_of_extension}}</td>
                    </tr>
                    <tr>
                      <th>Renew date</th>
                      <td colspan="2">{{row.renew_date}}</td>
                    </tr>
                    <tr>
                      <th>Renew place</th>
                      <td colspan="2">{{row.renew_place}}</td>
                    </tr>
                    <tr>
                      <th>Remark</th>
                      <td colspan="2">{{row.remark}}</td>
                    </tr>
                    <tr>
                      <th>Annual Endorsement Date</th>
                      <th>Annual Endorsement Place</th>
                      <th>Next Annual Endorsement Due Date</th>
                    </tr>
                    <tr v-if="row.annual_endorsements"   v-for="(endorsement, index) in row.annual_endorsements" :key="index">
                      <td>{{ endorsement?.annual_endorsement_date }}</td>
                      <td>{{ endorsement?.annual_endorsement_place }}</td>
                      <td>{{ endorsement?.next_annual_endorsement_date }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>


              </section>
              <div id="printArea" style="display: none">
                <div style="text-align: center;line-height: 20px;">
                  <h2>{{ appName }}</h2>
                  <h3>Vessel Certificate</h3>
                  <p style="text-align: center">
                  <strong>Certificate Type:{{ row?.vessel_certificate_type?.name }}</strong></p>
                  <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                </div>
                <div class="table">
                  <table class="table  print-table" style="width: 100%">
                    <tbody>
                    <tr>
                      <th>Certificate Type</th>
                      <td colspan="2">
                         {{ row?.vessel_certificate_type?.name }}
                      </td>
                    </tr>
                    <tr>
                      <th>Name</th>
                      <td colspan="2">{{row.name}}</td>
                    </tr>
                    <tr>
                      <th>Date of Issue</th>
                      <td colspan="2">{{row.date_of_issue}}</td>
                    </tr>
                    <tr>
                      <th>Date of Expiry</th>
                      <td colspan="2">{{row.date_of_expire}}</td>
                    </tr>
                    <tr>
                      <th>Issued by(Authority)</th>
                      <td colspan="2">{{row.issued_by}}</td>
                    </tr>
                    <tr>
                      <th>Place</th>
                      <td colspan="2">{{row.place}}</td>
                    </tr>
                    <tr>
                      <th>Duration</th>
                      <td colspan="2">{{row.type}}</td>
                    </tr>
                    <tr>
                      <th>Certificate no</th>
                      <td colspan="2">{{row.certificate_no}}</td>
                    </tr>
                    <tr>
                      <th>Alert days</th>
                      <td colspan="2">{{row.alert_days}}</td>
                    </tr>
                    <tr>
                      <th>Window Period</th>
                      <td colspan="2">{{ row.window_period_start | moment("DD/MM/YYYY") }} - {{ row.window_period_end | moment("DD/MM/YYYY") }}</td>
                    </tr>
                    <tr>
                      <th>Doc</th>
                      <td colspan="2"><a v-if="row.doc != 0" :href="`${row.doc}`" target="_blank">View Doc</a></td>
                    </tr>
                    <tr>
                      <th>Extended upto</th>
                      <td colspan="2">{{row.extended_upto}}</td>
                    </tr>
                    <tr>
                      <th>Place of extension</th>
                      <td colspan="2">{{row.place_of_extension}}</td>
                    </tr>
                    <tr>
                      <th>Renew date</th>
                      <td colspan="2">{{row.renew_date}}</td>
                    </tr>
                    <tr>
                      <th>Renew place</th>
                      <td colspan="2">{{row.renew_place}}</td>
                    </tr>
                    <tr>
                      <th>Remark</th>
                      <td colspan="2">{{row.remark}}</td>
                    </tr>
                    <tr>
                      <th>Annual Endorsement Date</th>
                      <th>Annual Endorsement Place</th>
                      <th>Next Annual Endorsement Due Date</th>
                    </tr>
                    <tr v-if="row.annual_endorsements"   v-for="(endorsement, index) in row.annual_endorsements" :key="index">
                      <td>{{ endorsement?.annual_endorsement_date }}</td>
                      <td>{{ endorsement?.annual_endorsement_place }}</td>
                      <td>{{ endorsement?.next_annual_endorsement_date }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
    import datetime from 'vuejs-datetimepicker';
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";

    export default {
        mixins: [printMixin, goBackButtonMixin],
        name: "Invoice",
        components: {datetime},
        data() {
            return {
                row: null,
                appName: process.env.VUE_APP_NAME,
            };
        },
        mounted() {
            this.getData();
            let that = this;

        },
        methods: {

            getData() {
                this.$http.get("/api/vessel-certificate/" + this.$route.params.id).then(response => {
                    this.row = response.data;

                  console.log(response)
                }).catch(e => {
                    console.log(e);
                });
            }
        }
    }
</script>

<style scoped>

</style>
