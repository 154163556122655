var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{class:_vm.getTableClasses,staticStyle:{"width":"100%"}},[_c('tbody',[_vm._m(0),_c('tr',[_vm._m(1),_c('th',[_vm._v(" "+_vm._s(_vm.data?.non_conformity?.ref_no))])]),_c('tr',[_vm._m(2),_c('th',[_vm._v(_vm._s(_vm.data?.non_conformity?.company_name))])]),_c('tr',[_vm._m(3),_c('th',[_vm._v(_vm._s(_vm.data?.date))])]),_c('tr',[_vm._m(4),_c('th',[_vm._v(_vm._s(_vm.data?.non_conformity?.non_conformance_description))])]),_c('tr',[_vm._m(5),_c('th',[_vm._v(_vm._s(_vm.data?.root_cause_analysis))])]),_c('tr',[_vm._m(6),_c('th',[_vm._v(_vm._s(_vm.data?.corrective_action))])]),_c('tr',[_vm._m(7),_c('th',[_vm._v(_vm._s(_vm.data?.preventive_actions))])]),_vm._m(8),_c('tr',[_vm._m(9),_c('th',[_vm._v(" "+_vm._s(_vm.data?.review_by))])]),_c('tr',[_vm._m(10),_c('th',[_vm._v(" "+_vm._s(_vm.data?.reviewer_comments))])]),_c('tr',[_vm._m(11),_c('th',[_vm._v(" "+_vm._s(_vm.data?.reviewer_date))])]),_c('tr',[_vm._m(12),_c('th',[_vm._v(" "+_vm._s(_vm.data?.dpa_comments))])]),_c('tr',[_vm._m(13),_c('th',[_vm._v(" "+_vm._s(_vm.data?.dpa_date))])]),_vm._m(14),_c('tr',[_vm._m(15),_c('th',[_vm._v(" "+_vm._s(_vm.data?.vessel_acknowledgement))])]),_c('tr',[_vm._m(16),_c('th',[_vm._v(" "+_vm._s(_vm.data?.date_close_vessel))])]),_c('tr',[_vm._m(17),_c('th',[_vm._v(" "+_vm._s(_vm.data?.master_name))])]),_c('tr',[_vm._m(18),_c('th',[_vm._v(" "+_vm._s(_vm.data?.date_closed_office))])]),_c('tr',[_vm._m(19),_c('th',[_vm._v(" "+_vm._s(_vm.data?.dpa_name))])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{attrs:{"colspan":"2"}},[_c('strong',[_vm._v("1st Section (General Info) ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Reference No of NC: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Name of Company: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Date: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("2nd Section (Description) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("3rd Section (Root Cause Analysis) ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("4rd Section (Corrective Action):")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("5rd Section (Preventive Action ): ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{attrs:{"colspan":"2"}},[_c('strong',[_vm._v("6th Section (Review and Follow-up) : ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("To be reviewed/followed-up by: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Comments by Reviewer: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Date: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Comments by DPA: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Date: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('th',{attrs:{"colspan":"2"}},[_c('strong',[_vm._v("6th Section (Review and Follow-up) : ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Vessel’s acknowledgement and Comments: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Date Closed Vessel: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Name of Master: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Date Closed Office: ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('th',{attrs:{"width":"25%"}},[_c('strong',[_vm._v("Name of DPA: ")])])
}]

export { render, staticRenderFns }