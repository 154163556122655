<!-- TableComponent.vue -->
<template>
  <table   :id="isPrinting ? '' : 'datatables'" :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th>Type</th>
      <th>Status</th>
      <th>Purchase No</th>
      <th>Title</th>
      <th>Quotation No</th>
      <th>Created By</th>
      <th>Create Date</th>
      <th>Super. Approval</th>
<!--      <th>PM Approval</th>-->
      <th>TM Approval</th>
      <th>Amount</th>
      <th>Actual Amount</th>
      <th v-if="!isPrinting">Budget Info</th>
      <th v-if="!isPrinting && (isPermissionExist(PERMISSIONS.PURCHASE_ORDER_VIEW) ||
       isPermissionExist(PERMISSIONS.PURCHASE_ORDER_EDIT) ||  isPermissionExist(PERMISSIONS.INVOICE) || isPermissionExist(PERMISSIONS.VENDOR_EVALUATION)) ">
         Action
      </th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(row, index) in listData" :key="row.id">
      <td>{{row.type}}</td>
      <td>{{row.status}}</td>
      <td>{{row.po_no}}</td>
      <td>{{row.title}}</td>
      <td> <span v-if="row.quotation">  {{row.quotation.quotation_no}} </span>  </td>
      <td>{{row.user.username}}</td>
      <td>{{row.date}}</td>
      <td> <span v-if="row.superintendent_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.SUPERINTENDING_APPROVAL)">
                              <button type="button" title="Approve" class="btn btn-warning btn-sm" @click="approveRequest('Purchase', row.id, 'Approved', 'superintendent_approval')"> {{row.superintendent_approval}}</button>
                             </span>
        <span v-else> {{row.superintendent_approval}} </span>
      </td>
<!--      <td> <span v-if="row.purchase_manager_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.PM_APPROVAL)">-->
<!--                              <button type="button" title="Approve" class="btn btn-warning btn-sm" @click="approveRequest('Purchase', row.id, 'Approved', 'purchase_manager_approval')"> {{row.purchase_manager_approval}}</button>-->
<!--                              </span>-->
<!--        <span v-else>  {{row.purchase_manager_approval}}  </span>-->
<!--      </td>-->
      <td>  <span v-if="row.technical_manager_approval == 'Pending' && !isPrinting && isPermissionExist(PERMISSIONS.TM_APPROVAL)">
                                <button type="button" title="Approve" class="btn btn-warning btn-sm"  @click="approveRequest('Purchase', row.id, 'Approved', 'technical_manager_approval')"> {{row.technical_manager_approval}}</button>
                             </span>
        <span v-else>  {{row.technical_manager_approval}}   </span>
      </td>
      <td>${{row.grand_amount}}</td>
      <td>
        <span v-if="row?.receive_item"> ${{row?.actual_amount}}</span>
        <span v-else> No Receive Item</span>
      </td>
      <td v-if="!isPrinting">
        Budget: {{row?.budget_info?.totalBudget}} &nbsp; &nbsp;
        Budget Left:  {{row?.budget_info?.totalBudgetLeft }}
<!--        <button type="button" @click="showBudget(row.requisition_id, index)" title="View" class="btn btn-info btn-sm">Budget Info</button>-->
<!--        <div :class="`budget-${index}`"></div>-->
      </td>
      <td v-if="!isPrinting && (isPermissionExist(PERMISSIONS.PURCHASE_ORDER_VIEW) || isPermissionExist(PERMISSIONS.PURCHASE_ORDER_EDIT) ||  isPermissionExist(PERMISSIONS.INVOICE) || isPermissionExist(PERMISSIONS.VENDOR_EVALUATION)) ">
        <button type="button" v-if="isPermissionExist(PERMISSIONS.PURCHASE_ORDER_VIEW)" @click="show(row.id)" title="View" class="btn btn-primary btn-sm"><i class="fa fa-eye"></i> View</button>
        <button type="button" v-if="isPermissionExist(PERMISSIONS.PURCHASE_ORDER_EDIT)" @click="edit(row.id)" title="View" class="btn btn-info btn-sm"><i class="fa fa-pencil"></i> Edit</button>
       <button v-if="row.superintendent_approval === 'Approved' &&  row.technical_manager_approval === 'Approved' && row?.receive_item === true && isPermissionExist(PERMISSIONS.INVOICE)"
               type="button" @click="updateInvoice(row)" title="Create/Update Invoice" class="btn btn-success btn-sm px-1 py-0"><i class="fa fa-money"></i>
                 <span v-if="row.invoice_exist === true"> Update Invoice</span>
                 <span v-else> Invoice</span>
           </button>

        <button v-if="row?.receive_item === true && isPermissionExist(PERMISSIONS.VENDOR_EVALUATION)" type="button" @click="showEvaluationFormModal(row)" title="Vendor Evaluation" class="btn btn-secondary btn-sm"><i class="fa fa-reply"></i>
          Feedback
        </button>
      </td>
    </tr>

    </tbody>
  </table>
</template>

<script>
import PermissionsMixin from "@/mixin/permissionsMixin";
import auth from "@/auth";

export default {
  mixins:[PermissionsMixin],
  data () {
    return {
      permissions: auth.getPermissionData(),
    }
  },
  props: {
    listData : {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    approveRequest: {
      type: Function,
      required: false
    },
    showBudget: {
      type: Function,
      required: false
    },
    show: {
      type: Function,
      required: false
    },
    edit: {
      type: Function,
      required: false
    },
    updateInvoice: {
      type: Function,
      required: false
    },
    showEvaluationFormModal: {
      type: Function,
      required: false
    }


  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-striped': !this.isPrinting,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style>
.print-table {
  /* Define styles for printing if needed */
}
</style>
