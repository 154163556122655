<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <h2 class="float-left">Maintenance History List</h2>
          <button v-if="!listMode" type="button" class="btn btn-info btn-sm float-right" @click="changeListMode">View Job List<i class="fa fa-eye"></i></button>
          <button v-if="listMode" @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
          <button v-if="listMode" @click="print('printArea','Maintenance History Report')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
          <button v-if="listMode" @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
          <vue-excel-xlsx v-if="listMode" class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'maintenance'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
        </div>

        <div class="">
          <div class="x_content ">
            <div class="row" v-if="listMode && jobList">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-2">
                        <div class="form-group">
                          <label>Vessel</label>
                          <select class="form-control" v-model="scheduleInfo.vesselId">
                            <option value="" selected>Select Vessel</option>
                            <option v-for="(row, key) in vessels" :value="row.id">{{row.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2 col-6">
                        <div class="form-group">
                          <label for="">Date From</label>
                          <DatePicker type="date" v-model="scheduleInfo.date_from" valueType="format"></DatePicker>
                        </div>
                      </div>
                      <div class="col-md-2 col-6">
                        <div class="form-group">
                          <label for="">Date To</label>
                          <DatePicker type="date" v-model="scheduleInfo.date_to" valueType="format"></DatePicker>
                        </div>
                      </div>
                      <div class="col-md-2 col-6">
                        <div class="form-group">
                          <label for="">Counter Type</label>
                          <select class="form-control" v-model="scheduleInfo.listType">
                            <option value>Select Job Interval</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Two-Yearly">2 yearly</option>
                            <option value="Thirty-Monthly">2.5 yearly</option>
                            <option value="Three-Yearly">3 yearly</option>
                            <option value="Four-Yearly">4 yearly</option>
                            <option value="Five-Yearly">5 yearly</option>
                            <option value="Ten-Yearly">10 yearly</option>
                            <option value="Fifteen-Yearly">15 yearly</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Three-Monthly">3 Monthly</option>
                            <option value="Six-Monthly">6 Monthly</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Running Hours">Running Hour</option>
                            <!--                            <option value="Unscheduled">Unscheduled</option>-->
                            <!--                            <option value="BreakDown">BreakDown</option>-->
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2 col-6">
                        <div class="form-group">
                          <label for="">Department</label>
                          <select v-model="scheduleInfo.department_id" name="department_id" class="form-control" @change="getMainComponent(scheduleInfo.department_id)">
                            <option value selected>Select department</option>
                            <option v-for="sdepartment in departments" :key="sdepartment.id" :value="sdepartment.id">{{ sdepartment.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2 col-6">
                        <div class="form-group">
                          <label for="">Main Component</label>
                          <select v-model="scheduleInfo.main_component_id" name="main_component_id" class="form-control" @change="getComponentList(scheduleInfo.main_component_id)">
                            <option value selected>Select main component</option>
                            <option v-for="mainComponent in mainComponents" :key="mainComponent.id" :value="mainComponent.id">{{ mainComponent.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2 col-6">
                        <div class="form-group">
                          <label for="">Component</label>
                          <select v-model="scheduleInfo.component_id" name="component_id" class="form-control" @change="getSubComponent(scheduleInfo.component_id)">
                            <option value selected>Select component</option>
                            <option v-for="component in components" :key="component.id" :value="component.id">{{ component.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2 col-6">
                        <div class="form-group">
                          <label for="">Sub Component</label>
                          <select v-model="scheduleInfo.sub_component_id" name="component_id" class="form-control" @change="getSubSubComponent(scheduleInfo.sub_component_id)">
                            <option value="0" selected>Select Sub component</option>
                            <option v-for="subcomponent in subcomponents" :key="subcomponent.id" :value="subcomponent.id">{{ subcomponent.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2 col-6">
                        <div class="form-group">
                          <label for="">Sub Sub Component</label>
                          <select v-model="scheduleInfo.sub_sub_component_id" name="component_id" class="form-control">
                            <option value="0" selected>Select Sub Sub component</option>
                            <option v-for="subsubcomponent in subsubcomponents" :key="subsubcomponent.id" :value="subsubcomponent.id">{{ subsubcomponent.name }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2 col-6">
                        <div class="form-group">
                          <label for="">Maintenance Type </label>
                          <select class="form-control" v-model="scheduleInfo.maintenance_type">
                            <option value>Select </option>
                            <option value="planned">Planned</option>
                            <option value="breakdown">Breakdown</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-2 col-6 ">
                        <div class="form-group">
                          <label for="">Search </label>
                          <input type="text" v-model="scheduleInfo.searchInput" name="searchInput" class="form-control">
                        </div>
                      </div>
                      <div class="col-md-2 mt-2">
                        <div class="form-group">
                          <label for=""></label>
                          <button class=" mt-3 btn-info btn btn-sm" @click="getAllJobList()">Search</button>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="loading"
                      class="col-md-12 text-center"
                      style="margin-top:25%"
                    >
                      <PulseLoader
                        :loading="loading"
                      ></PulseLoader>
                    </div>
                    <div v-if="!loading" class="card-box table-responsive">
                      <TableData :jobList="jobList?.data" :jobDoneFunction="jobDoneFunction"/>
                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <div class="font-weight-bold">Maintenance History List</div>
                          <p> <span v-if="scheduleInfo.date_from">From: {{scheduleInfo.date_from}}</span>  <span v-if="scheduleInfo.date_to">To: {{scheduleInfo.date_to}}</span></p>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        </div>
                        <TableData :jobList="jobListWithOutPagination" :jobDoneFunction="jobDoneFunction" :isPrinting="true"/>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <JobDone v-if="JobDoneStatus" :jobInfo="selectedJob" :mode="permitWorkMode" :componentName="componentName" :jobName="job_name" :Schedule="scheduleData" @enableListMode="changeListMode"/>

            <!--            pagination -->
            <div v-if="listMode && jobList">
              <Pagination
                :pagination="pagination"
                :offset="pagination.offset"
                @paginate="getAllJobList()"
              ></Pagination>
            </div>

          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>

    import DatePicker from 'vue2-datepicker'
    import 'vue2-datepicker/index.css'
    import {VueEditor} from 'vue2-editor'
    import auth from '../../../auth'
    import Pagination from "@/components/Pagination.vue";
    import JobDone from '../JobDone.vue'
    import TableData from './TableData.vue'
    import {printUtil} from "@/utils/print";
    import printMixin from "@/mixin/printMixin";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import exportMixin from "@/mixin/exportMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";

    export default {
        mixins: [printMixin, goBackButtonMixin, exportMixin],
        name: 'maintenance-report',
        components: {
            Pagination,
            VueEditor,
            JobDone,
            DatePicker,
            TableData,
            PulseLoader
        },
        data() {
            return {
                loading: false,
                reportList: false,
                color: '#1ABB9C',
                size: '10px',
                jobId: 25,
                selectedComponent: '',
                vessels: null,
                selectedJob: Object,
                treeDisplayData: [],
                appName: process.env.VUE_APP_NAME,
                scheduleData: '',
                job_name: '',
                componentName: '',
                jobType: '',
                query: '',
                content: '',
                queryFiled: 'name',
                jobList: [],
                jobListWithOutPagination: [],
                jobRemarks: '',
                listMode: true,
                IRR: false,
                P2W: false,
                JobDoneStatus: false,
                DeferReschedule: false,
                editMode: false,
                permitWorkMode: false,
                tableEnableMode: false,
                chiefPermisionMode: false,
                departments: [],
                mainComponents: [],
                components: [],
                subcomponents: [],
                subsubcomponents: [],
                vessel_id: auth.getVesselId(),
                user_type: auth.getUserType(),
                form: new Form({
                    id: '',
                    name: '',
                    email: '',
                    status: 'Activate'
                }),
                pagination: {
                    current_page: 1,
                    offset: 5
                },
                filter: new Form({
                    dateRange: '',
                    jobType: '',
                    jobId: '',
                    vessel_id: auth.getVesselId(),
                }),
                scheduleInfo: new Form({
                    vesselId: auth.getVesselId(),
                    listType: '',
                    date_from: '',
                    date_to: '',
                    department_id: '',
                    main_component_id: '',
                    component_id: '',
                    sub_component_id: '0',
                    sub_sub_component_id: '0',
                    filterDate: '',
                    searchInput: '',
                    maintenance_type:'',
                }),
                excelHeader: [],
                excelData: []
            }
        },
        mounted() {
            this.getVessel();
            this.getAllJobList();
            this.departmentList();
        },
        methods: {
            getVessel() {
                this.$http.get('/api/vessel').then(response => {
                    this.vessels = response.data.success.vessel
                }).catch(e => {
                    console.log(e)
                })
            },
            prepareExportData() {
                return this.jobListWithOutPagination.map((item, key) => ({
                    '#': key + 1,
                    'Job Name': item?.job?.name + '(' + item?.job?.code + ')',
                    'Component Name': item?.job?.component?.name,
                    'Due Date': item?.start_date,
                    'Completion Date': item?.end_date,
                    'Counter Type': item?.counter_type,
                    'Assign To': item?.job?.rank_fixed_role?.name,
                    'Status': item?.status,
                }));
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'maintenance.csv');
            },
            excelExport() {
                let data = this.prepareExportData();
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                data.shift();
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            getAllJobList() {
                this.loading = true;
                this.jobList = [];
                this.jobListWithOutPagination = [];
                let params = {
                    date_from: this.scheduleInfo.date_from,
                    date_to: this.scheduleInfo.date_to,
                    listType: this.scheduleInfo.listType,
                    department_id: this.scheduleInfo.department_id,
                    main_component_id: this.scheduleInfo.main_component_id,
                    component_id: this.scheduleInfo.component_id,
                    sub_component_id: this.scheduleInfo.sub_component_id,
                    sub_sub_component_id: this.scheduleInfo.sub_sub_component_id,
                    vesselId: this.scheduleInfo.vesselId,
                    searchInput: this.scheduleInfo.searchInput,
                    maintenance_type: this.scheduleInfo.maintenance_type,
                };
                this.$http.get('/api/filter/all-job-maintenance-history-list?page=' + this.pagination.current_page, {
                    params
                }).then(response => {
                    this.loading = false;
                    this.jobList = response.data?.jobData;
                    this.jobListWithOutPagination = response.data?.jobDataWithOutPagination;
                    this.excelExport();
                    let lastPage = response.data?.jobData?.last_page;
                    if (lastPage > 5) {
                        this.pagination.offset = 5;
                    } else {
                        this.pagination.offset = lastPage;
                    }
                }).catch(e => {
                    this.loading = false;
                    let response = e?.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                      console.log(e)
                    }
                });
            },
            departmentList() {
                this.$http.get('/api/filter/department/' + this.vessel_id).then((response) => {
                    this.departments = response.data.success;
                }).catch((e) => {
                    console.log(e)
                });
            },
            getMainComponent(department_id) {
                this.mainComponents = [];
                this.components = [];
                this.subcomponents = [];
                this.subsubcomponents = [];
                this.scheduleInfo.main_component_id = '';
                this.scheduleInfo.component_id = '';
                this.scheduleInfo.sub_component_id = '0';
                this.scheduleInfo.sub_sub_component_id = '0';
                if (department_id !== '') {
                    this.$http.get('/api/filter/main-component/' + this.vessel_id + '/' + department_id).then((response) => {
                        this.mainComponents = response.data.success;
                    }).catch((e) => {
                        console.log(e)
                    });
                }
            },
            getComponentList(main_component_id) {
                this.components = [];
                this.subcomponents = [];
                this.subsubcomponents = [];
                this.scheduleInfo.component_id = '';
                this.scheduleInfo.sub_component_id = '0';
                this.scheduleInfo.sub_sub_component_id = '0';
                if (main_component_id !== '') {
                    this.$http.get('/api/filter/component/' + this.vessel_id + '/' + main_component_id).then((response) => {
                        this.components = response.data.success;
                    }).catch((e) => {
                        console.log(e)
                    });
                }
            },
            getSubComponent(component_id) {
                this.subcomponents = [];
                this.subsubcomponents = [];
                this.scheduleInfo.sub_component_id = '0';
                this.scheduleInfo.sub_sub_component_id = '0';
                if (component_id != '0') {
                    this.$http.get('/api/filter/sub-component/' + this.vessel_id + '/' + component_id).then((response) => {
                        this.subcomponents = response.data.success;
                    }).catch((e) => {
                        console.log(e)
                    });
                }
            },
            getSubSubComponent(sub_component_id) {
                this.subsubcomponents = [];
                this.scheduleInfo.sub_sub_component_id = '0';
                if (sub_component_id != '0') {
                    this.$http.get('/api/filter/sub-sub-component/' + this.vessel_id + '/' + sub_component_id).then((response) => {
                        this.subsubcomponents = response.data.success;
                    }).catch((e) => {
                        console.log(e)
                    });
                }
            },
            jobDoneFunction(job) {
              console.log("joib done",job)
                if (job?.job_done_report && job?.job_done_report != null) {
                    this.permitWorkMode = true
                } else {
                    this.permitWorkMode = false
                }
                if (this.user_type != job?.job?.rank_fixed && job?.job_done_report == null) {
                    this.$snotify.warning('Job Done Report is not Submitted')
                } else {
                    this.listMode = false;
                    this.IRR = false;
                    this.P2W = false;
                    this.JobDoneStatus = true;
                    this.selectedJob = job?.job;
                    this.scheduleData = job;
                    let  job_name = job?.job?.name + '(' + job?.job?.code + ')';
                    this.job_name = job_name;
                    this.componentName = job?.job?.component?.name
                }

            },
            //this function use for change list mode
            changeListMode() {
                this.IRR = false;
                this.P2W = false;
                this.JobDoneStatus = false;
                this.DeferReschedule = false;
                this.listMode = true;
                this.getAllJobList()
            }
        }
    }
</script>

<style scoped>
  .mx-datepicker {
    width: 100% !important;
  }

</style>
