<!-- TableComponent.vue -->
<template>
  <table     :class="getTableClasses" style="width:100%">
    <thead>
    <tr>
      <th scope="col">Code</th>
      <th scope="col">Budget Name</th>
      <th scope="col">Total Budget</th>
      <th scope="col" class="text-right">Monthly Budget</th>
      <th scope="col" class="text-right">Monthly Actual</th>
      <th scope="col" class="text-right">Monthly Committed</th>
      <th scope="col" class="text-right">Y-T-D Expense</th>
      <th scope="col" class="text-right">Y-T-D Variance($)</th>
      <th scope="col" class="text-right">Y-T-D Variance(%)</th>
      <th scope="col" class="text-right">Monthly Variance($)</th>
      <th scope="col" class="text-right">Monthly Variance (%)</th>
      <th scope="col">Remarks</th>
      <th scope="col">Variance Remarks</th>
      <th scope="col">Variance Document</th>
      <th v-if="!isPrinting" scope="col">Action</th>
    </tr>
    </thead>
    <tbody>

    <tr  v-for="budgetInfo in  budgetInfoList" v-if="budgetInfo.variance_remarks != null && budgetInfo.variance_remarks != ''"  :key="budgetInfo.id"  v-bind:class="{'bg-secondary text-white': budgetInfo.parent_id==0}" >
      <td class="text-left">{{budgetInfo.code}}</td>
      <td>{{budgetInfo.name}}</td>
      <td class="text-left">{{(parseInt(isEmpty(budgetInfo.budget)?budgetInfo.budget[0].amount:'0.0')).toFixed(3)}}</td>
      <td class="text-right" >{{(parseInt(isEmpty(budgetInfo.budget)?budgetInfo.budget[0].amount:'0.0')/12).toFixed(3)}}</td>
      <td  class="text-right" >{{isEmpty(budgetInfo.budget)?budgetInfo.monthly_actual:'0.0'}}</td>
      <td class="text-right" >{{isEmpty(budgetInfo.budget)?budgetInfo.monthly_commited:'0.0'}}</td>
      <td class="text-right" >{{isEmpty(budgetInfo.budget)?budgetInfo.y_t_d_expense:'0.0'}}</td>
      <td class="text-right">
            <span v-if="isEmpty(budgetInfo.budget)">
                <i  v-if="parseInt(isEmpty(budgetInfo.budget)?budgetInfo.y_t_d_variance:'0.0')>0" class="fa fa-arrow-up text-success"  aria-hidden="true" ></i>
                <i  v-if="parseInt(isEmpty(budgetInfo.budget)?budgetInfo.y_t_d_variance:'0.0')<0"  class="fa fa-arrow-down text-danger" aria-hidden="true"  ></i>
           </span>
         {{isEmpty(budgetInfo.budget)?budgetInfo.y_t_d_variance:'0.0'}}
      </td>
      <td class="text-right">
        <i
          v-if="parseInt(isEmpty(budgetInfo.budget)?budgetInfo.y_t_d_variance:'0.0')>0"
          class="fa fa-arrow-up text-success"
          aria-hidden="true"
        ></i>
        <i
          v-if="parseInt(isEmpty(budgetInfo.budget)?budgetInfo.y_t_d_variance:'0.0')<0"
          class="fa fa-arrow-down text-danger"
          aria-hidden="true"
        ></i>
        {{isEmpty(budgetInfo.budget)?budgetInfo.y_t_d_variance_parcent.toFixed(3):'0.0'}}
      </td>
      <td class="text-right">
          <span v-if="isEmpty(budgetInfo.budget)">
             <i
               v-if="parseInt(isEmpty(budgetInfo.budget)?budgetInfo.monthly_variance:'0.0')>0"
               class="fa fa-arrow-up text-success"
               aria-hidden="true"
             ></i>
           <i
             v-if="parseInt(isEmpty(budgetInfo.budget)?budgetInfo.monthly_variance:'0.0')<0"
             class="fa fa-arrow-down text-danger"
             aria-hidden="true"
           ></i>
         </span>
        {{(parseInt(isEmpty(budgetInfo.budget)?budgetInfo.monthly_variance:'0.0').toFixed(3))}}
      </td>
      <td class="text-right">
           <span v-if="isEmpty(budgetInfo.budget)">
             <i
               v-if="parseInt(isEmpty(budgetInfo.budget)?budgetInfo.monthly_variance:'0.0')>0"
               class="fa fa-arrow-up text-success"
               aria-hidden="true"
             ></i>
          <i
            v-if="parseInt(isEmpty(budgetInfo.budget)?budgetInfo.monthly_variance:'0.0')<0"
            class="fa fa-arrow-down text-danger"
            aria-hidden="true"
          ></i>
            </span>
        {{(parseInt(isEmpty(budgetInfo.budget)?budgetInfo.monthly_variance_parcent:'0.0').toFixed(3))}}
      </td>
      <td class="text-right">{{budgetInfo.remarks}}</td>
      <td class="text-right">{{budgetInfo?.variance_remarks}}</td>
      <td class="text-right">
        <div v-if="budgetInfo?.variance_document != null && budgetInfo?.variance_document != '' ">
          <a :href="budgetInfo?.variance_document" target="_blank"> <i class="fa fa-file-text"></i> </a>
        </div>
      </td>
      <td v-if="!isPrinting">
        <button type="button" @click="createVarianceRemarks(budgetInfo,selectedYear,selectedMonth)" class="btn btn-success btn-sm" title="Create Budget Variance Remarks"><i
          class="fa fa-plus-square"></i> </button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  props: {
    budgetInfoList: {
      required: true
    },
    createVarianceRemarks: {
      required: false
    },
    isEmpty: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },
    selectedYear: {
      required: false
    },
    selectedMonth: {
      required: false
    },

  },



  computed: {
    getTableClasses() {
      return {
        'table': true,
        'text-center': true,
        'table-bordered': !this.isPrinting,
        'table-sm': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }
};
</script>

<style scoped>
.print-table {
  /* Define styles for printing if needed */
}
</style>
