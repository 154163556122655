<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left">Non Conformity Corrective Action</h2>
              <ul class="nav navbar-right panel_toolbox align-right">
                <li>
                  <button v-if="isPermissionExist(PERMISSIONS.NC_CORRECTIVE_ACTION)" type="button" class="btn btn-info btn-sm" @click="create">Add New <i class="fa fa-plus"></i></button>
                </li>
                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                  <button @click="print('printArea','Vessel Certificate')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
                  <button @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
                  <vue-excel-xlsx class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'nc-corrective-action'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>
                      <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Reference No</th>
                          <th>Date</th>
                          <th>Name of Company</th>
                          <th>To be reviewed</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row , index) in dataList" :key="index">
                          <td>{{index+1}}</td>
                          <td>{{row?.non_conformity.ref_no || ''}}</td>
                          <td>{{row.date || ''}}</td>
                          <td>{{row?.non_conformity?.company_name || ''}}</td>
                          <td>{{row.review_by || ''}}</td>
                          <td>
                            <button type="button" @click="show(row)" title="View" class="btn btn-info btn-sm"><i class="fa fa-eye"></i></button>
                            <button type="button"  v-if="isPermissionExist(PERMISSIONS.NC_CORRECTIVE_ACTION)" @click="edit(row)" class="btn btn-primary btn-sm"><i class="fa fa-edit"></i></button>
                            <button
                              v-if="isPermissionExist(PERMISSIONS.NC_CORRECTIVE_ACTION)"
                              type="button"
                              @click="destroy(row)"
                              class="btn btn-danger btn-sm"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <h4>Vessel Name: {{this.vessel_name}}</h4>
                          <h3>Non Conformity Corrective Action</h3>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        </div>
                        <table class="table  print-table" style="width: 100%">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Reference No</th>
                            <th>Date</th>
                            <th>Name of Company</th>
                            <th>To be reviewed</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(row , index) in dataList" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{row?.non_conformity.ref_no || ''}}</td>
                            <td>{{row.date || ''}}</td>
                            <td>{{row?.non_conformity?.company_name || ''}}</td>
                            <td>{{row.review_by || ''}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">{{ editMode ? "Edit" : "Add New" }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <div class="row">
                  <div class="col-md-6 form-group">
                    <label>Reference No<span class="required">*</span></label>
                    <select v-model="form.non_conformity_id" name="non_conformity_id" class="form-control" :class="{ 'is-invalid': form.errors.has('non_conformity_id') }">
                      <option value="">Select Option</option>
                      <option v-for="(row , index) in NCDataList" :key="index" :value="`${row.id}`">{{ row.ref_no }}</option>

                    </select>
                    <has-error :form="form" field="ref_no"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Date</label>
                    <datetime format="YYYY-MM-DD" v-model="form.date"></datetime>
                    <has-error :form="form" field="date"></has-error>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Description of Root Cause Analysis </label>
                      <textarea v-model="form.root_cause_analysis"
                                name="root_cause_analysis" class="form-control">
                      </textarea>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Description of Corrective Action(s) </label>
                      <textarea v-model="form.corrective_action"
                                name="corrective_action" class="form-control">
                      </textarea>
                    </div>
                  </div>

                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Description of Preventive Actions </label>
                      <textarea v-model="form.preventive_actions"
                                name="suggestions" class="form-control">
                      </textarea>
                    </div>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>To be reviewed/followed-up by</label>
                    <select v-model="form.review_by" name="review_by" class="form-control" :class="{ 'is-invalid': form.errors.has('review_by') }">
                      <option value="">Select Option</option>
                      <option value="Marine">Marine</option>
                      <option value="Safety and Quality">Safety and Quality</option>
                      <option value="Technical">Technical</option>
                      <option value="Manning">Manning</option>
                    </select>
                    <has-error :form="form" field="review_by"></has-error>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Comments by Reviewer</label>
                      <textarea v-model="form.reviewer_comments"
                                name="preventive_actions" class="form-control">
                      </textarea>
                    </div>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Date of Reviewer</label>
                    <datetime format="YYYY-MM-DD" v-model="form.reviewer_date"></datetime>
                    <has-error :form="form" field="reviewer_date"></has-error>
                  </div>
                  <div class="col-md-6 col-12">
                    <div class="form-group">
                      <label>Comments by DPA</label>
                      <textarea v-model="form.dpa_comments"
                                name="dpa_comments" class="form-control">
                      </textarea>
                    </div>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Date of DPA</label>
                    <datetime format="YYYY-MM-DD" v-model="form.dpa_date"></datetime>
                    <has-error :form="form" field="dpa_date"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Vessel’s acknowledgement and Comments </label>
                    <textarea v-model="form.vessel_acknowledgement"
                              name="vessel_acknowledgement" class="form-control">
                      </textarea>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Date Closed Vessel</label>
                    <datetime format="YYYY-MM-DD" v-model="form.date_close_vessel"></datetime>
                    <has-error :form="form" field="date_close_vessel"></has-error>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Date Closed Office</label>
                    <datetime format="YYYY-MM-DD" v-model="form.date_closed_office"></datetime>
                    <has-error :form="form" field="date_closed_office"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Name of Master </label>
                    <input
                      v-model="form.master_name"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('master_name') }"
                    >
                    <has-error :form="form" field="master_name"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>DPA Name</label>
                    <input
                      v-model="form.dpa_name"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('dpa_name') }"
                    >
                    <has-error :form="form" field="dpa_name"></has-error>
                  </div>

                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Modal -->


      <div class="modal fade" id="nonConformityViewModal" tabindex="-1" role="dialog" aria-labelledby="requisitionModalTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="requisitionModalTitle">Non Conformity Corrective Action</h5>
              <div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
                <button @click="print('nonConformityPrint','Dry Dock')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </div>

            </div>
            <div class="modal-body">
              <NCCorrectiveActionVIew :data="form"/>

              <div id="nonConformityPrint" style="display: none">
                <div style="text-align: center">
                  <h2>{{appName}}</h2>
                  <h4>Vessel Name:  {{ this.vessel_name }}</h4>
                  <h3>Non Conformity Corrective Action</h3>
                  <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                </div>
                <NCCorrectiveActionVIew :data="form" :isPrinting="true"/>


              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
    import datetime from 'vuejs-datetimepicker';
    import auth from "@/auth";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import exportMixin from "@/mixin/exportMixin";
    import printMixin from "@/mixin/printMixin";
    import TableData from "@/components/reports/Requisition/TableData.vue";
    import ModalData from "@/components/DryDock/ModalData.vue";
    import NonConformityView from "@/components/SafetyQuality/NonConformityView.vue";
    import NCCorrectiveActionVIew from "@/components/SafetyQuality/NCCorrectiveActionVIew.vue";
    import PermissionsMixin from "@/mixin/permissionsMixin";

    export default {
        name: "NonConformityCorrectiveAction",
        mixins: [goBackButtonMixin, exportMixin, printMixin,PermissionsMixin],
        components: {NCCorrectiveActionVIew, NonConformityView, ModalData, TableData, PulseLoader, datetime},
        data() {
            return {
                loading: false,
                editMode: false,
                query: "",
                vesselId: auth.getVesselId(),
                vessel_name: auth.getVesselName(),
                permissions:auth.getPermissionData(),
                appName: process.env.VUE_APP_NAME,
                queryFiled: "name",
                dataList: null,
                NCDataList: null,
                vesselCertificateTypes: null,
                form: new Form({
                  id: "",
                  non_conformity_id: "",
                  date: "",
                  root_cause_analysis: "",
                  corrective_action: '',
                  preventive_actions: '',
                  review_by: '',
                  reviewer_comments: '',
                  reviewer_date: '',
                  dpa_comments: '',
                  dpa_date: '',
                  vessel_acknowledgement: '',
                  date_close_vessel: '',
                  date_closed_office: '',
                  master_name: '',
                  dpa_name: '',
                }),
                excelHeader: [],
                excelData: [],
                pagination: {
                    current_page: 1
                }
            };
        },
        mounted() {
            this.getData();
            this.getNCData();
        },
        methods: {
            prepareExportData() {
                return this.dataList.map(item => ({
                    'Vessel Name' : auth.getVesselName(),
                    'Reference No of NC': item?.non_conformity?.ref_no,
                    'Name of Company': item?.non_conformity?.company_name,
                    'Date': item?.date,
                    'Description of Non-Conformance (Findings) ': item?.non_conformity?.non_conformance_description,
                    'Description of Root Cause Analysis': item?.root_cause_analysis,
                    'Description of Corrective Action(s)': item?.corrective_action,
                    'Description of Preventive Actions': item?.preventive_actions,
                    'To be reviewed/followed-up by ': item?.review_by,
                    'Comments by Reviewer': item?.reviewer_comments,
                    'Reviewer Date': item?.reviewer_date,
                    'Comments by DPA': item?.dpa_comments,
                    'DPA Date': item?.dpa_date,
                    'Vessel acknowledgement and Comments': item?.vessel_acknowledgement,
                    'Date Closed Vessel': item?.date_close_vessel,
                    'Name of Master': item?.master_name,
                    'Date Closed Office': item?.date_closed_office,
                    'Name of DPA': item?.dpa_name,
                }));
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'nc-corrective-action.csv');
            },
            excelExport() {
                let data = this.prepareExportData();
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                //data.shift();
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            getData() {
                this.loading = true;
                this.$http.get("/api/filter/non-conformity-corrective-action/" + this.vesselId).then(response => {
                    this.loading = false;
                    this.dataList = response.data.success;
                  console.log(this.dataList)
                    setTimeout(() => $('#datatables').DataTable(), 1000);
                    this.excelExport();
                }).catch(e => {
                    this.loading = false;
                    let response = e?.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },

           getNCData() {
            this.$http.get("/api/filter/non-conformity/" + this.vesselId).then(response => {
              this.NCDataList = response.data.success;
            }).catch(e => {
              console.log(e);
            });
          },


            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                $("#roleModal").modal("show");
            },
            store() {
                this.form.busy = true;
                this.form.vessel_id = this.vesselId;
                this.form.post("/api/non-conformity-corrective-action").then(response => {
                    this.getData();
                    $("#roleModal").modal("hide");
                    if (this.form.successful) {
                        $('.dataTables_empty').closest("tr").remove();
                        this.$snotify.success("Data Created Successfully");
                    } else {
                        this.$snotify.error("Something went wrong try again later.");
                    }
                }).catch(e => {
                    console.log(e);
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else if (response.status === 422) {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    } else {
                        var error = this.form.errors.errors.error;
                        this.$snotify.error(error);
                    }
                });
            },
            show(row) {
              this.form.reset();
              this.form.clear();
              this.form.fill(row);
              this.form.non_conformity = row.non_conformity;
              $('#nonConformityViewModal').modal('show')

            },
            edit(row) {
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.form.fill(row);
                $("#roleModal").modal("show");
                console.log(this.form);
            },
            update() {
                this.form.busy = true;
                this.form.vessel_id = this.vesselId;
                this.form._method = 'put';
                this.form.post("/api/non-conformity-corrective-action/" + this.form.id).then(response => {
                    this.getData();
                    $("#roleModal").modal("hide");
                    if (this.form.successful) {
                        this.$snotify.success("Data Successfully Updated");
                    } else {
                        this.$snotify.error("Something went wrong try again later.");
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },
            destroy(role) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete("/api/non-conformity-corrective-action/" + role.id).then(response => {
                        this.getData();
                        this.$snotify.success("Data Successfully Deleted", "Success");
                    }).catch(error => {
                        let response = error.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            var err = error.response.data.error;
                            this.$snotify.error(err);
                        }
                    });
                })
                    .catch(function () {

                    });
            }

        }
    }
</script>

<style scoped>

</style>
