<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left">Service Receive Item</h2>
              <div>
                <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                <button @click="print('printArea','Delivery List ')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>
                      <TableData :deliveryData="deliveryData" :getPurchaseItemData="getPurchaseItemData"/>
                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <h4>Vessel Name: {{this.vessel_name}}</h4>
                          <div class="font-weight-bold">Service Receive Item List</div>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        </div>
                        <TableData :deliveryData="deliveryData" :isPrinting="true"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="purchaseItemModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog mw-100 w-75" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">Receive Items</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-2">
                  <div class="form-group">
                    <label for="">Received Date</label>
                    <input style="width: 140px;" type="date" v-model="received_date" name="received_date" class="form-control" placeholder="Date"
                           autocomplete="off">
                  </div>
                </div>
                <div class="col-md-2">
                  <label for="">Country</label>
                    <Select2 v-model="country_id" @change="getCountriesport" name="country_id" :options="CountryData" />
                </div>
                <div class="col-md-2">
                  <label for="">Port</label>
                  <Select2 v-model="port_id"  name="port_id" :options="portData" />
                </div>
              </div>

              <table class="table table-bordered">
                <thead>
                <tr>
                  <th>Id</th>
                  <th>Name</th>
                  <th>Impa Code</th>
                  <th>Model</th>
                  <th>Unit</th>
                  <th class="text-center">Order Qty</th>
                  <th>Receive Qty</th>
                  <th>Remarks</th>
                  <th>Expire Date</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="(purchase , index) in purchaseItemData" :key="index">
                  <td>{{purchase.id}}</td>
                  <td>{{purchase.item.name}}</td>
                  <td>{{purchase.item.inpa_code}}</td>
                  <td>{{purchase.item.model}}</td>
                  <td>{{purchase.item.unit.name}}</td>
                  <td class="text-center">{{purchase.qty}}</td>
                  <td>
                    <div class="form-group">
                      <input style="width: 70px;" v-model="receiveItemData[index].qty" type="number" name="name" class="form-control" placeholder="Enter receive quantity"
                             autocomplete="off">
                    </div>
                  </td>
                  <td>
                    <div class="form-group">
                      <input style="width: 100px;" v-model="receiveItemData[index].remarks" type="text" name="remarks" class="form-control" placeholder="Enter remarks"
                             autocomplete="off">
                    </div>
                  </td>

                  <td>
                    <div class="form-group">
                      <input style="width: 140px;" type="date" v-model="receiveItemData[index].expire_date" name="expire_date" class="form-control" placeholder="Date"
                             autocomplete="off">
                    </div>
                  </td>
                  <td>
                    <button type="button" class="btn btn-success btn-sm pull-left" @click="updateReceiveQuantity(index)">Save</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal -->
    </div>
  </div>
</template>

<script>
    import auth from '../../../auth'
    import TableData from "./TableData.vue";
    import {printUtil} from "@/utils/print";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import Select2 from 'v-select2-component'

    export default  {
        name: 'DeliveryList',

        components: {TableData, PulseLoader,Select2},
        data() {
            return {
                loading: false,
                appName: process.env.VUE_APP_NAME,
                vessel_name: auth.getVesselName(),
                editMode: false,
                receiveItemData: [],
                purchaseItemData: [],
                deliveryData: [],
                CountryData: [],
                portData: [],
                form: new Form({
                    requisition_id: '',
                    supplier_id: '',
                    title: '',
                    date: '',
                    remark: '',
                    vessel_id: auth.getVesselId(),
                }),
                received_date: '',
                country_id: '',
                port_id: '',
                vessel_id: auth.getVesselId(),
                pagination: {
                    current_page: 1
                }
            }
        },
        mounted() {
            this.getDeliveryData()
            this.getCountryData()
        },
        methods: {
            getDeliveryData() {
                this.loading = true;
                var data = {
                    type: 'Return',
                    status: 'Ready for Invoice',
                }
                this.$http.post('/api/filter/purchase/' + this.vessel_id, data).then(response => {
                    this.loading = false
                    this.deliveryData = response.data.success
                    setTimeout(() => $('#datatables').DataTable(), 1000)
                }).catch(e => {
                    this.loading = false
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else {
                        this.$snotify.error("something went wrong")
                    }
                })
            },
            setDateFormat(event) {
                this.form.date = moment(event.target.value).format('YYYY-MM-DD')
            },
            updateQuantity(index, event) {
                this.purchaseItemData[index].receive = event.target.value
            },
          getCountryData() {
            this.CountryData = [];
            this.$http.get('/api/country').then(response => {
              var newObj = {
                id: '',
                text: 'Select Country'
              };
              this.CountryData.push(newObj);
              response.data.success.filter(item => {
                var newObj = {
                  id: item.id,
                  text: item.name
                };
                this.CountryData.push(newObj)
              })

            }).catch(e => {
              console.log(e)
            })
          },
          getCountriesport() {
            this.portData = [];
            this.$http.get('/api/port-by-country/' + this.country_id).then(response => {
              var newObj = {
                id: '',
                text: 'Select Port'
              };
              this.portData.push(newObj);
              response.data.success.filter(item => {
                var newObj = {
                  id: item.id,
                  text: item.name
                };
                this.portData.push(newObj)
              })
            }).catch(e => {
              this.portData = []
              console.log(e)
            })
          },
            updateReceiveQuantity(index) {
                this.receiveItemData[index].received_date = this.received_date
                this.receiveItemData[index].country_id = this.country_id
                this.receiveItemData[index].port_id = this.port_id

                if (!this.receiveItemData[index].received_date) {
                    this.$snotify.error('Receive date required')
                    return false
                }

                this.$http.post(`/api/return-receive-item`, this.receiveItemData[index]).then((response) => {

                    if (response.data.success) {
                        this.$snotify.success('Return Receive item updated successfully')
                    } else {
                        this.$snotify.error('Return Receive item already updated')
                    }
                }).catch((error) => {
                    console.log(error)
                    let status = error.response.status
                    if (status === 422) {
                        if (error.response.data?.errors) {
                            const errors = error.response.data?.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    }
                    if (status === 500) {
                        let message = error.response.data.error
                        this.$snotify.error(message)
                    }
                    if (status === 403) {
                        this.$snotify.warning(error.response.data.error)
                    }


                })
            },
            getPurchaseItemData(item) {
                let that = this
                $('#purchaseItemModal').modal('show')
                this.$http.get('/api/purchase/' + item).then(response => {
                    this.purchaseItemData = response.data.purchase_item

                    this.purchaseItemData.forEach((value, index) => {
                        let obj = {
                            vessel_id: that.form.vessel_id,
                            purchase_id: value.purchase_id,
                            item_id: value.item_id,
                            order_qty: value.qty,
                            qty: value.qty,
                            damage_qty: 0,
                            returned_qty: 0,
                            remarks: '',
                            received_date: that.received_date,
                            country_id: that.country_id,
                            port_id: that.port_id,
                            status: ''
                        }

                        that.receiveItemData.push(obj)
                    })

                }).catch(e => {
                    console.log(e)
                })
            },
            print(id, title = '') {
                printUtil(id, title);
            },
            goBack() {
                this.$router.go(-1);
            },
        }
    }
</script>

<style scoped>

</style>
