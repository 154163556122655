<template>
  <div>
    <table :class="getTableClasses" style="width: 100%" >
      <tbody>
      <tr>
        <th colspan="2"><strong>1st Section (General Info) </strong> </th>
      </tr>
      <tr>
        <th width="25%"><strong>Reference No: </strong></th>
        <th>{{ data?.ref_no }}</th>
      </tr>
      <tr>
        <th width="25%"><strong>Type(Convention/Code): </strong></th>
        <th>{{ data?.convention_code }}</th>
      </tr>
      <tr>
        <th width="25%"><strong>Type( 3rd Party Inspection): </strong></th>
        <th>{{ data?.third_party_inspection }}</th>
      </tr>
      <tr>
        <th width="25%"><strong>Name Of Company: </strong></th>
        <th>{{ data?.company_name }}</th>
      </tr>
      <tr>
        <th colspan="2"><strong>2nd Section (Description) </strong></th>
      </tr>
      <tr>
        <th width="25%"><strong>Manual/Publication Ref: </strong></th>
        <th>{{ data?.publication_ref }}</th>
      </tr>
      <tr>
        <th width="25%"><strong>Description of Non-Conformance (Findings): </strong></th>
        <th>{{ data?.non_conformance_description }}</th>
      </tr>
      <tr>
        <th width="25%"><strong>Objective Findings: </strong></th>
        <th>{{ data?.objective_findings }}</th>
      </tr>
      <tr>
        <th width="25%"><strong>Suggestions: </strong></th>
        <th>{{ data?.suggestions }}</th>
      </tr>
      <tr>
        <th colspan="2"><strong>3rd Section( Person raising and Person Responsible)  : </strong></th>
      </tr>
      <tr>
        <th width="25%"><strong>Name Of Person Raising NC: </strong> </th>
        <th> {{ data?.name_person_raising }}</th>
      </tr>
      <tr>
        <th width="25%"><strong>Place Of Person Raising NC: </strong> </th>
        <th> {{ data?.person_raising_place }}</th>
      </tr>
      <tr>
        <th width="25%"><strong>Date Of Person Raising NC: </strong> </th>
        <th> {{ data?.person_raising_date }}</th>
      </tr>
      <tr>
        <th width="25%"><strong>Name Of PIC For CAP: </strong> </th>
        <th> {{ data?.pic_name }}</th>
      </tr>
      <tr>
        <th width="25%"><strong>Place Of PIC For CAP: </strong> </th>
        <th> {{ data?.pic_place }}</th>
      </tr>
      <tr>
        <th width="25%"><strong>Date Of PIC For CAP: </strong> </th>
        <th> {{ data?.pic_date }}</th>
      </tr>

      </tbody>
    </table>
    <div>
    </div>
  </div>
</template>



<script>
export default {
  props: {
    data: {
      required: true
    },
    isPrinting: {
      type: Boolean,
      default: false
    },

  },

  computed: {
    getTableClasses() {
      return {
        'table': true,
        'table-bordered': !this.isPrinting,
        'print-table': this.isPrinting,
      };
    }
  }

};
</script>
<style scoped>
    .underline {
      text-decoration: underline;
    }

</style>
