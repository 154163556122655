<template>
  <div>
    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-md-12 col-sm-12 ">
          <div class="">
            <div class="x_panel">
              <h2 class="float-left">Vessel Certificates</h2>
              <ul class="nav navbar-right panel_toolbox align-right">
                <li>
                  <button type="button" class="btn btn-info btn-sm" @click="create">Add New <i class="fa fa-plus"></i></button>
                </li>
                <li>
                  <button @click="goBack" class="btn btn-danger btn-sm float-right">Back <i class="fa fa-arrow-circle-o-right"></i></button>
                  <button @click="print('printArea','Vessel Certificate')" class="btn btn-warning btn-sm float-right"><i class="fa fa-print"></i> Print</button>
                  <button @click="csvExport" class="btn btn-success btn-sm float-right"><i class="fa fa-file-archive-o"></i> CSV</button>
                  <vue-excel-xlsx class="btn btn-primary btn-sm float-right" :data="excelData" :columns="excelHeader" :file-name="'vessel-certificate'" :file-type="'xlsx'" :sheet-name="'sheetname'"><i class="fa fa-file-excel-o"></i> Excel</vue-excel-xlsx>
                </li>
              </ul>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-box table-responsive">
                      <p class="text-muted font-13 m-b-30"></p>
                      <table id="datatables" class="table table-striped table-bordered table-sm  text-center" style="width:100%">
                        <thead>
                        <tr>
                          <th>#</th>
                          <th>Type</th>
                          <th>Name</th>
                          <th>Date of Issue</th>
                          <th>Date of Expiry</th>
                          <th>Issued by</th>
                          <th>Place</th>
                          <th>Duration</th>
                          <th>Certificate no</th>
                          <th>Alert days</th>
                          <th>Window Period</th>
                          <th>Remark</th>
                          <th>Doc</th>
                          <th>Last Annual Endorsement Date</th>
                          <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(row , index) in dataList" :key="index">
                          <td>{{index+1}}</td>
                          <td>
                            <span v-if="row.vessel_certificate_type">{{row.vessel_certificate_type.name}}</span>
                          </td>
                          <td>{{row.name}}</td>
                          <td>{{row.date_of_issue}}</td>
                          <td>{{row.date_of_expire}}</td>
                          <td>{{row.issued_by}}</td>
                          <td>{{row.place}}</td>
                          <td>{{row.type}}</td>
                          <td>{{row.certificate_no}}</td>
                          <td>{{row.alert_days}}</td>
                          <td>
                            {{ row.window_period_start | moment("DD/MM/YYYY") }} - {{ row.window_period_end | moment("DD/MM/YYYY") }}
                          </td>
                          <td>{{row.remark}}</td>
                          <td>
                            <a style="text-decoration: underline;color: #0a6aa1" target="_blank" v-if="row && row.doc != null" :href="row && row.document">View Doc</a>
                          </td>
                          <td>{{row?.last_endorsement_date}}</td>
                          <td>
                            <router-link :to="{ name: 'CertificateView', params: { id: row.id }}" class="btn btn-info btn-sm"><i class="fa fa-eye"></i></router-link>
                            <button type="button" @click="edit(row)" class="btn btn-primary btn-sm"><i class="fa fa-edit"></i></button>
                            <button
                              type="button"
                              @click="destroy(row)"
                              class="btn btn-danger btn-sm"
                            >
                              <i class="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                        </tbody>
                      </table>

                      <div id="printArea" style="display: none">
                        <div style="text-align: center;line-height: 20px;">
                          <h2>{{ appName }}</h2>
                          <h4>Vessel Name: {{this.vessel_name}}</h4>
                          <h3>Vessel Certificate</h3>
                          <p>Printing Date: {{new Date().toISOString().substring(0, 10)}}</p>
                        </div>
                        <table class="table  print-table" style="width: 100%">
                          <thead>
                          <tr>
                            <th>#</th>
                            <th>Type</th>
                            <th>Name</th>
                            <th>Date of Issue</th>
                            <th>Date of Expiry</th>
                            <th>Issued by</th>
                            <th>Place</th>
                            <th>Duration</th>
                            <th>Certificate no</th>
                            <th>Last Annual Endorsement Date</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(row , index) in dataList" :key="index">
                            <td>{{index+1}}</td>
                            <td>
                              <span v-if="row.vessel_certificate_type">{{row.vessel_certificate_type.name}}</span>
                            </td>
                            <td>{{row.name}}</td>
                            <td>{{row.date_of_issue}}</td>
                            <td>{{row.date_of_expire}}</td>
                            <td>{{row.issued_by}}</td>
                            <td>{{row.place}}</td>
                            <td>{{row.type}}</td>
                            <td>{{row.certificate_no}}</td>
                            <td>{{row?.last_endorsement_date}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="roleModal" tabindex="-1" role="dialog" aria-labelledby="customerModalLongTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="customerModalLongTitle">{{ editMode ? "Edit" : "Add New" }}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="editMode ? update() : store()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label>Certificate Type </label>
                    <select v-model="form.vessel_certificate_type_id" name="vessel_certificate_type_id" class="form-control" :class="{ 'is-invalid': form.errors.has('vessel_certificate_type_id') }">
                      <option value="">Select Certificate Type</option>
                      <option v-for="type in vesselCertificateTypes" :key="type.id" :value="type.id">{{type.name}}</option>
                    </select>
                    <has-error :form="form" field="vessel_certificate_type_id"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Name<span class="required">*</span></label>
                    <input
                      v-model="form.name"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('name') }"
                    >
                    <has-error :form="form" field="name"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Date of Issue<span class="required">*</span></label>
                    <datetime format="YYYY-MM-DD" v-model="form.date_of_issue"></datetime>
                    <has-error :form="form" field="name"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Date of Expiry<span class="required">*</span></label>
                    <datetime format="YYYY-MM-DD" v-model="form.date_of_expire"></datetime>
                    <has-error :form="form" field="name"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Issued by(Authority)<span class="required">*</span></label>
                    <input
                      v-model="form.issued_by"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('issued_by') }"
                    >
                    <has-error :form="form" field="name"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Place<span class="required">*</span></label>
                    <input
                      v-model="form.place"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('place') }"
                    >
                    <has-error :form="form" field="name"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label> Duration:<span class="required">*</span></label>
                    <select v-model="form.type" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                      <option value="Short Term">Short Term</option>
                      <option value="Permanent">Permanent</option>
                      <option value="Full-Term">Full-Term</option>
                      <option value="Temporary">Temporary</option>
                      <option value="Conditional">Conditional</option>
                    </select>
                    <has-error :form="form" field="name"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Certificate no<span class="required">*</span></label>
                    <input
                      v-model="form.certificate_no"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('certificate_no') }"
                    >
                    <has-error :form="form" field="name"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Alert days</label>
                    <input
                      v-model="form.alert_days"
                      type="number"
                      min="0"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('alert_days') }"
                    >
                    <has-error :form="form" field="name"></has-error>
                  </div>
                  <div class="col-md-6 form-group">
                    <label>Doc</label>
                    <input autocomplete="off" type="file" name="image" class="form-control" @change="onImageChange" accept=".png, .jpg, .jpeg,.pdf">
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Window Period From</label>
                    <datetime format="YYYY-MM-DD" v-model="form.window_period_start"></datetime>
                    <has-error :form="form" field="window_period_start"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Window Period To</label>
                    <datetime format="YYYY-MM-DD" v-model="form.window_period_end"></datetime>
                    <has-error :form="form" field="name"></has-error>
                  </div>



                  <div class="col-md-6 form-group">
                    <label>Extended upto</label>
                    <datetime format="YYYY-MM-DD" v-model="form.extended_upto"></datetime>
                    <has-error :form="form" field="name"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Place of extension</label>
                    <input
                      v-model="form.place_of_extension"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('place_of_extension') }"
                    >
                    <has-error :form="form" field="name"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Renew date</label>
                    <datetime format="YYYY-MM-DD" v-model="form.renew_date"></datetime>
                    <has-error :form="form" field="name"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Renew place</label>
                    <input
                      v-model="form.renew_place"
                      type="text"
                      name="renew_place"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('renew_place') }"
                    >
                    <has-error :form="form" field="renew_place"></has-error>
                  </div>

                  <div class="col-md-6 form-group">
                    <label>Remark<span class="required">*</span></label>
                    <input
                      v-model="form.remark"
                      type="text"
                      name="name"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('remark') }"
                    >
                    <has-error :form="form" field="name"></has-error>
                  </div>
                </div>

                <div class="row" v-for="(endorsement, index) in form.annual_endorsements" :key="index">
                  <div class="col-6">
                    <label>Annual Endorsement Date</label>
                    <datetime format="YYYY-MM-DD" v-model="endorsement.annual_endorsement_date"></datetime>
                    <has-error :form="form" :field="'annual_endorsement_date.' + index"></has-error>
                  </div>
                  <div class="col-6">
                    <label>Annual Endorsement Place</label>
                    <input
                      v-model="endorsement.annual_endorsement_place"
                      type="text"
                      name="annual_endorsement_place"
                      class="form-control"
                      :class="{ 'is-invalid': form.errors.has('annual_endorsement_place.' + index) }"
                    />
                    <has-error :form="form" :field="'annual_endorsement_place.' + index"></has-error>
                  </div>
                  <div class="col-6">
                    <label>Next Annual Endorsement Date</label>
                    <datetime format="YYYY-MM-DD" v-model="endorsement.next_annual_endorsement_date"></datetime>
                    <has-error :form="form" :field="'next_annual_endorsement_date.' + index"></has-error>
                  </div>

                  <div class="col-6">
                    <button v-if="index == 0" type="button" class="btn btn-info btn-sm mt-4" @click="addRow"><i class="fa fa-plus"></i></button>
                    <button v-if="index > 0" type="button" class="btn btn-danger btn-sm mt-4" @click="removeRow(index)">
                      <i class="fa fa-minus"></i>
                    </button>
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button :disabled="form.busy" type="submit" class="btn btn-info">Save</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Modal -->
    </div>
  </div>
</template>

<script>
    import datetime from 'vuejs-datetimepicker';
    import auth from "@/auth";
    import goBackButtonMixin from "@/mixin/goBackButtonMixin";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import exportMixin from "@/mixin/exportMixin";
    import printMixin from "@/mixin/printMixin";
    import TableData from "@/components/reports/Requisition/TableData.vue";

    export default {
        name: "Role",
        mixins: [goBackButtonMixin, exportMixin, printMixin],
        components: {TableData, PulseLoader, datetime},
        data() {
            return {
                loading: false,
                editMode: false,
                query: "",
                vesselId: auth.getVesselId(),
                vessel_name: auth.getVesselName(),
                appName: process.env.VUE_APP_NAME,
                queryFiled: "name",
                dataList: null,
                vesselCertificateTypes: null,
                form: new Form({
                    id: "",
                    name: "",
                    vessel_certificate_type_id: "",
                    date_of_issue: '',
                    date_of_expire: '',
                    issued_by: '',
                    place: '',
                    type: "",
                    certificate_no: "",
                    alert_days: "",
                    window_period_start: "",
                    window_period_end: "",
                    extended_upto: "",
                    place_of_extension: "",
                    renew_date: "",
                    renew_place: "",
                    remark: "",
                    doc: "",
                  annual_endorsements: [
                    {
                      annual_endorsement_date: '',
                      annual_endorsement_place: '',
                      next_annual_endorsement_date: ''
                    }
                  ]
                }),
                excelHeader: [],
                excelData: [],
                pagination: {
                    current_page: 1
                }
            };
        },
        mounted() {
            this.getData();
            this.getVesselCertificateTypes();
        },
        methods: {
            prepareExportData() {
                return this.dataList.map(item => ({
                    'Type': item?.vessel_certificate_type ? item?.vessel_certificate_type?.name : '',
                    'Name': item?.name,
                    'Date of Issue': item?.date_of_issue,
                    'Date of Expiry': item?.date_of_expire,
                    'Issued by': item?.issued_by,
                    'Place': item?.place,
                    'Duration': item?.type,
                    'Certificate No': item?.certificate_no,
                }));
            },
            csvExport() {
                const exportData = this.prepareExportData();
                this.exportCSVData(exportData, 'vessel-certificate.csv');
            },
            excelExport() {
                let data = this.prepareExportData();
                let excelHeader = [];
                Object.keys(data[0]).forEach((colItem, colIndex) => {
                    excelHeader.push({label: colItem, field: colItem});
                });
                //data.shift();
                this.excelHeader = excelHeader;
                this.excelData = data;
            },
            getData() {
                this.loading = true;
                this.$http.get("/api/filter/vessel-certificate/" + this.vesselId).then(response => {
                    this.loading = false;
                    this.dataList = response.data.success;
                    setTimeout(() => $('#datatables').DataTable(), 1000);
                    this.excelExport();
                }).catch(e => {
                    this.loading = false;
                    let response = e?.response;
                    if (response?.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },
            getVesselCertificateTypes() {
                this.$http.get("/api/filter/vessel-certificate-type/" + this.vesselId).then(response => {
                    this.vesselCertificateTypes = response.data.success;
                }).catch(e => {
                    console.log(e);
                });
            },
            onImageChange(e) {
                let file = e.target.files[0];
                this.form.doc = file;
            },
            create() {
                this.editMode = false;
                this.form.reset();
                this.form.clear();
                $("#roleModal").modal("show");
            },
            addRow() {
              this.form.annual_endorsements.push({
                annual_endorsement_date: '',
                annual_endorsement_place: '',
                next_annual_endorsement_date: ''
              });
            },
            removeRow(index) {
              this.form.annual_endorsements.splice(index, 1);
            },
            store() {
                this.form.busy = true;
                this.form.vessel_id = this.vesselId;
                this.form.post("/api/vessel-certificate").then(response => {
                  console.log(response)
                    this.getData();
                    $("#roleModal").modal("hide");
                    if (this.form.successful) {
                        $('.dataTables_empty').closest("tr").remove();
                        this.$snotify.success("Data Created Successfully");
                    } else {
                        this.$snotify.error("Something went wrong try again later.");
                    }
                }).catch(e => {
                    console.log(e);
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    } else if (response.status === 422) {
                        if (response.data?.errors) {
                            const errors = response.data.errors;
                            for (const key in errors) {
                                this.$snotify.error(errors[key][0])
                            }
                        }
                    } else {
                        var error = this.form.errors.errors.error;
                        this.$snotify.error(error);
                    }
                });
            },
            edit(row) {
                console.log(row);
                this.editMode = true;
                this.form.reset();
                this.form.clear();
                this.form.fill(row);
                $("#roleModal").modal("show");
                console.log(this.form);
            },
            update() {
                this.form.busy = true;
                this.form.vessel_id = this.vesselId;
                this.form._method = 'put';
                this.form.post("/api/vessel-certificate/" + this.form.id).then(response => {
                    this.getData();
                    $("#roleModal").modal("hide");
                    if (this.form.successful) {
                        this.$snotify.success("Data Successfully Updated");
                    } else {
                        this.$snotify.error("Something went wrong try again later.");
                    }
                }).catch(e => {
                    let response = e.response;
                    if (response.status === 403) {
                        this.$snotify.warning(response?.data?.error)
                    }
                });
            },
            destroy(role) {
                this.$dialog.confirm('Do You Want to delete This ?').then(() => {
                    this.$http.delete("/api/vessel-certificate/" + role.id).then(response => {
                        this.getData();
                        this.$snotify.success("Data Successfully Deleted", "Success");
                    }).catch(error => {
                        let response = error.response;
                        if (response.status === 403) {
                            this.$snotify.warning(response?.data?.error)
                        } else {
                            var err = error.response.data.error;
                            this.$snotify.error(err);
                        }
                    });
                })
                    .catch(function () {

                    });
            }

        }
    }
</script>

<style scoped>

</style>
