<template>

  <div class="dashboard">

    <div
      v-if="loading"
      class="col-md-12 text-center"
      style="margin-top:25%"
    >
      <PulseLoader
        :loading="loading"
      ></PulseLoader>
    </div>
    <div v-if="isPermissionExist(PERMISSIONS.DASHBOARD) && !loading">

      <div class="row facts">
        <!--      <div class="col-xl-3 col-md-6">-->
        <!--        <div class="card bg-c-yellow update-card">-->
        <!--          <div class="card-block">-->
        <!--            <router-link :to="{ name: 'pending-job' , params: { type: 'pending'} }">-->
        <!--              <div class="row align-items-end">-->
        <!--                <div class="col-8">-->
        <!--                  <h4 class="text-white">{{ jobInfo.pendingJob }}</h4>-->
        <!--                  <h6 class="text-white m-b-0">Running jobs</h6>-->
        <!--                </div>-->
        <!--                <div class="col-4 text-right">-->
        <!--                  <i class="fa fa-product-hunt fa-big"></i>-->
        <!--                </div>-->
        <!--              </div>-->
        <!--            </router-link>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--      </div>-->

        <div class="col-xl-3 col-md-6">
          <div class="card bg-c-green update-card">
            <div class="card-block">
              <router-link to="/over-due-job">
                <div class="row align-items-end">
                  <div class="col-8">
                    <h4 class="text-white">{{ jobInfo.overDueJob }}</h4>
                    <h6 class="text-white m-b-0">Overdue jobs</h6>
                    <p class="text-white m-b-0">Pending Approval</p>

                  </div>
                  <div class="col-4 text-right">
                    <i class="fa fa-cloud fa-big"></i>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6">
          <div class="card bg-c-pink update-card">
            <div class="card-block">
              <router-link to="/reports/deferred-job">
                <div class="row align-items-end">
                  <div class="col-8">
                    <h4 class="text-white">{{ jobInfo.deferJob }}</h4>
                    <h6 class="text-white m-b-0">Deffer Jobs</h6>
                    <p class="text-white m-b-0">Pending Approval</p>

                  </div>
                  <div class="col-4 text-right">
                    <i class="fa fa-ship fa-big"></i>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6">
          <div class="card bg-c-lite-green update-card">
            <div class="card-block">
              <router-link to="/maintenance">
                <div class="row align-items-end">
                  <div class="col-8">
                    <h4 class="text-white">{{ jobInfo.doneJob }}</h4>
                    <h6 class="text-white m-b-0">Job Done </h6>
                    <p class="text-white m-b-0">Pending Approval</p>

                  </div>
                  <div class="col-4 text-right">
                    <i class="fa fa-adjust fa-big"></i>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>


        <div class="col-xl-3 col-md-6 pointer" @click="goLink('requisition-items')">
          <div class="card bg-c-purple update-card">
            <div class="card-block">
              <div class="row align-items-end">
                <div class="col-8">
                  <h4 class="text-white">{{ pendingReq }}</h4>
                  <h6 class="text-white m-b-0">Requisition </h6>
                  <p class="text-white m-b-0">Pending Approval</p>

                </div>
                <div class="col-4 text-right">
                  <i class="fa fa-align-right fa-big"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6">
          <div class="card bg-c-teal update-card pointer" @click="goLink('quatetion-list')">
            <div class="card-block">
              <div class="row align-items-end">
                <div class="col-8">
                  <h4 class="text-white">{{ pendingQut }}</h4>
                  <h6 class="text-white m-b-0">Pending Quotation</h6>

                </div>
                <div class="col-4 text-right">
                  <i class="fa fa-asterisk fa-big"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3 col-md-6">
          <div class="card bg-c-blue update-card pointer" @click="goLink('supplier-quotations')">
            <div class="card-block">
              <div class="row align-items-end">
                <div class="col-8">
                  <h4 class="text-white">{{ sq }}</h4>
                  <h6 class="text-white m-b-0">Supplier Quotations</h6>
                </div>
                <div class="col-4 text-right">
                  <i class="fa fa-bandcamp fa-big"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6">
          <div class="card bg-c-cyan update-card pointer" @click="goLink('purchase-list')">
            <div class="card-block">
              <div class="row align-items-end">
                <div class="col-8">
                  <h4 class="text-white">{{ po }}</h4>
                  <h6 class="text-white m-b-0">Pending Purchase</h6>
                </div>
                <div class="col-4 text-right">
                  <i class="fa fa-cart-plus fa-big"></i>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xl-3 col-md-6 ml-auto mr-auto">
          <div class="card bg-c-fire update-card pointer" @click="goLink('auto-requisition')">
            <div class="card-block">
              <div class="row align-items-end">
                <div class="col-8">
                  <h4 class="text-white">{{ ar }}</h4>
                  <h6 class="text-white m-b-0">Auto Requisition</h6>
                </div>
                <div class="col-4 text-right">
                  <i class="fa fa-cart-plus fa-big"></i>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
      <div class="row">
        <!--        <div class="col-lg-6">-->
        <!--          <canvas ref="criticalEquipmentStockChart" id="criticalEquipmentStockChart" width="400" height="150"></canvas>-->
        <!--        </div>-->
        <div class="col-lg-12">
          <canvas ref="doughnut" id="doughnut" width="600" height="200"></canvas>
        </div>
      </div>
    </div>
    <div v-if="!loading && !isPermissionExist(PERMISSIONS.DASHBOARD)">
      <div class="col-12">
        <h2>Dashboard</h2>
      </div>
    </div>

  </div>
</template>

<script>
    import Chart from 'chart.js';
    import auth from "@/auth";
    import PulseLoader from "vue-spinner/src/PulseLoader.vue";
    import PermissionsMixin from "@/mixin/permissionsMixin";

    export default {
        name: "Dashboard",
        components: {PulseLoader},
        mixins: [PermissionsMixin],
        data() {
            return {
                loading: false,
                vessel_type_id: auth.getVesselTypeId(),
                permissions: auth.getPermissionData(),
                pendingReq: 0,
                pendingQut: 0,
                sq: 0,
                po: 0,
                ar: 0,
                vesselTypes: [],
                vessels: [],
                jobInfo: {
                    pendingJob: '0',
                    overDueJob: '0',
                    deferJob: '0',
                    doneJob: '0'
                },

                criticalEquipmentStockLabel: [],
                criticalEquipmentStockData: [],
                budgetHead: [],
                budgetAmount: [],
                vesselId: auth.getVesselId(),
                budgetInfoList: []
            }
        },
        mounted() {
            this.loading = true;
            this.budgetYearInfo();
            this.getPendingReq();
            this.getPendingQuotation();
            this.getPendingSQ();
            this.getPendingPO();
            this.veselType();
            this.getAllPendingJob();
            this.getAllOverDueJob();
            this.getAllDeferJob();
            this.getAllDoneJob();
            this.getAllAutoReq();

            setTimeout(() => {
                this.loading = false;
            }, 1000);
            this.getCriticalInventory();

            setTimeout(() => {
                this.generateCriticalEquipmentStockChart();
            }, 1000);

            setTimeout(() => {
                this.barDoughnut();
            }, 1000);


        },
        computed: {},
        methods: {

            generateCriticalEquipmentStockChart() {

                if (this.criticalEquipmentStockLabel && this.criticalEquipmentStockData) {
                    let chart_label = this.criticalEquipmentStockLabel;
                    let chart_data = this.criticalEquipmentStockData;

                    if (this.$refs.criticalEquipmentStockChart) {
                        var ctx = this.$refs.criticalEquipmentStockChart.getContext('2d');

                        var barCharts = new Chart(ctx, {
                            type: 'bar',
                            data: {
                                labels: chart_label,
                                datasets: [{
                                    label: 'Critical Equipment Stock',
                                    data: chart_data,
                                    backgroundColor: [
                                        'rgba(255, 99, 132, 0.2)',
                                        'rgba(54, 162, 235, 0.2)',
                                        'rgba(255, 206, 86, 0.2)',
                                        'rgba(75, 192, 192, 0.2)',
                                        'rgba(153, 102, 255, 0.2)',
                                        'rgba(255, 159, 64, 0.2)'
                                    ],
                                    borderColor: [
                                        'rgba(255, 99, 132, 1)',
                                        'rgba(54, 162, 235, 1)',
                                        'rgba(255, 206, 86, 1)',
                                        'rgba(75, 192, 192, 1)',
                                        'rgba(153, 102, 255, 1)',
                                        'rgba(255, 159, 64, 1)'
                                    ],
                                    borderWidth: 1
                                }]
                            },
                            options: {
                                responsive: true,
                                lineTension: 1,
                                scales: {
                                    yAxes: [{
                                        ticks: {
                                            beginAtZero: true
                                        }
                                    }]
                                }
                            }
                        });
                    }
                }
            },

            barDoughnut() {
                let budgetHead = this.budgetHead;
                let budgetAmount = this.budgetAmount;
                var canvas = this.$refs.doughnut;
                if (!canvas) {
                    console.error('Canvas element not found');
                    return;
                }
                var ctx = canvas.getContext('2d');
                if (!ctx) {
                    console.error('Failed to get canvas context');
                    return;
                }
                var colorCode = [
                    '#ff0000',
                    '#FF7F00',
                    '#FFFF00',
                    '#008000',
                    '#0000FF',
                    '#F56444',
                    '#A71DC5',
                    '#FBD603',
                    '#A807E4',
                ];
                var myChart = new Chart(ctx, {
                    type: 'doughnut',
                    data: {
                        datasets: [{
                            data: budgetAmount,
                            backgroundColor: colorCode,
                            label: 'Dataset 1'
                        }],
                        labels: budgetHead
                    },
                    options: {
                        responsive: true,
                        legend: {
                            position: 'top',
                        },
                        title: {
                            display: true,
                            text: 'Budget Head Chart '
                        },
                        animation: {
                            animateScale: true,
                            animateRotate: true
                        },
                        onClick: (event, elements) => {
                        if (elements.length) {
                           this.$router.push('/budget-report');
                        }
                      },
                    }
                });
            },
            veselType() {
                this.$http.get('/api/vessel-info').then(response => {
                    this.vesselTypes = response.data.success.vessel_type;
                }).catch(error => {
                    console.log(error);
                })
            },
            getVessel() {
                this.$http.get('/api/vessel-info/' + this.vessel_type_id).then(response => {
                    this.vessels = response.data.success.vessel;
                }).catch(error => {
                    console.log(error);
                })
            },
            getPendingReq() {
                var filter = {
                    "chief_engr_approval": "Pending",
                    "superintendent_approval": "Pending",
                    "technical_manager_approval": "Pending",
                };

                this.$http.post("/api/filter/requisition/" + this.vesselId, filter).then(response => {
                    this.pendingReq = response.data.success.length
                }).catch(e => {
                    console.log(e);
                });
            },
            getPendingQuotation() {
                var filter = {
                    "superintendent_approval": "Pending",
                    "technical_manager_approval": "Pending",
                };

                this.$http.post("/api/filter/quotation/" + this.vesselId, filter).then(response => {
                    this.pendingQut = response.data.success.length
                }).catch(e => {
                    console.log(e);
                });
            },
            getPendingSQ() {
                var filter = {
                    "superintendent_approval": "Pending",
                    "technical_manager_approval": "Pending",
                };

                this.$http.post("/api/filter/supplier-quotation/" + this.vesselId, filter).then(response => {
                    this.sq = response.data.success.length
                }).catch(e => {
                    console.log(e);
                });
            },
            getPendingPO() {
                var filter = {
                    "superintendent_approval": "Pending",
                    "technical_manager_approval": "Pending",
                };
                this.$http.post("/api/filter/purchase/" + this.vesselId, filter).then(response => {
                    this.po = response.data.success.length
                }).catch(e => {
                    console.log(e);
                });
            },
            getCriticalInventory() {

                this.$http.get("/api/critical-item-stock/" + this.vesselId).then(response => {
                    this.criticalEquipmentStockLabel = (response.data.label.length > 0) ? response.data.label : [];
                    this.criticalEquipmentStockData = (response.data.qty.length > 0) ? response.data.qty : [];
                }).catch(e => {
                    console.log(e);
                });
            },
            getAllAutoReq() {
                this.$http.get("/api/auto-requisition-count/" + this.vesselId).then(response => {
                    this.ar = response.data;
                }).catch(e => {
                    console.log(e);
                });
            },
            goLink(link) {
                window.location.href = link;
            },
            //Maintenance related all option
            getAllPendingJob() {
                this.$http.get("/api/get-pending-job/" + this.vesselId + '/Dashboard').then(response => {
                    var totalJobArray = response.data.success;
                    this.jobInfo.pendingJob = totalJobArray.reduce((a, b) => a + b, 0);
                }).catch(e => {
                    console.log(e);
                });
            },
            getAllOverDueJob() {
                let params = {
                    vessel_id: this.vesselId,
                };
                this.$http.get("/api/get-due-job-report", {params}).then(response => {
                    this.jobInfo.overDueJob = response.data?.success?.data?.length;
                }).catch(e => {
                    console.log(e.message);
                });
            },
            getAllDeferJob() {
                let params = {
                    vessel_id: this.vesselId,
                };
                this.$http.get('/api/filter/deferred-job-pending', {params}).then(response => {
                    this.jobInfo.deferJob = response.data.success;
                }).catch(e => {
                    console.log(e);
                });
            },
            getAllDoneJob() {
                let params = {
                    vessel_id: this.vesselId,
                };
                this.$http.get('/api/filter/get-pending-job-done', {params}).then(response => {
                    this.jobInfo.doneJob = response?.data.success
                }).catch(e => {
                    console.log(e);
                });
            },
            //budget related head
            budgetYearInfo() {
                let year = new Date().getFullYear();
                this.$http.get("/api/budget-head-year/" + year + '/' + this.vesselId).then(response => {
                    var budgetInformation = response.data.success;
                  console.log("b inf",budgetInformation)
                    var budgetHead = [];
                    var budgetAmount = [];
                    budgetInformation.filter(item => {
                        budgetHead.push(item.name);
                        budgetAmount.push(item.budget[0]?.amount);
                    });
                    this.budgetHead = budgetHead;
                    this.budgetAmount = budgetAmount;

                }).catch(e => {
                    console.log(e);
                });
            },
        }
    }
</script>

<style scoped>

</style>
