<template>
  <div>
    <div class="row">
      <div class="col-md-12 col-sm-12 ">
        <div class="x_panel">
          <h2 class="float-left">Requisition Create</h2>
          <ul class="nav navbar-right panel_toolbox align-right">

          </ul>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-body">
              <form role="form" action="#" method="POST" @submit="formSubmit" class="requisitionForm">

                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Req Title<span class="required">*</span></label>
                      <input v-model="RequisitionTitle" type="text" name="req_title" class="form-control form-control-sm" placeholder="Enter title" autocomplete="off">
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="form-group">
                      <label>Requisition Priority</label>
                      <select v-model="RequisitionPriority" class="form-control form-control-sm">
                        <option value="">Select one</option>
                        <option value="High">High</option>
                        <option value="Medium">Medium</option>
                        <option value="Normal">Normal</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="form-group">
                      <label for="remark">Remarks</label>
                      <textarea v-model="RequisitionRemarks" class="form-control form-control-sm" name="remark" rows="2" id="remark"></textarea>
                    </div>
                  </div>

                  <div class="x_panel">
                    <div class="x_content">
                      <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
                        <li class="nav-item">
                          <a class="nav-link active" id="line-tab" data-toggle="tab" href="#line" role="tab" aria-controls="line" aria-selected="false">
                            Line Items
                          </a>
                        </li>
                        <li class="nav-item">
                          <a class="nav-link " id="supply-tab" data-toggle="tab" href="#supply" role="tab" aria-controls="supply" aria-selected="true">Supply Details</a>
                        </li>

                      </ul>

                      <div class="tab-content mt-2" id="myTabContent">

                        <div class="tab-pane fade active show" id="line" role="tabpanel" aria-labelledby="line-tab">
                          <div class="row">

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Select Item<span class="required">*</span></label>
                                <select class="form-control form-control-sm" v-model="itemId" @change="getItemData">
                                  <option value="" selected>Select item</option>
                                  <option v-for="(row, key) in items" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Status</label>
                                <select v-model="itemStatus" class="form-control form-control-sm">
                                  <option value="" selected>Select one</option>
                                  <option value="High">High</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Normal">Normal</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Quantity<span class="required">*</span></label>
                                <input v-model="itemQuantity" type="number" name="name" class="form-control form-control-sm" placeholder="Enter quantity" autocomplete="off">
                              </div>
                            </div>

                            <div class="col-md-2">
                              <div class="form-group">
                                <label>Unit</label>
                                <input v-model="itemData.item.unit.name" type="text" name="name" class="form-control form-control-sm" placeholder="Enter unit" readonly>
                              </div>
                            </div>

                            <div class="col-md-2">
                              <button style="margin-top: 26px" type="button" class="btn btn-success btn-sm pull-right" @click="setRequisitionItem"><i class="fa fa-plus-square"></i>
                              </button>
                            </div>

                            <div class="col-md-12">
                              <table class="table table-bordered">
                                <thead>
                                <tr>
                                  <th>Status</th>
                                  <th>Item name</th>
                                  <th>Impa code</th>
                                  <th>Maker Part no</th>
                                  <th>Maker</th>
                                  <th>Model</th>
                                  <th>Qty</th>
                                  <th>Unit</th>
                                  <th>Stock</th>
                                  <th>Budget total</th>
                                  <th>Budget left</th>
                                  <th>Action</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item , index) in requisitionForm.item_id" :key="index">
                                  <td>{{requisitionForm.status[index]}}</td>
                                  <td>{{requisitionForm.item_name[index]}}</td>
                                  <td>{{requisitionForm.item[index].item.inpa_code}}</td>
                                  <td>{{requisitionForm.item[index].item.maker_part_no}}</td>
                                  <td>{{requisitionForm.item[index].item.maker}}</td>
                                  <td>{{requisitionForm.item[index].item.model}}</td>
                                  <td>{{requisitionForm.qty[index]}}</td>
                                  <td>{{requisitionForm.item[index].item.unit.name}}</td>
                                  <td>{{requisitionForm.item[index].currentStock?.rob}}</td>
                                  <td>{{requisitionForm.item[index].totalBudget}}</td>
                                  <td>{{requisitionForm.item[index].totalBudgetLeft}}</td>
                                  <td>
                                    <button type="button" class="btn btn-round btn-danger" @click="removeRequisitionItem(index)"><i class="fa fa-trash-o"></i></button>
                                  </td>
                                </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="tab-pane fade " id="supply" role="tabpanel" aria-labelledby="supply-tab">
                          <div class="row">

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Country</label>
                                <select class="form-control form-control-sm" v-model="country_id" @change="getCountriesport">
                                  <option value="" selected>Select country</option>
                                  <option v-for="(row, key) in CountryData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group">
                                <label>Port</label>
                                <select class="form-control form-control-sm" v-model="port_id">
                                  <option value="" selected>Select port</option>
                                  <option v-for="(row, key) in portData" :value="`${row.id}`">{{row.name}}</option>
                                </select>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="address">Address</label>
                                <textarea v-model="SupplyAddress" class="form-control form-control-sm" name="address" rows="2" id="address"></textarea>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Required By</label>
                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyDate"></datetime>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Vessel ETA</label>
                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyETA"></datetime>
                              </div>
                            </div>

                            <div class="col-md-4">
                              <div class="form-group">
                                <label>Vessel ETD</label>
                                <datetime format="YYYY-MM-DD" width="300px" v-model="SupplyETD"></datetime>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div class="col-md-12">
                    <button type="submit" class="btn btn-primary pull-right">Submit</button>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

    import Select2 from 'v-select2-component'
    import datetime from 'vuejs-datetimepicker'
    import auth from '../../auth'

    export default {
        name: 'AutoRequisitionProcess',
        components: {
            Select2,
            datetime
        },
        data() {
            return {
                editMode: false,
                isDisabled: false,
                locationList: null,
                units: null,
                myOptions: [],
                vesselId: auth.getVesselId(),
                CategoryData: [],
                category_id: '',
                country_id: '',
                port_id: '',
                RequisitionTitle: '',
                RequisitionPriority: '',
                RequisitionRemarks: '',
                SupplyAddress: '',
                SupplyDate: '',
                SupplyETA: '',
                SupplyETD: '',
                SupplyAdvised: '',
                itemId: '',
                itemName: '',
                itemStatus: '',
                itemQuantity: '',
                itemTotalPrice: '',
                CategoriesItemData: [],
                CountryData: [],
                portData: [],
                itemData: {
                    'item': {
                        'id': '',
                        'budget_head_id': '',
                        'category_id': '',
                        'location_id': '',
                        'vessel_id': '',
                        'other_ref': '',
                        'name': '',
                        'maker': '',
                        'maker_part_no': '',
                        'model': '',
                        'drawing': '',
                        'size': '',
                        'unit': '',
                        'description': '',
                        'min_order': '',
                        'max_order': '',
                        'reorder_level': '',
                        'reorder_qty': '',
                        'opening_stock': '',
                        'opening_stock_date': '',
                        'image': '',
                        'doc': '',
                        'status': '',
                        'created_at': '',
                        'updated_at': '',
                        'budgethead': {
                            'id': '',
                            'vessel_id': '',
                            'parent_id': '',
                            'code': '',
                            'group_code': '',
                            'name': '',
                            'created_at': '',
                            'updated_at': '',
                            'budget': {
                                'id': '',
                                'budget_head_id': '',
                                'amount': '',
                                'budget_year': '',
                                'created_at': '',
                                'updated_at': ''
                            }

                        }

                    },
                    'component': [],
                    'currentStock': '',
                    'lastRequisitionData': {
                        'id': '',
                        'requisition_id': '',
                        'item_id': '',
                        'qty': '',
                        'status': '',
                        'created_at': '',
                        'updated_at': ''

                    },
                    'lastPurchaseData': ''
                },
                form: new Form({
                    id: '',
                    category_id: '',
                    location_id: '',
                    supplier_id: '',
                    vessel_id: auth.getVesselId(),
                    other_ref: '',
                    name: '',
                    maker: '',
                    maker_part_no: '',
                    model: '',
                    drawing: '',
                    size: '',
                    unit_id: '',
                    description: '',
                    min_order: '',
                    max_order: '',
                    reorder_level: '',
                    reorder_qty: '',
                    opening_stock: '',
                    class: '',
                    opening_stock_date: '',
                    image: '',
                    doc: '',
                    inpa_code: '',
                    status: '',
                    component_id: [],
                    budget_head_id: '',
                    price: ''
                }),
                supplierData: [],
                requisitionData: [],
                requisitionItem: [],
                requisitionItems: [],
                requisitionForm: {
                    vessel_id: auth.getVesselId(),
                    item_id: [],
                    item_name: [],
                    status: [],
                    qty: [],
                    item: [],
                },
                requestRequisitionId: '',
                items: [],
            }
        },
        mounted() {
            this.getCountryData()
            this.getBudgetHeadInfo()
            this.locationListInfo()
            this.getUnit()
            var items = this.$route.params.ids.split(',')
            items.forEach((element) => {
                var itemIdName = element.split('~')
                var itemId = itemIdName[0]
                var itemName = itemIdName[1]
                var obj = {
                    'id': itemId,
                    'name': itemName,
                }
                this.items.push(obj)
            })
        },
        methods: {
            getUnit() {
                this.$http.get('/api/unit').then(response => {
                    this.units = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            async getBudgetHeadInfo() {
                this.myOptions = []
                var itemList = await this.$http.get('/api/filter/budget-head/' + this.form.vessel_id)
                this.results = itemList.data.success
                var newObj = {
                    id: '',
                    text: 'No Selected'
                }
                this.myOptions.push(newObj)
                this.results.filter(item => {
                    var newObj = {
                        id: item.id,
                        text: item.name
                    }
                    this.myOptions.push(newObj)
                })
            },
            getCountryData() {
                this.$http.get('/api/country').then(response => {
                    this.CountryData = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            locationListInfo() {
                this.$http.get('/api/locationTree/' + this.form.vessel_id).then(response => {
                    $('.location-tree').html(response.data)
                }).catch(e => {
                    console.log(e)
                })
            },
            store() {
                this.form.busy = true
                this.form.image = $('#itemImage').val()
                this.form.post('/api/item').then(response => {
                    $('#roleModal').modal('hide')
                    if (response.data.success) {
                        $('#addItemModal').modal('hide')
                        this.CategoriesItemData = response.data.success
                        this.category_id = response.data.success.category_id
                    } else {
                        this.$snotify.error(
                            'Please check form again and fillup all required filed.'
                        )
                    }
                }).catch(e => {
                    var error = this.form.errors.errors.error
                    this.$snotify.error('Please check form again and fillup all required filed.')
                })
            },
            getSupplierData() {
                this.$http.get('/api/supplier').then(response => {
                    this.supplierData = response.data.success
                }).catch(e => {
                    console.log(e)
                })
            },
            getCountriesport() {
                this.$http.get('/api/port-by-country/' + this.country_id).then(response => {
                    this.portData = response.data.success
                }).catch(e => {
                    this.portData = []
                    console.log(e)
                })
            },
            getItemData() {
                this.$http.get('/api/item/' + this.itemId).then(response => {
                    this.itemData = response.data
                    this.itemName = response.data.item.name
                }).catch(e => {
                    console.log(e)
                })
            },
            showRequisitionModal() {
                $('#addRequisitionModal').modal('show')
            },
            addItem() {
                $('#addItemModal').modal('show')
            },
            setRequisitionItem() {
                if (!this.itemId || !this.itemStatus || !this.itemQuantity) {
                    this.$snotify.error('Please fill required field')
                } else {
                    // for edit requisition add new item
                    if (this.requisitionItem.id) {
                        let editItemData = {
                            requisition_id: this.requisitionItem.id,
                            item_id: this.itemId,
                            qty: this.itemQuantity,
                            status: this.itemStatus,
                            item: this.itemData.item
                        }
                        this.requisitionItem.requisition_item.push(editItemData)
                    } else {
                        // for new add
                      if(this.itemData?.currentStock?.max_order < (parseInt(this.itemQuantity) + parseInt(this.itemData?.currentStock?.rob))){
                        alert('Item quantity will exceed  max order quantity ')
                      }
                        this.itemTotalPrice = (parseFloat(this.itemQuantity) * parseFloat(this.itemData.item.price))
                        if (this.itemTotalPrice > this.itemData.totalBudgetLeft) {
                            alert('Item value will exceed item budget value')
                        }
                        if (this.requisitionForm.item_id.includes(this.itemId)) {
                            this.$snotify.error('This requisition item already added')
                            this.itemId = ''
                            this.itemQuantity = ''
                            return false
                        }
                        this.message = false
                        this.requisitionForm.item.push(this.itemData)
                        this.requisitionForm.item_id.push(this.itemId)
                        this.requisitionForm.item_name.push(this.itemName)
                        this.requisitionForm.status.push(this.itemStatus)
                        this.requisitionForm.qty.push(this.itemQuantity)
                    }
                    this.itemId = ''
                    this.itemName = ''
                    this.itemStatus = ''
                    this.itemQuantity = ''
                    this.itemTotalPrice = ''
                }
            },
            removeRequisitionItem(index) {
                if (confirm('Do you really want to delete?')) {
                    this.requisitionForm.item_id.splice(index, 1)
                    this.requisitionForm.item_name.splice(index, 1)
                    this.requisitionForm.status.splice(index, 1)
                    this.requisitionForm.item.splice(index, 1)
                    this.requisitionForm.qty.splice(index, 1)
                }
            },
            removeEditRequisitionItem(index) {
                if (confirm('Do you really want to delete?')) {
                    this.requisitionItem.requisition_item.splice(index, 1)
                }
            },
            onChangeItem(index, event) {
                this.requisitionItem.requisition_item[index].item_id = event.target.value
            },
            onChangeQuantity(index, event) {
                this.requisitionItem.requisition_item[index].qty = event.target.value;
            },
            onChangeCopyQuantity(item, index, event) {
                this.requisitionItem.requisition_item[index].item_id = item.item_id;
                this.requisitionItem.requisition_item[index].status = item.status;
                this.requisitionItem.requisition_item[index].qty = event.target.value;
            },
            formSubmit: function (e) {
                e.preventDefault()
                this.requisitionForm.is_auto = true
                this.requisitionForm.title = this.RequisitionTitle
                this.requisitionForm.priority = this.RequisitionPriority
                this.requisitionForm.remarks = this.RequisitionRemarks
                this.requisitionForm.country_id = this.country_id
                this.requisitionForm.port_id = this.port_id
                this.requisitionForm.address = this.SupplyAddress
                this.requisitionForm.supply_date = this.SupplyDate
                this.requisitionForm.vessel_eta = this.SupplyETA
                this.requisitionForm.vessel_etd = this.SupplyETD
                this.requisitionForm.advised_on_date = this.SupplyAdvised
                this.requisitionForm.type = 'Others'

                if (this.requisitionForm.item_id.length > 0) {
                    this.$http.post(`/api/requisition`, this.requisitionForm).then((response) => {
                        if (response.data.success) {
                            //remove blank row
                            this.$snotify.success('Requisition created successfully')
                            this.requisitionForm = {}
                            this.$router.push({name: 'requisition_items'})
                        }
                    }).catch((error) => {
                        if (error.response.status) {
                            this.$snotify.error('Requisition failed, please try again later.')
                        }
                    })
                } else {
                    this.$snotify.error('Please fill required field')
                }
            },
            formUpdate: function (e) {
                e.preventDefault()
                if (this.requisitionItem.requisition_item.length > 0) {
                    this.$http.put(`/api/requisition/` + this.requisitionItem.id, this.requisitionItem).then((response) => {
                        if (response.data.success) {
                            this.$snotify.success('Requisition updated successfully')
                            this.requisitionItem = {}
                        }
                    }).catch((error) => {
                        if (error.response.status) {
                            this.$snotify.error('Update failed, please try again later.')
                        }
                    })
                } else {
                    this.$snotify.error('Please select requisition item & quantity')
                }
                $('#requisitionEditModal').modal('hide')
            },

            show(item) {
                $('#requisitionModal').modal('show')
                this.$http.get('/api/requisition/' + item).then(response => {
                    this.requisitionItem = response.data
                }).catch(e => {
                    console.log(e)
                })
            },
            showBudget(id, index) {
                this.$http.get('/api/get-budget-info/' + id).then(response => {
                    $('.budget-' + index).html('Budget:' + response.data.totalBudget + ', Budget Left:' + response.data.totalBudgetLeft)
                }).catch(e => {
                    console.log(e)
                    this.$snotify.error(e.response.data.error)
                    $('.budget-' + index).html(e.response.data.error)
                })
            },
            selectSupplier() {
                this.selected = []
                if (!this.selectAll) {
                    for (let i in this.supplierData) {
                        this.selected.push(this.supplierData[i].id)
                    }
                }
            },
            myChangeEvent(val) {
            },
            mySelectEvent({id, text}) {
                this.itemId = id
                this.itemName = text
            },
        }
    }
</script>

<style scoped>

</style>
